import { createRouter, createWebHistory } from 'vue-router'
import user_login from '../views/user_login.vue'
import user_register from '../views/user_register.vue'
import user_forgotpass from '../views/user_forgotpass.vue'
import user_resetpass from '../views/user_resetpass.vue'
import app_dashboard from '../views/app_view/dashboard.vue'
import calendar from '../views/app_view/calendar.vue'
import app_quotation from '../views/app_view/quotation.vue'
import sheet from '../views/app_view/sheet.vue'
import app_quotation_remark from '../views/app_view/quotation_remark.vue'
import project from '../views/app_view/project.vue'
import project_create_edit from '../views/app_view/project_create_edit.vue'
import schedules from '../views/app_view/schedules.vue'
import booking from '../views/app_view/booking.vue'
import manage from '../views/app_view/manage.vue'
import customer from '../views/app_view/customer.vue'
import Vehicles from '../views/app_view/vehicles.vue'
import Sales from '../views/app_view/sales.vue'
import AutoParts from '../views/app_view/autoparts.vue'
import Repair from '../views/app_view/repair.vue'
import GasStation from '../views/app_view/gas_station.vue'
import ManageUsers from '../views/app_view/manage_users.vue'
import CreateEditAny from '../views/app_view/create_edit_any.vue'
import BillingPayment from '../views/app_view/billing_payment.vue'
import Accident from '../views/app_view/accident.vue'
import RollCall from '../views/app_view/roll_call.vue'
import Tracking from '../views/app_view/tracking.vue'
import CarRepairType from '../views/app_view/car_repair_type.vue'
import leave from '../views/app_view/leave.vue'
import LeaveHistory from '../views/app_view/leaveHistory.vue'
import claim from '../views/app_view/claim.vue'
import leave_user from '../views/app_view/leave_user.vue'
import leave_admin from '../views/app_view/leave_admin.vue'
import leave_admin_history from '../views/app_view/leave_admin_history.vue'
import manage_worksheet from '../views/app_view/manage_worksheet.vue'

import CompanyService from "../api/CompanyService";
import UserService from '../api/UserService'
import Chat from '../views/app_view/chat.vue'
import Admin from '../views/app_view/admin.vue'
import NotFound from '../views/app_view/not_found.vue'
import check_bill from '../views/app_view/check_bill.vue'

const routes = [
  {
    path: '/user/login',
    name: 'Login',
    component: user_login
  },
  {
    path: '/app/admin',
    name: 'ADmin',
    component: Admin,
    children: [
      {
        name: 'edit_user_admin',
        path: ':type/edit',
        component: Admin
      },
    ]
  },
  {
    path: '/app/admin/:type',
    name: 'adminDetail',
    component: user_register
  },
  {
    path: '/app/admin/:type',
    name: 'adminDetail',
    component: user_register
  },
  {
    path: '/app/admin/:type',
    name: 'adminDetail',
    component: user_register
  },
  {
    path: '/user/forgot_password',
    name: 'ForgotPassword',
    component: user_forgotpass
  },
  {
    path: '/user/reset-password',
    name: 'ResetPassword',
    component: user_resetpass
  },
  {
    path: '/app/dashboard',
    name: 'Dashboard',
    component: app_dashboard
  },
  {
    path: '/app/leaveAdmin/all',
    name: 'LeaveAdmin',
    component: leave_admin
  },
  {
    path: '/app/manageWorksheet/all',
    name: 'ManageWorksheet',
    component: manage_worksheet
  },
  {
    path: '/app/checkBill/all',
    name: 'checkBill',
    component: check_bill
  },
  {
    path: '/app/leaveAdmin/history',
    name: 'LeaveAdminHistory',
    component: leave_admin_history
  },
  {
    path: '/app/Project/',
    name: 'Project',
    component: project
  },
  {
    path: '/app/Project/:id',
    name: 'Project-Create',
    component: project_create_edit
  },
  {
    path: '/app/quotation/:type',
    name: 'Booking-Create',
    component: app_quotation
  },
  {
    path: '/app/sheet/:type',
    name: 'Work-Sheet-Create',
    component: sheet
  },
  {
    path: '/app/quotation-remark/all',
    name: 'Booking-Remark',
    component: app_quotation_remark
  },
  {
    path: '/app/job/calendar',
    name: 'Calendar',
    component: calendar
  },
  {
    path: '/schedules',
    name: 'Schedule',
    component: schedules
  },
  {
    path: '/app/booking/:type',
    name: 'Booking',
    children: [
      { path: '', component: booking }
    ], 
  },
  {
    path: '/app/manage/:type',
    name: 'Manage',
    component: manage
  },
  {
    path: '/app/customer/:type',
    name: 'Customer',
    component: customer
  },
  {
    path: '/app/vehicle/:type',
    name: 'Vehicles',
    component: Vehicles
  },
  {
    path: '/app/Sale/all',
    name: 'Sales',
    component: Sales
  },
  {
    path: '/app/auto-parts/:type',
    name: 'AutoParts',
    component: AutoParts
  },
  {
    path: '/app/repair/:type',
    name: 'repair',
    component: Repair
  },
  {
    path: '/app/leaveUser/:type',
    name: 'LeaveUser',
    component: leave_user
  },
  {
    path: '/app/repair-type/:type',
    name: 'repair-type',
    component: CarRepairType
  },
  {
    path: '/app/roll-call/:type',
    name: 'roll-call',
    component: RollCall
  },
  {
    path: '/app/tracking/:type',
    name: 'tracking',
    component: Tracking
  },
  {
    path: '/app/accident/:type',
    name: 'accident',
    component: Accident
  },
  {
    path: '/app/gas-station',
    name: 'GasStation',
    component: GasStation,
    children: [
      {
        name: 'add',
        path: 'add',
        component: GasStation
      },
      {
        name: 'detail',
        path: 'detail/:id',
        component: GasStation
      },
  ]
  },
  {
    path: '/app/manageUsers',
    name: 'ManageUsers',
    component: ManageUsers,
    children: [
      {
        name: 'new_user',
        path: 'new_user',
        component: ManageUsers
      },
      {
        name: 'edit_user',
        path: ':role/:user',
        component: ManageUsers
      },
  ]
  },
  {
    path: '/app/:model/:id',
    name: 'CreateEdit',
    component: CreateEditAny
  },
  {
    path: '/app/billing-payment/:id',
    name: 'BillingPayment',
    component: BillingPayment
  },
  {
    path: '/app/leave',
    name: 'leave',
    component: leave
  },
  {
    path: '/app/leave/leaveHistory',
    name: 'leaveHistory',
    component: LeaveHistory
  },
  {
    path: '/app/claim',
    name: 'claim',
    component: claim,
    children: [
      {
        name: 'history',
        path: 'history',
        component: claim
      },
    ]
  },
  {
    path: '/app/chat',
    name: 'cr_chat',
    component: Chat
  },
  {
    path: '/app/not-found',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const isAuthenticated = localStorage.getItem('token')
const allowed_list = ['Login', 'Register', 'ForgotPassword', 'ResetPassword']

router.beforeEach(async (to, from, next) => {
  if (allowed_list.includes(to.name) || to.query.token) {
    next()
  } else {
    if (isAuthenticated) {
      var user_profile = await UserService.get_user_profile()
      var businessTypeId = 0
      if(user_profile.company_id){
        let result = await CompanyService.getBusinessId(user_profile.company_id)
        if(result){
          businessTypeId = result.data
        }
      }
      if(user_profile) localStorage.setItem('user_profile', JSON.stringify(user_profile))
        if (to.path.startsWith('/app/admin') && user_profile.permission.role !== 'vecabo') {
          next({ name: 'NotFound' })
        } else if ((to.path.startsWith('/app/customer') || to.path.startsWith('/app/vehicle/ClientContact') || to.path.startsWith('/app/quotation') 
          || to.path.startsWith('/app/manage') || to.path.startsWith('/app/booking') || to.path.startsWith('/app/gas-station') 
          || to.path.startsWith('/app/tracking') || to.path.startsWith('/app/roll-call')
          || to.path.startsWith('/app/manageUsers') || to.path.startsWith('/app/chat')
        ) && user_profile.permission.role === 'tsm') {
          next({ name: 'NotFound' })
        } else if ((to.path.startsWith('/app/vehicle/CarCompany') || to.path.startsWith('/app/vehicle/CarType') || to.path.startsWith('/app/vehicle/CarGroup') 
          || to.path.startsWith('/app/vehicle/Driver') || to.path.startsWith('/app/vehicle/Car') || to.path.startsWith('/app/vehicle/Parking')
          || to.path.startsWith('/app/vehicle/Expense') || to.path.startsWith('/app/vehicle/Extra') || to.path.startsWith('/app/vehicle/Trip')
          || to.path.startsWith('/app/vehicle/Expire?table=Car') || to.path.startsWith('/app/booking') || to.path.startsWith('/app/gas-station') 
          || to.path.startsWith('/app/tracking') || to.path.startsWith('/app/roll-call') || to.path.startsWith('/app/manageUsers') || to.path.startsWith('/app/chat')
        ) && user_profile.permission.role === 'sale') {
          next(false)
        }else if ((to.path.startsWith('/app/quotation/all') || to.path.startsWith('/app/quotation-remark/all') 
          || to.path.startsWith('/app/manageUsers')
        ) && user_profile.permission.role === 'tm') {
          next({ name: 'NotFound' })
        } else if (to.path.startsWith('/app/tracking') && ( businessTypeId != 2)) {
          // Redirect to another route if company_id is 39
          next({ name: 'Dashboard' }); // Redirect to a suitable route
        } else {
          next();
        }
    } else {
      next({ name: 'Login' })
    }
  }
})

export default router
