<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type != 'edit_user_admin'" class="app-content">
            <a-spin :spinning="loading">
                <a-card :title="title">
                    <template #extra>
                        <a-space>
                            <a-button style="display: flex;"
                                type="primary" size="large" @click="showModalExport = true">
                                {{ button_title }}
                            </a-button>
                            <a-button style="display: flex" @click="$router.go(-1)" type="primary" size="large">
                                <template #icon>
                                    <span class="material-symbols-outlined">arrow_back_ios</span>
                                </template>
                                ย้อนกลับ
                            </a-button>
                        </a-space>
                    </template>

                    <a-row>
                        <a-space>
                            <a-input-search style="width: 300px;" v-model:value="search_username" :placeholder="`ค้นหาชื่อผู้ส่งคำขอ`" enter-button="Search"/>
                            <a-date-picker style="width: 150px;" placeholder="Start Date" v-model:value="timeStart" format="DD/MM/YYYY" />
                            <span class="material-symbols-outlined icon" style="color: rgba(0, 0, 0, 0.25);">East</span>
                            <a-date-picker style="width: 150px;" placeholder="End Date" v-model:value="timeEnd" format="DD/MM/YYYY" />
                        </a-space>
                        <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>
                    </a-row>

                    <br>
                    <a-row>
                        <a-table :columns="filteredColumns" :data-source="filtered_list(Leave_progress2)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'fullname'">
                                    <div> {{ record.fullname }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_timestamp'">
                                    <div> {{ render_date(record.leave_user_timestamp) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_date'">
                                    <div> {{ render_date(record.time_start) }} - {{ render_date(record.time_end) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_no'">
                                    <div> {{record.leave_user_no}} </div>
                                </template>
                                <template v-if="column.key === 'leave_type_id'">
                                    <div> {{ getLeaveTypeName(record.leave_type_id) }} </div>
                                </template>
                                <template v-if="column.key === 'leave_user_amount'">
                                    <div> {{record.leave_user_amount}} </div>
                                </template>
                                <template v-if="column.key === 'approve_status'">
                                    <div>{{ getApproveStatusName(record.approve_status) }}</div>
                                </template>
                                <template v-if="column.key === 'leave_quota'">
                                    <div> {{record.leave_quota}} </div>
                                </template>
                                <template v-if="column.key === 'approver'">
                                    <div>{{ record.approver }}</div>
                                </template>
                                <template v-if="column.key === 'id'">
                                    <div>
                                        <a><span style="font-size: 14px" class="material-symbols-outlined" @click="showLeaveDetails(record)">Visibility</span></a>
                                    </div>
                                </template>
                            </template>
                        </a-table>
                    </a-row>
                    <a-modal v-model:visible="showModalLeaveUserData" @ok="showModalLeaveUserData=false" >
                        <div style="display: flex; flex-direction: column ;justify-items:start;align-items: start">
                            <a-form-item label="ชื่อ-นามสกุล ผู้ส่งคำขอ" name="leave_user_no" style="width: 98%; margin-top: 5%;">
                                <a-input :value="selectedLeave.fullname" placeholder="ชื่อ-นามสกุล ผู้ส่งคำขอ" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="เลขกำกับใบลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_user_no" placeholder="เลขกำกับใบลา" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่แจ้งลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.leave_user_timestamp)" placeholder="วันที่แจ้งลา" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่ลาเริ่มต้น" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.time_start)" placeholder="วันที่ลาเริ่มต้น" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่ลาสิ้นสุด" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.time_end)" placeholder="วันที่ลาสิ้นสุด" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="จำนวนวันลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_user_amount" placeholder="จำนวนวันลา" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="ประเภทวันลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="getLeaveTypeName(selectedLeave.leave_type_id)" placeholder="ประเภทวันลา" disabled></a-input>
                            </a-form-item>
                            <a-form-item label="การอนุมัติ" name="leave_user_no" style="width: 98%;">
                                <a-input :value="getApproveStatusName(selectedLeave.approve_status)" placeholder="การอนุมัติ" disabled></a-input>
                            </a-form-item>
                            <a-form-item v-if="business" label="โควตาการลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="(selectedLeave.leave_quota)" placeholder="โควตาการลา" disabled></a-input>
                            </a-form-item>
                            <p>เอกสารที่เกี่ยวข้อง:</p>
                            <div v-if="selectedLeave.photo_1 != null && selectedLeave.photo_1 != ''" class="image-container" style="margin-top: -5px; margin-bottom: 15px;">
                                <img :src="selectedLeave.photo_1" class="uploaded-image-table" alt="uploaded image" />
                                <div class="icon-container">
                                    <span class="material-symbols-outlined preview-icon" @click="showPreview($event, selectedLeave.photo_1)">
                                        visibility
                                    </span>
                                    <span class="material-symbols-outlined download-icon"
                                        @click="getBase64Image($event, selectedLeave.photo_1)">
                                        download
                                    </span>
                                </div>
                            </div>
                            <a-form-item label="หมายเหตุ" name="leave_user_remark" style="width: 98%;">
                                <a-textarea :value="selectedLeave.leave_user_remark" placeholder="หมายเหตุ" :rows="3" disabled></a-textarea>
                            </a-form-item>
                            <a-form-item label="ชื่อผู้อนุมัติการลา" name="approver" style="width: 98%; margin-top: 6%;">
                                <a-select style="width: 100%;" placeholder="ชื่อผู้อนุมัติ" v-model:value="selectedLeave.approver" disabled
                                        @change="(value) => handleChangeApprover(selectedLeave.id, value)">
                                        <a-select-option
                                            v-for="({ operation_id, fullname }, i) in operations"
                                            :key="i"
                                            :value="fullname"
                                            >
                                            {{ fullname }}
                                        </a-select-option>
                                    </a-select>
                            </a-form-item>
                        </div>
                        <template #footer>
                            <a-button type="primary" @click="showModalLeaveUserData=false">
                                ปิด
                            </a-button>
                        </template> 
                    </a-modal> 
                    <a-modal :title="'ออกรายงานการลา'" v-model:visible="showModalExport" @ok="showModalExport=false" >
                        <a-form layout="vertical">
                            <a-row type="flex">
                                <a-col :span="24">
                                    <a-form-item label="ผู้ส่งคำขอ" style="width: 98%;" :rules="[{ required: true, message: 'กรุณากรอกผู้ส่งคำขอ' }]">
                                        <a-select v-model:value="search_leave_user" placeholder="ผู้ส่งคำขอ">
                                            <a-select-option
                                                v-for="({ id, fullname }, i) in uniqueLeaveProgress2"
                                                :key="i"
                                                :value="fullname"
                                            >{{ fullname }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="24">
                                    <a-form-item style="width: 98%;" :rules="[{ required: true, message: 'กรุณากรอกประเภทการลา' }]">
                                        <span style="color: red; margin-right: 3px;">*</span><span>ประเภทการลา</span>
                                        <a-checkbox v-model="allLeaveTypes" style="margin-left: 8px; margin-right: 5px;" @change="handleCheckboxChange">
                                            ทั้งหมด
                                        </a-checkbox>
                                        <a-select v-model:value="search_leave_type" placeholder="ประเภทการลา" :disabled="allLeaveTypes == true">
                                            <a-select-option
                                                v-for="({ id, name }, i) in filtered_leave_types"
                                                :key="i"
                                                :value="id"
                                            >{{ name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="24">
                                    <a-form-item label="วันที่เริ่ม" style="width: 98%;" :rules="[{ required: true, message: 'กรุณากรอกวันที่เริ่ม' }]">
                                        <a-date-picker v-model:value="search_start_date" style="width: 100%;"></a-date-picker>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="24">
                                    <a-form-item label="วันที่สิ้นสุด" style="width: 98%;" :rules="[{ required: true, message: 'กรุณากรอกวันที่สิ้นสุด' }]">
                                        <a-date-picker v-model:value="search_end_date" style="width: 100%;"></a-date-picker>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                        </a-form>
                        <template #footer>
                            <a-button type="primary" @click="validateFields">
                                บันทึก
                            </a-button>
                        </template> 
                    </a-modal> 
                    <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false"
                        class="custom-modal" style="background-color: none !important">
                        <img :src="previewImage" alt="Preview" style="width: 100%;" />
                    </a-modal>
                </a-card>
            </a-spin>
        </div>
    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import AdminService from '../../api/AdminService';
import UserService from '../../api/UserService';
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import CompanyService from '../../api/CompanyService'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
// import * as XLSX from 'xlsx';
import * as XLSX from "xlsx-js-style";
export default {
    name: 'LeaveAdmin',
    computed: {
        uniqueLeaveProgress2() {
        const uniqueUsers = [];
        const userNames = new Set();
        this.Leave_progress2.forEach(item => {
            if (!userNames.has(item.fullname)) {
            userNames.add(item.fullname);
            uniqueUsers.push(item);
            }
        });
        return uniqueUsers;
        },
        filteredColumns() {
            if (Array.isArray(this.columns)) {
                return this.columns.filter(column => {
                    // ซ่อน leave_quota เมื่อ business เป็น false
                    return column.key !== 'leave_quota' || this.business;
                });
            }
            // ถ้า columns ไม่ใช่ array ให้คืนค่าเป็น array เปล่า
            return [];
        
        },
    },
    data() {
        return {
            leave_quota_data: [], // ดึงข้อมูลโควตาจาก API
            leave_quota_limits: {
                1: 3,  // ลากิจ: 3 ครั้ง/ปี
                2: 8,  // ลาพักร้อน: 8 ครั้ง/ปี
                7: 4,  // ลาฝึกอบรม: 4 ครั้ง/ปี
                4: 30, // ลาป่วย: 30 ครั้ง/ปี
                5: null, // ลาแบบไม่รับค่าจ้าง: ไม่มีเงื่อนไข
                6: null, // ลาชาปนกิจ: ไม่มีเงื่อนไข
            },
            previewImage: "",
            previewVisible: false,
            allLeaveTypes: false,
            showModalExport: false,
            search_username: null,
            search_leave_user: null,
            search_leave_type: null,
            search_start_date: null,
            search_end_date: null,
            Leave_progress2: [],
            selectedLeave: {},
            showModalLeaveUserData: false,
            timeStart: null,
            timeEnd: null,
            leave_types: [],
            loading: false,
            search_business_type: null,

            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            leave_types: [],
            business: false,
            filtered_leave_types: [],
            operations: [],
            fileList: [],
            title: 'ประวัติการแจ้งลา',
            button_title: 'ออกรายงานการลา',
            columns: [
                {
                    title: 'ชื่อ-นามสกุล ผู้ส่งคำขอ',
                    dataIndex: 'fullname',
                    key: 'fullname',
                    width: 200,
                    align: 'center',
                },
                {
                    title: 'วันที่แจ้งลา',
                    dataIndex: 'leave_user_timestamp',
                    key: 'leave_user_timestamp',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'วันที่ลา',
                    dataIndex: 'leave_user_date',
                    key: 'leave_user_date',
                    width: 180,
                    align: 'center',
                }, 
                {
                    title: 'เลขกำกับใบลา',
                    dataIndex: 'leave_user_no',
                    key: 'leave_user_no',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ปรเภทการลา',
                    dataIndex: 'leave_type_id',
                    key: 'leave_type_id',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'จำนวนวันลา',
                    dataIndex: 'leave_user_amount',
                    key: 'leave_user_amount',
                    width: 90,
                    align: 'center',
                }, 
                {
                    title: 'การอนุมัติ',
                    dataIndex: 'approve_status',
                    key: 'approve_status',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'โควตาการลา',
                    dataIndex: 'leave_quota',
                    key: 'leave_quota',
                    width: 150,
                    align: 'center',
                    // render: (text) => text || '-',
                },
                {
                    title: 'ชื่อผู้อนุมัติการลา',
                    dataIndex: 'approver',
                    key: 'approver',
                    width: 210,
                    align: 'center',
                }, 
                {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 130,
                    align: 'center',
                    fixed: 'right'
                }
            ],
        }
    },
    watch: {
        'detail.leave_type_id': function () {
                this.updateLeaveQuota();
            },
    },
    methods: {
        async getBase64Image(e, file) {
            e.stopPropagation();
            e.preventDefault();

            if (typeof file === 'string') {
                const result = await this.getBase64ImageFromURL(file);
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else if (file.url) {
                const result = await this.getBase64ImageFromURL(file.url);

                // download
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => {
                    const base64String = reader.result;
                    let a = document.createElement('a');
                    a.href = base64String;
                    a.download = 'image.png';
                    a.click();
                };
            }
        },
        showPreview(e, fileUrl) {
            e.stopPropagation();
            this.previewImage = fileUrl;
            this.previewVisible = true;
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        handleCheckboxChange() {
            this.allLeaveTypes = !this.allLeaveTypes;
            if (this.allLeaveTypes) {
                this.search_leave_type = null;
            }
        },
        validateFields() {
            const isUserValid = !!this.search_leave_user;
            const isTypeValid = this.allLeaveTypes || !!this.search_leave_type;
            const isStartDateValid = !!this.search_start_date;
            const isEndDateValid = !!this.search_end_date;

            if (!isUserValid) {
                this.$message.error('กรุณากรอกผู้ส่งคำขอ');
            }
            if (!isTypeValid) {
                this.$message.error('กรุณากรอกประเภทการลา');
            }
            if (!isStartDateValid) {
                this.$message.error('กรุณากรอกวันที่เริ่ม');
            }
            if (!isEndDateValid) {
                this.$message.error('กรุณากรอกวันที่สิ้นสุด');
            }

            if (isUserValid && isTypeValid && isStartDateValid && isEndDateValid) {
                this.import_export_dropdown();
            }
        },
        import_export_dropdown() {
            this.export_file()
            this.showModalExport = false
        },
        async export_file() {
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });

            const company_id = JSON.parse(localStorage.getItem('companyData')).id;
            const data = await SystemService.get_core('getLeaveAdminProgressTwo');
            console.log("data:", data);

            this.Leave_progress2 = await Promise.all(
                    data.map(async (item) => {
                        const username = item.user_name;
                        let leaveQuotaData = await SystemService.leavequota(company_id, username);
                        leaveQuotaData = leaveQuotaData.data;
                        const quota = leaveQuotaData.find(q => q.leave_type_id === item.leave_type_id);
                        const usedQuota = quota ? quota.count : 0;
                        const totalQuota = this.leave_quota_limits[item.leave_type_id] || '-';
                        return {
                            ...item,
                            leave_quota: totalQuota !== '-' ? `${usedQuota}/${totalQuota}` : '-',
                        };
                    })
                );
                const [leaveTypes, operations] = await Promise.all([
                    SystemService.get_core('getLeaveType'),
                    SystemService.get_core('getOperations'),
                ]);
                this.leave_types = leaveTypes;
                this.operations = operations;
            
                const filteredData = this.Leave_progress2.filter(item => {
                const matchesUser = !this.search_leave_user || (item.fullname && item.fullname.includes(this.search_leave_user));
                const matchesType = this.allLeaveTypes || item.leave_type_id === this.search_leave_type;
                const matchesStartDate = !this.search_start_date || dayjs(item.time_start).isSameOrAfter(dayjs(this.search_start_date), 'day');
                const matchesEndDate = !this.search_end_date || dayjs(item.time_end).isSameOrBefore(dayjs(this.search_end_date), 'day');
                const matchesDate = matchesStartDate && matchesEndDate;
                return matchesUser && matchesType && matchesDate;
            }).map(item => {
                const baseItem = {
                    fullname: item.fullname || '-',
                    leave_user_timestamp: item.leave_user_timestamp 
                        ? `${dayjs(item.leave_user_timestamp).format('YYYY/MM/DD')}` 
                        : '-',
                    leave_period: item.time_start && item.time_end 
                        ? `${dayjs(item.time_start).format('YYYY/MM/DD')} - ${dayjs(item.time_end).format('YYYY/MM/DD')}` 
                        : '-',
                    leave_user_no: item.leave_user_no || '-',
                    leave_type_id: this.getLeaveTypeName(item.leave_type_id) || '-',
                    leave_user_amount: item.leave_user_amount || '-',
                    approve_status: this.getApproveStatusName(item.approve_status) || '-',
                    approver: item.approver || '-'
                };

                // Add leave_quota only if business is true
                if (this.business) {
                    baseItem.leave_quota = item.leave_quota || '-';
                }

                return baseItem;
            });
            console.log("Filtered Data for Export:", filteredData);

            const headersColumn = [
                { header: "ชื่อ-นามสกุล ผู้ส่งคำขอ", key: "fullname" },
                { header: "วันที่แจ้งลา", key: "leave_user_timestamp" },
                { header: "วันที่ลา", key: "leave_period" },
                { header: "เลขกำกับใบลา", key: "leave_user_no" },
                { header: "ปรเภทการลา", key: "leave_type_id" },
                ...(this.business ? [{ header: "โควตาการลา", key: "leave_quota" }] : []),
                { header: "จำนวนวันลา", key: "leave_user_amount" },
                { header: "การอนุมัติ", key: "approve_status" },
                { header: "ชื่อผู้อนุมัติการลา", key: "approver" },
            ];

            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(filteredData, { header: headersColumn.map(h => h.key) });

            XLSX.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });

            headersColumn.forEach((header, colIndex) => {
                const cellAddress = XLSX.utils.encode_cell({ c: colIndex, r: 0 });
                const cell = workSheet[cellAddress];
                if (cell) {
                    cell.s = {
                        font: { bold: true, color: { rgb: "000000" } }, // color text
                        fill: { fgColor: { rgb: "FAF052" } }, // color background
                        alignment: { horizontal: "center", vertical: "center" },
                        border: {
                            top: { style: "thin", color: { rgb: "000000" } },
                            bottom: { style: "thin", color: { rgb: "000000" } },
                            left: { style: "thin", color: { rgb: "000000" } },
                            right: { style: "thin", color: { rgb: "000000" } }
                        }
                    };
                }
            });

            const range = XLSX.utils.decode_range(workSheet['!ref']);
            for (let R = range.s.r + 1; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cellAddress = XLSX.utils.encode_cell({ c: C, r: R });
                    const cell = workSheet[cellAddress];
                    if (cell) {
                        cell.s = cell.s || {};
                        cell.s.alignment = { horizontal: "center", vertical: "center" };
                        cell.s.border = {
                            top: { style: "thin", color: { rgb: "000000" } },
                            bottom: { style: "thin", color: { rgb: "000000" } },
                            left: { style: "thin", color: { rgb: "000000" } },
                            right: { style: "thin", color: { rgb: "000000" } }
                        };
                    }
                }
            }

            workSheet['!cols'] = [
                { wch: 50 },
                { wch: 25 },
                { wch: 45 },
                { wch: 30 },
                { wch: 20 },
                { wch: 22 },
                { wch: 20 },
                { wch: 30 },
                { wch: 30 },
            ];

            XLSX.utils.book_append_sheet(workBook, workSheet, "Report");
            XLSX.writeFile(workBook, "Leaves.xlsx");
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        },
        remove_leave_user(id){
            Swal.fire({
                title: 'คุณต้องการลบใช่หรือไม่??',
                text: `คุณจะไม่สามารถกู้คืนได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('LeaveAdmin', id)
                    this.onRefresh()
                }
            })
        },
        showLeaveDetails(record) {
            this.selectedLeave = record;
            this.showModalLeaveUserData = true;
        },
        onRefresh() {
             this.init_leave_admins()
        },
        async handleChangeApproveStatus(id, value) {
            await SystemService.update_all('LeaveAdmin', { data: { approve_status: value } }, id);
            this.onRefresh();
        },
        async handleChangeApprover(id, fullname) {
            await SystemService.update_all('LeaveAdmin', { data: { approver: fullname} }, id);
            this.onRefresh();
        },
        clear_search() {
            this.search_username = null;
            this.timeStart = null;
            this.timeEnd = null;
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        filtered_list(array) {
            return array.filter(({ fullname, time_start, time_end }) => {
                let matchesName = true;
                let matchesDateRange = true;
                if (this.search_username) {
                    matchesName = fullname ? fullname.toLowerCase().includes(this.search_username.toLowerCase()) : false;
                }
                // ตรวจสอบช่วงวันที่ (ไม่สนใจเวลา)
                if (this.timeStart && this.timeEnd) {
                    const filterStart = dayjs(this.timeStart).startOf('day');
                    const filterEnd = dayjs(this.timeEnd).startOf('day');
                    const start = dayjs(time_start).startOf('day');
                    const end = dayjs(time_end).startOf('day');
                    matchesDateRange = start.isSame(filterStart) && end.isSame(filterEnd);
                }
                return matchesName && matchesDateRange;
            });
        },
        async init_leave_admins() {
    try {
        const company_id = JSON.parse(localStorage.getItem('companyData')).id;
        const leaveProgressData = await SystemService.get_core('getLeaveAdminProgressTwo');
        console.log("leaveProgressData:", leaveProgressData);
        this.Leave_progress2 = [];
        for (const item of leaveProgressData) {
            const username = item.user_name;
            // console.log("Processing user:", username);
            let leaveQuotaData = await SystemService.leavequota(company_id, username);
            leaveQuotaData = leaveQuotaData.data;
            const quota = leaveQuotaData.find(q => q.leave_type_id === item.leave_type_id);
            const usedQuota = quota ? quota.count : 0;
            const totalQuota = this.leave_quota_limits[item.leave_type_id] || '-';
            this.Leave_progress2.push({
                ...item,
                leave_quota: totalQuota !== '-' ? `${usedQuota}/${totalQuota}` : '-',
            });
            // console.log("Current Leave_progress2:", this.Leave_progress2);
        }
        console.log("Final Leave_progress2:", this.Leave_progress2);
        const [leaveTypes, operations] = await Promise.all([
            SystemService.get_core('getLeaveType'),
            SystemService.get_core('getOperations')
        ]);

        this.leave_types = leaveTypes;
        this.operations = operations;
    } catch (error) {
        console.error('Error initializing leave admins:', error);
    }
},

        getLeaveTypeName(id) {
            const leaveType = this.leave_types.find(type => type.id === id);
            return leaveType ? leaveType.name : '';
        },
        getApproveStatusName(status) {
            if (status == 1) {
                return 'อนุมัติ';
            } else if (status == 2) {
                return 'ไม่อนุมัติ';
            } else {
                return '';
            }
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2 || businessType.data === 3)
            {
                console.log("Business type is 2, setting business to true.");
                this.business = true
            }
        },
        filter_leave_types() {
            // กรองประเภทการลาตาม business
            if (this.business) {
                this.filtered_leave_types = this.leave_types.filter(
                    (type) => ![ 3].includes(type.id) 
                );

                const order = [1, 2, 7, 4, 5, 6];
                this.filtered_leave_types.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
                
            } else {
                this.filtered_leave_types = this.leave_types.filter(
                    (type) => ![ 7].includes(type.id) 
                );
            }
            console.log("filtered_leave_types");
        },
        updateLeaveQuota() {
            if (this.detail.leave_type_id) {
                const quota = this.leave_quota_data.find(
                    (item) => item.leave_type_id === this.detail.leave_type_id
                );
                const usedQuota = quota ? quota.count : 0; // จำนวนที่ใช้ไป
                const totalQuota = this.leave_quota_limits[this.detail.leave_type_id]; // โควตาทั้งหมด

                if (totalQuota !== null) {
                    this.detail.leave_quota = `${usedQuota}/${totalQuota}`; // เช่น "1/3"
                } else {
                    this.detail.leave_quota = `-`; // ไม่มีเงื่อนไข
                }
            }
        },
    },
    mounted() {
        this.init_leave_admins().then(() => {
        this.get_company().then(() => {
            this.filter_leave_types();
        });

    });
    }
}
</script>

<style>
.preview-icon,
.download-icon {
    cursor: pointer;
    font-size: 20px;
    color: black;
}
.image-container {
    position: relative;
    display: inline-block;
}
.uploaded-image-table {
    display: block;
    width: 15rem;
    height: auto;
}
.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding: 5px;
}

.permission-table .ant-table-row-level-0 td:first-child,
.permission-table .ant-table-row-level-0 td:nth-child(2),
.permission-table .ant-table-row-level-0 td:nth-child(3) {
    background-color: #f9f9f9 !important;
}
.permission-table .ant-table-row-expand-icon::before,
.permission-table .ant-table-row-expand-icon::after 
{
    content: '' !important;
    border: none !important;
    background: none; /* ปิด background */
    transform: none !important;
    transition: none !important;
    top: auto !important
}
.permission-table .ant-table-row-expand-icon:not(.ant-table-row-expand-icon-expanded)::before {
    content: '>' !important;
}

.permission-table .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded::before {
    content: 'v' !important;
}
</style>