<template>
    <div class="app-content">
        <a-card title="Billing Detail">
            <template #extra>
                <a-button style="display: flex" @click="clear_model_data()" size="large">
                    <template #icon>
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                    </template>
                    ย้อนกลับ
                </a-button>
            </template>

            <a-form
                :model="billing_form" 
                layout="vertical" 
                :onFinish="submit_billing_payment"
            >
                <a-collapse style="border-radius: 4px" v-model:activeKey="activeKey">
                    <a-collapse-panel key="1" :header="`ใบวางบิลเลขที่: ${billing_form.billing_no}`">
                        <template #extra>
                            <p style="margin: 0">วันที่สร้าง : {{ render_date(billing_form.issue_date) }}</p>
                        </template>
                        <a-row>
                            <a-col :span="12">
                                <a-form-item style="padding: 0 4px" label="วันที่ออกบิล">
                                    <a-date-picker 
                                        v-if="on_edit.includes('issue_date')" show-time 
                                        v-model:value="newDate['issue_date']" 
                                        :show-time="{ format: 'HH:mm' }"
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="เลือกเวลา"
                                        :minuteStep="1"
                                        @change="save_newDate(newDate['issue_date'], 'issue_date', billing_form.id)"
                                    />
                                    <div v-else>
                                        {{ render_date(billing_form['issue_date'] ) }}
                                        <a @click="edit_date(billing_form['issue_date'] , 'issue_date')"><span class="material-symbols-outlined">edit</span></a>
                                    </div>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item style="padding: 0 4px" label="วันที่ครบกำหนด">
                                    <a-date-picker 
                                        v-if="on_edit.includes('expire_date')" show-time 
                                        v-model:value="newDate['expire_date']" 
                                        :show-time="{ format: 'HH:mm' }"
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="เลือกเวลา"
                                        :minuteStep="1"
                                        @change="save_newDate(newDate['expire_date'], 'expire_date', billing_form.id)"
                                    />
                                    <div v-else>
                                        {{ render_date(billing_form['expire_date'] ) }}
                                        <a @click="edit_date(billing_form['expire_date'] , 'expire_date')"><span class="material-symbols-outlined">edit</span></a>
                                    </div>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6">
                                <a-form-item style="padding: 0 4px" label="ชื่อลูกค้า/บริษัท">
                                    <a-input v-model:value="billing_form.bookings[0].car_company.fullname" disabled />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item style="padding: 0 4px" label="เลขประจำตัวผู้เสียภาษี">
                                    <a-input v-model:value="billing_form.bookings[0].car_company.tax_id" disabled />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item style="padding: 0 4px" label="เบอร์โทรศัพท์">
                                    <a-input v-model:value="billing_form.bookings[0].car_company.phone_no" disabled />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item style="padding: 0 4px" label="แฟกซ์">
                                    <a-input v-model:value="billing_form.bookings[0].car_company.fax_no" disabled />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24">
                                <a-form-item label="ที่อยู่สำหรับออกบิล" style="padding: 4px">
                                    <a-textarea v-model:value="billing_form.bookings[0].car_company.billing_address" disabled />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24">
                                <a-form-item label="หมายเหตุ" style="padding: 4px">
                                    <a-textarea v-model:value="billing_form.bookings[0].car_company.remark" disabled />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>
                    <a-collapse-panel key="2" header="รายละเอียด Booking" >
                        <a-table rowKey="id" :columns="[
                            {
                                title: 'QTC',
                                dataIndex: 'booking_no',
                                key: 'booking_no',
                                width: 150
                            },
                            {
                                title: 'จำนวนรถ',
                                dataIndex: 'booking_car_types',
                                key: 'booking_car_types',
                                width: 100,
                                align: 'center'
                            },
                            {
                                title: 'ราคา',
                                dataIndex: 'booking_car_types_amount',
                                key: 'booking_car_types_amount',
                                width: 118,
                                align: 'center'
                            },
                            {
                                title: 'รายละเอียด',
                                dataIndex: 'booking_car_types_name',
                                key: 'booking_car_types_name',
                                width: 216
                            },
                            // {
                            //     title: 'วันเวลา สร้าง Booking',
                            //     dataIndex: 'created_at',
                            //     key: 'created_at',
                            //     width: 150
                            // },
                            // {
                            //     title: 'วันเวลา เริ่มงาน',
                            //     dataIndex: 'time_start',
                            //     key: 'time_start',
                            //     width: 150
                            // },
                            // {
                            //     title: 'วันเวลา สิ้นสุดงาน',
                            //     dataIndex: 'time_end',
                            //     key: 'time_end',
                            //     width: 150
                            // },
                        ]" :data-source="billing_form.bookings" bordered size="small" :pagination="false">
                            <template #bodyCell="{ record, column }" >
                                <template v-if="column.key === 'booking_car_types'">
                                    {{ record.booking_car_types.map(({ quantity }) => quantity? quantity:0).reduce((a,b) => a+b,0) }}
                                </template>
                                <template v-else-if="column.key === 'booking_car_types_amount'">
                                    {{ billing_form.total_price.toLocaleString() }}
                                    <!-- {{ sum_car_price({ 
                                    cost: billing_form.total_price, 
                                    inc_vat: record.inc_vat,
                                    expenses: record.expenses,
                                    withholding: record.withholding, 
                                    car_company: record.car_company }, 'total').toLocaleString() }} -->
                                </template>
                                <template v-else-if="column.key === 'booking_car_types_name'">
                                    {{ record.remark }}
                                </template>
                                <template v-else-if="['created_at','time_start','time_end'].includes(column.key)">
                                    <a-date-picker 
                                        v-if="on_edit.includes(column.key)" show-time 
                                        v-model:value="newDate[column.key]" 
                                        :show-time="{ format: 'HH:mm' }"
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="เลือกเวลา"
                                        :minuteStep="1"
                                        @change="save_newDate(newDate[column.key], column.key, record.id)"
                                    />
                                    <div v-else>
                                        {{ render_date(record[column.key] ) }}
                                        <a @click="edit_date(record[column.key] , column.key)"><span class="material-symbols-outlined">edit</span></a>
                                    </div>
                                </template>
                            </template>
                        </a-table>
                    </a-collapse-panel>
                    <a-collapse-panel key="3" header="รายละเอียดการจ่ายเงิน" >
                        <a-table rowKey="id" :columns="[
                            {
                                title: 'ประเภท',
                                dataIndex: 'pay_method',
                                key: 'pay_method',
                                align: 'center',
                                width: 125
                            },
                            {
                                title: 'จำนวนเงิน',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                width: 125
                            },
                            {
                                title: 'รายละเอียด',
                                dataIndex: 'remark',
                                key: 'remark',
                            },
                            {
                                title: 'วันที่-เวลา',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                width: 150
                            },
                            {
                                title: 'ตัวเลือก',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                                width: 100
                            }
                        ]" :data-source="billing_form.billing_payments" bordered size="small" :pagination="false">
                            <template #bodyCell="{ record, column }" >
                                <template v-if="column.key === 'id'">
                                    <a @click="edit_payment_drawer(record)"><span style="font-size: 18px" class="material-symbols-outlined">edit</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, 'BillingPayment', index)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </template>
                                <template v-else-if="column.key === 'created_at'">
                                    {{ render_date(record.created_at) }}
                                </template>
                            </template>
                        </a-table>
                        <a-row justify="end">
                            <a-button @click="payment_drawer = true" type="primary" size="large" style="margin: 4px">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Add</span>
                                เพิ่มการจ่ายเงิน
                            </a-button>
                        </a-row>
                        <a-drawer
                            v-model:visible="payment_drawer"
                            :closable="false"
                            title="เพิ่มการจ่ายเงิน"
                        >
                            <a-form
                                :model="payment_drawer_form" name="payment_drawer_form" 
                                autocomplete="off"
                                layout="vertical"
                                @finish="payment_drawer_submit">
                                    <a-row>
                                        <a-col :span="12">
                                            <a-form-item label="ประเภทการจ่ายเงิน" style="padding: 4px" name="pay_method_id" :rules="[{ required: true, message: 'โปรดระบุประเภทการจ่ายเงิน' }]">
                                                <a-select v-model:value="payment_drawer_form.pay_method_id" placeholder="กรุณาเลือกประเภทการจ่ายเงิน">
                                                    <a-select-option v-for="{ id, name } in pay_method_list" :value="id">{{ name }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="12">
                                            <a-form-item label="จำนวนเงิน" style="padding: 4px" name="amount" :rules="[{ required: true, message: 'โปรดระบุจำนวนเงิน' }]">
                                                <a-input-number v-model:value="payment_drawer_form.amount" :min="0" :max="999999999" />
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                    <a-row>
                                        <a-col :span="24">
                                            <a-form-item label="รายละเอียด" style="padding: 4px">
                                                <a-textarea v-model:value="payment_drawer_form.remark" :rows="3" />
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                    <a-row >
                                        <a-col :span="24">
                                            <a-form-item label="วัน - เวลา" style="padding: 4px" name="created_at" :rules="[{ required: true, message: 'โปรดระบุจำนวนเงิน' }]">
                                                <a-date-picker v-model:value="payment_drawer_form.created_at" placeholder="กรุณาเลือกวันที่"
                                                    :show-time="{ format: 'HH:mm' }"
                                                    format="DD/MM/YYYY HH:mm"
                                                    :placeholder="['Start Time', 'End Time']"
                                                    :minuteStep="1"
                                                    :locale="lang_th"
                                                    style="width: 100%"
                                                />
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                    <a-row justify="center">
                                        <a-col>
                                            <a-upload v-model:file-list="fileList" list-type="picture-card"
                                                class="avatar-uploader" :show-upload-list="false"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, 'photo')" method="GET"
                                                :before-upload="setFileType"
                                                accept="image/*"
                                                >
                                                <img v-if="payment_drawer_form.photo" :src="payment_drawer_form.photo"
                                                    :style="`width: 100%;object-fit: cover`"
                                                    alt="avatar" />
                                                <div v-else>
                                                    <p class="ant-upload-drag-icon">
                                                        <span class="material-symbols-outlined"
                                                            style="font-size: 5rem">upload_file</span>
                                                    </p>
                                                    คลิกเพื่ออัพโหลดรูปภาพ
                                                </div>
                                            </a-upload>
                                        </a-col>
                                    </a-row>
                                    <a-row justify="end">
                                        <a-col>
                                            <a-form-item style="padding: 4px">
                                                <a-button type="primary" html-type="submit">
                                                    บันทึก
                                                </a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                            </a-form>
                        </a-drawer>
                    </a-collapse-panel>
                    <a-collapse-panel key="4" header="ยอดรวม" >
                        <a-row>
                            <a-col :span="12">
                                <a-form-item label="รวมค่าดำเนินการ" style="padding: 4px">
                                    <a-input v-model:value="billing_form.total_price" disabled />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="รวมค่าการจ่ายเงิน" style="padding: 4px">
                                    <a-input v-model:value="billing_form.total_paid" disabled />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>
                </a-collapse>
                <a-row justify="end">
                    <a-col :span="6">
                        <a-form-item label="Status:">
                            <a-input v-model:value="billing_form.billing_status" disabled />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-col :span="1">
                        <a-button type="primary" @click="clear_model_data()" >
                            บันทึก
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import Swal from 'sweetalert2';
import SystemService from '../../api/SystemService';
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';
import CarCompanyService from '../../api/CarCompanyService';

export default {
    name: 'BillingPayment',
    data() {
        return {
            activeKey: [],
            on_edit: [],
            newDate: {},
            payment_drawer: false,
            payment_drawer_form: {},
            fileList: [],
            lang_th: lang_th,
            billing_form: {
                
            },
            pay_method_list: []
        };
    },
    methods: {
        save_newDate(e, key, booking_id) {
            if (e) {
                const date = e.format('YYYY-MM-DD HH:mm:ss')
                if (date) {
                    if (['time_start','time_end'].includes(key)) {
                        SystemService.update_all('Booking', 
                        { 
                            data: {
                                [key]: date
                            }
                        }, booking_id)
                            .then(res => {
                                this.$message.success('บันทึกสำเร็จ')
                            })

                    } else {
                        const body = {
                            id: this.billing_form.id,
                            [key]: date
                        }
                        SystemService.update_all('Billing', { data: body }, body.id)
                            .then(res => {
                                this.$message.success('บันทึกสำเร็จ')
                            })

                    }
                }
            }
        },
        setFileType(file) {
            const fileType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' 
            || file.type === 'image/raw' || file.type === 'image/webp' || file.type === 'image/tif' || file.type === 'image/psd' || file.type === 'image/svg';
            if (!fileType) {
                this.$message.error('คุณสามารถอัปโหลดได้เฉพาะไฟล์รูปภาพเท่านั้น!');
            }
            return fileType
        },
        edit_date(date, key) {
            this.newDate[key] = dayjs(date)
            this.on_edit.push(key)
        },
        withholding_type(car_company, type) {
            if (car_company) {
                const { withholding_id } = car_company
                if (withholding_id == 1) {
                    return type == 'text'? `(1%)`: 0.01
                } else if (withholding_id == 2) {
                    return type == 'text'? `(5%)`: 0.05
                } else {
                    return type == 'text'? `(3%)`: 0.03
                }
            }
        },
        sum_car_price({ cost, expenses = [], inc_vat, withholding, car_company }, type) {
            var total = (Number(cost) + Number(expenses.map(({ amount, price }) => amount*price).reduce((a,b) => a+b, 0)))
            const vat = inc_vat? total * 0.07 : 0
            const withholding_type = this.withholding_type(car_company, 'number')
            const with_holding = withholding? total * withholding_type : 0

            const result = {
                total: total + vat - with_holding,
                vat,
                withholding: with_holding
            }
            
            return result[type]
        },
        upload_files(event, field) {
            const file = event.file.originFileObj
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                this.payment_drawer_form[field] = reader.result
            }
        },
        edit_payment_drawer(data) {
            this.payment_drawer = true
            this.payment_drawer_form = data
            this.payment_drawer_form.created_at = dayjs(data.created_at)
            this.fileList = [{
                uid: '-1',
                name: '',
                status: 'done',
                url: data.photo,
            }]
        },
        payment_drawer_submit() {
            this.payment_drawer = false

            if (this.payment_drawer_form.id) {
                SystemService.update_all('BillingPayment', { data: {
                    pay_method_id: this.payment_drawer_form.pay_method_id,
                    amount: this.payment_drawer_form.amount,
                    remark: this.payment_drawer_form.remark,
                    photo: this.payment_drawer_form.photo,
                    created_at: dayjs(this.payment_drawer_form.created_at).format('YYYY-MM-DD HH:mm:ss'),
                }}, this.payment_drawer_form.id)
                    .then(res => {
                        Swal.fire({
                            icon: 'success',
                            title: 'บันทึกข้อมูลสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.init_form()
                        this.payment_drawer_form = {}
                    })
            } else {
                SystemService.create_all('BillingPayment', { data: [{
                    billing_id: this.billing_form.id,
                    pay_method_id: this.payment_drawer_form.pay_method_id,
                    amount: this.payment_drawer_form.amount,
                    remark: this.payment_drawer_form.remark,
                    photo: this.payment_drawer_form.photo,
                    created_at: dayjs(this.payment_drawer_form.created_at).format('YYYY-MM-DD HH:mm:ss'),
                }]})
                    .then(res => {
                        Swal.fire({
                            icon: 'success',
                            title: 'บันทึกข้อมูลสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.init_form()
                        this.payment_drawer_form = {}
                    })
            }
            
        },
        go_remove(id, modelname, index){
            if (id) {
                Swal.fire({
                    title: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
                    showDenyButton: true,
                    confirmButtonText: `ลบ`,
                    denyButtonText: `ยกเลิก`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        SystemService.delete_all(modelname, id)
                            .then(res => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'ลบข้อมูลสำเร็จ',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.init_form()
                            })
                    }
                })
            } else {
                this.billing_form.billing_payments?.splice(index, 1)
            }
        },
        show_export_modal() {

        },
        submit_billing_payment() {

        },
        render_date(datetime) {
            return dayjs(datetime).format('DD/MM/YYYY HH:mm')
        },
        clear_model_data() {
            this.$emit('apply_model_data', {})
            this.$router.go(-1)
        },
        async init_form() {
            this.billing_form = await SystemService.get_id('Billing', this.$route.params.id)
            const carCom = await CarCompanyService.getById(this.billing_form.bookings[0].car_company_id);
            this.billing_form.bookings[0].car_company = carCom[0]||[];
            this.pay_method_list = await SystemService.get_all('PayMethod')
            this.billing_form.total_paid = this.billing_form.billing_payments?.map(({ amount }) => amount).reduce((a, b) => a + b, 0)
        }
    },
    mounted() {
        this.init_form()
    }
};
</script>