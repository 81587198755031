<template>
	<div class="dark-background menu-class">
		<a-menu
			v-model:openKeys="openKeys"
			v-model:selectedKeys="selectedKeys"
			mode="inline"
			theme="dark"
		>
			<section>
				<img
					style="
						width: 100%;
						max-height: 60px;
						object-fit: contain;
						background-color: #FFFFFF;
						padding: 10px; 
					"
					:src="logo"
				/>
			</section>

			<div v-if='!loading'
				v-for="({ key, name, path, icon, collapse, newtab }, i) in filteredMenus"
				:key="key"
			>
				<a-menu-divider></a-menu-divider>
				<a-sub-menu v-if="filteredCollapse(collapse,key,name,i).length" :key="`sub${i}`">
					<template #icon>
						<span
							style="font-size: 20px; margin: 0"
							class="material-symbols-outlined"
							>{{ icon }}</span
						>
					</template>
					<template #title>{{ name }}</template>
					<a-menu-item v-for="(sub, c) in filteredCollapse(collapse,key,name)" :key="`sub${i}${c}`">
						<router-link :to="sub.path">{{ sub.name }}</router-link>
					</a-menu-item>
				</a-sub-menu>

				<a-menu-item v-else :key="i">
					<template #icon>
						<img
							v-if="name == 'โปรเจกต์ลูกค้า'"
							src="https://img.icons8.com/ios/50/project.png"
							alt="project"
							width="20"
							height="20"
							style="margin: 0; filter: invert(100%);"
						/>
						<span
							v-if="name == 'แชทพนักงานขับรถ'"
							style="font-size: 20px; margin: 0;"
							class="material-symbols-outlined"
							>{{ icon }}  <span class="badge" v-if="chatValue > 0" style="font-size: 12px !important;">{{ chatValue }}</span>
							</span
						>
						<span
							v-else
							style="font-size: 20px; margin: 0"
							class="material-symbols-outlined"
							>{{ icon }}</span
						>
					</template>
					<a v-if="newtab" :href="path" target="_blank">{{ name }}</a>
					<router-link v-else :to="path">{{ name }}</router-link>
				</a-menu-item>
			</div>
			<a-modal v-model:visible="visible" title="เปลี่ยนรหัสผ่านใหม่" @ok="handleOk" :maskClosable="false" :closable="false" footer>
				<a-form :model="form" layout="vertical" @finish="onFinish">
							<a-form-item label="รหัสผ่านใหม่" name="password" :rules="[{ required: true, message: 'โปรดกรอกรหัสผ่านใหม่!' }]">
									<a-input-password v-model:value="form.password" type="password"></a-input-password>
							</a-form-item>
							<a-form-item>
									<a-button 
										type="primary" html-type="submit" 
										:disabled="form.password.length < 6" 
										style="margin-left: auto; width: '10rem'"
                    :loading="loadingForm"
									>ยืนยัน</a-button>
							</a-form-item>
					</a-form>
			</a-modal>
		</a-menu>
	</div>
</template>
<script>
import UserService from "../api/UserService";
import SystemService from "../api/SystemService";
import CompanyService from "../api/CompanyService";
import Swal from "sweetalert2";
import socketService from "../api/SocketService";
import DriversChannelService from "../api/DriversChannelService";
import CustomerProject from "../assets/icons/sidenav/customer-projects.png"
export default {
	data() {
		return {
			loading:true,
			filteredMenus:[],
			collapsed: false,
			selectedKeys: ["0"],
			openKeys: ["sub1"],
			preOpenKeys: ["sub1"],
			logo: require("@/assets/images/vecabo-label.png"),
			user_profile: null,
			businessTypeId: 0,
			chatValue : 0,
			users: [],
			socketService,
			links: [
				{
					key: "dashboard",
					name: "แดชบอร์ด",
					path: "/app/dashboard",
					icon: "speed",
					collapse: [],
				},
				{
					key: "calendar",
					name: "ตารางงาน",
					path: "/app/job/calendar",
					icon: "calendar_month",
					collapse: [],
				},
				{
					key: "schedule",
					name: "ตารางการใช้รถ",
					path: "/schedules",
					icon: "event_available",
					newtab: true,
					collapse: [],
				},
				{
					key: "manage_tour",
					name: "จัดการลูกค้าหรือบริษัท",
					icon: "group",
					collapse: [
						{
							key: "user_or_company",
							name: "ลูกค้าหรือบริษัท",
							path: "/app/customer/all",
						},
						{
							key: "coordinator",
							name: "ผู้ประสานงาน",
							path: "/app/vehicle/ClientContact",
						},
						{
							key: "customer_group_type",
							name: "ประเภทกลุ่มลูกค้า",
							path: "/app/customer/TourType",
						},
						{
							key: "external_customer",
						    name: 'ประวัติลูกค้าภายนอก',
						    path: '/app/customer/OutsideCustomer'
						},
						{
							key:"guide",
							name: "ไกด์",
							path: "/app/vehicle/Guide",
						},
					],
				},
				{
					key: "customer_projects",
					name: "โปรเจกต์ลูกค้า",
					path: "/app/project",
					icon: CustomerProject,
					collapse: [],
				},
				{
					key: "quotation",
					name: "ใบเสนอราคา",
					icon: "request_quote",
					collapse: [
						{
							key:"quotation_sub",
							name: "ใบเสนอราคา",
							path: "/app/quotation/all",
						},
						{
							key:"manage_notes",
							name: "จัดการหมายเหตุ",
							path: "/app/quotation-remark/all",
						},
						// {
						// 	key:"work_sheet",
						// 	name: "ใบงาน",
						// 	path: "/app/sheets/all",
						// },
					],
				},
				{
					key: "worksheet_management",
					name: "การจัดการใบงาน",
					icon: "book",
					collapse: [
						{
							key:"worksheet",
							name: "ใบงาน",
							path: "/app/sheet/all",
						},
					],
				},
				{
					key: "manage_car",
					name: "จัดรถ",
					icon: "request_quote",
					collapse: [
						{
							key:"manage_worksheet",
							name: "จัดการใบงาน",
							path: "/app/manageWorksheet/all",
						},
					],
				},
				{
					key: "billing",
					name: "การวางบิล",
					icon: "payments",
					collapse: [
						{
							key:"check_bill",
							name: "ตรวจสอบค่าใช้จ่ายใบงาน",
							path: "/app/checkBill/all",
						},
						{
							key:"wait_bill",
							name: "รอวางบิล",
							path: "/app/manage/booking",
						},
						{
							key:"overdue_bill",
							name: "บิลค้างชำระ",
							path: "/app/manage/billing",
						},
						{
							key:"monthly_report",
							name: "รายงานประจำเดือน",
							path: "/app/manage/monthly-report",
						},
						{
							key:"guarantee_work",
							name: "หลักค้ำประกันสัญญางาน",
							path: "/app/manage/guarantee",
						},
					],
				},
				{
					key: "booking",
					name: "การจองจัดรถ",
					path: "/app/booking/all",
					icon: "book",
					collapse: [],
				},
				{
					key: "company",
					name: "จัดการข้อมูลรถ",
					icon: "directions_car",
					collapse: [
						{
							key:"manage_car_company",
							name: "บริษัทรถ",
							path: "/app/vehicle/CarCompany",
						},
						{
							key:"manage_car_type",
							name: "ชนิดรถ",
							path: "/app/vehicle/CarType",
						},
						// {
						// 	key:"car_seat_type",
						// 	name: "ประเภทที่นั่งรถ",
						// 	path: "/app/vehicle/CarGroup",
						// },
						{
							key:"vehicle_employee",
							name: "พนักงานขับรถ",
							path: "/app/vehicle/Driver",
						},
						{
							key:"allowance",
							name: "เบี้ยเลี้ยง",
							path: "/app/vehicle/DriverCost",
						},
						{
							key:"all_car",
							name: "รถทั้งหมด",
							path: "/app/vehicle/Car",
						},
						{
							key:"vehicle_parking_spot",
							name: "จุดจอดยานพาหนะทั้งหมด",
							path: "/app/vehicle/Parking",
						},
						{
							key:"additional_car",
							name: "ค่าใช้จ่ายรถเพิ่มเติม",
							path: "/app/vehicle/Expense",
						},
						{
							key:"additional_qt",
							name: "รายการเพิ่มเติมใน QT",
							path: "/app/vehicle/Extra",
						},
						{
							key:"purpose_of_travel",
							name: "จุดประสงค์การเดินทาง (รูปแบบการเดินทาง)",
							path: "/app/vehicle/Trip",
						},
						{
							key:"expiry_date_report",
							name: "รายงานวันหมดอายุรถ",
							path: "/app/vehicle/Expire?table=Car",
						},
					],
				},
				// {
				// 	key: "manage_sales",
				// 	name: "จัดการงานขาย",
				// 	icon: "support_agent",
				// 	collapse: [
				// 		{
				// 			name: "พนักงานบริการลูกค้า CS",
				// 			path: "/app/Sale/all",
				// 		},
				// 	],
				// },
				{
					key: "manage_car_parts",
					name: "จัดการอะไหล่",
					icon: "precision_manufacturing",
					collapse: [
						{
							key:"parts_type",
							name: "ประเภทอะไหล่",
							path: "/app/auto-parts/PartType",
						},
						{
							key:"unit_parts",
							name: "หน่วยของอะไหล่",
							path: "/app/auto-parts/PartUnit",
						},
						{
							key:"manage_sale_vendor",
							name: "ผู้ขาย (vendor)",
							path: "/app/auto-parts/Vendor",
						},
						{
							key:"tradesman",
							name: "ช่าง",
							path: "/app/auto-parts/Technician",
						},
						{
							key:"manage_spare_parts",
							name: "จัดการอะไหล่",
							path: "/app/auto-parts/Part",
						},
						{
							key:"disbursement_parts",
							name: "เบิกจ่ายอะไหล่",
							path: "/app/auto-parts/StockHistory",
						},
						{
							key:"history_parts",
							name: "ประวัติเบิก-ถอนอะไหล่",
							path: "/app/auto-parts/PartHistory",
						},
					],
				},
				{
					key: "manage_repair",
					name: "แจ้งซ่อม/อุบัติเหตุ",
					icon: "build_circle",
					collapse: [
						{
							key: "manage_repair_main",
							name: "รายการแจ้งซ่อม",
							path: "/app/repair/all",
						},
						{
							key: "repair_type",
							name: "ประเภทการซ่อม",
							path: "/app/repair-type/all",
						},
						{
							key: "accident_report",
							name: "รายการแจ้งอุบัติเหตุ",
							path: "/app/accident/all",
						},
					],
				},
				{
					key: "gas_station",
					name: "ปั๊มน้ำมัน",
					icon: "local_gas_station",
					path: "/app/gas-station",
					collapse: [],
				},
			],
			visible: false,
      loadingForm: false,
			form: {
				password: '',
			},
		};
	},
	computed: {
        filteredMenus() {
            return this.filter_menus(this.links);
        },
    },
	methods: {
		connectToSocket() {
			this.socketService.connect();
			this.socketService.onBroadcast((message) => {
				if (message.type === 'send') {
					// New message received
					const userIndex = this.users.findIndex((user) => user.channel_id === message.data.channelId);
					if (userIndex !== -1) {
						const updatedUser = { ...this.users[userIndex], latestMessage: message.data };
						this.users.splice(userIndex, 1); // Remove old user data
						this.users.unshift(updatedUser); // Add updated user data at the top
					}
				// Update unread count dynamically
				this.updateChatValue();
				} else if (message.type === 'mark') {
					// Message marked as read
					const userIndex = this.users.findIndex((user) => user.latestMessage.messageId === message.data.messageId);
					if (userIndex !== -1) {
						this.users[userIndex].latestMessage.isRead = message.data.isRead;
					}

				// Update unread count dynamically
				this.updateChatValue();
				}
			});
		},
		disconnectFromSocket() {
			this.socketService.disconnect();
		},
		updateChatValue() {
 			this.chatValue = Array.isArray(this.users) ? this.users.filter(
				(user) =>
				user.latestMessage &&
				!user.latestMessage.isRead &&
				user.latestMessage.sentBy.role === 'Driver'
			).length
			: 0;
		},
		toggleCollapsed() {
			this.collapsed = !this.collapsed;
			this.openKeys = this.collapsed ? [] : this.preOpenKeys;
			this.$emit("collapsed", this.collapsed);
		},
		async onFinish(values) {
			const res = await SystemService.savePassword(values); 
      if (res.status == 'success') {
        this.loadingForm = false;
        this.visible = false;
        Swal.fire({
          icon: 'success',
          title: 'เปลี่ยนรหัสผ่านสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        this.loadingForm = false;
        Swal.fire({
          icon: 'error',
          title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        });
      }
		},
		async get_user_profile() {
			try {
				const data = await UserService.get_user_profile();
				this.data = data;
				const response = await DriversChannelService.getAllDriversChannelWithDriver();
				this.users = response;
				this.updateChatValue();
				if (data) {
					this.user_profile = data;
					if(data.company_id){
						let result = await CompanyService.getBusinessId(data.company_id)
						this.businessTypeId = result.data
					}
					const flagCheck = data.flag_check;
					if (flagCheck === 0 || (Array.isArray(flagCheck?.data) && flagCheck.data[0] === 0)) {
						this.visible = true;
					}
					if (this.businessTypeId == 2 || this.businessTypeId == 3) {
						this.links.push({
						key: "roll_call",
						name: "ติดตามรถ",
						icon: "directions_car",
						collapse: [
							{
								key: "car_tracking",
								name: "ติดตามรถ (Roll Call & Tracking)",
								path: "/app/tracking/all",
							},
							{
								key: "check_car",
								name: "ตรวจเช็ครถ (Roll Call)",
								path: "/app/roll-call/all",
							},
						],
						},
						{
							key: "manage_users",
							name: "ผู้ใช้งาน",
							icon: "settings_accessibility",
							path: "/app/manageUsers",
							collapse: [],
						},
						{
							key: "register_admin",
							name: "สมัครสมาชิก",
							icon: "person_add",
							path: "/app/admin",
							collapse: [],
						});
					} else {
						this.links.push({
						key: "roll_call",
						name: "ตรวจเช็ครถ (Roll Call)",
						icon: "ambulance",
						path: "/app/roll-call/all",
						collapse: []
						},
						{
							key: "manage_users",
							name: "ผู้ใช้งาน",
							icon: "settings_accessibility",
							path: "/app/manageUsers",
							collapse: [],
						},
						{
							key: "register_admin",
							name: "สมัครสมาชิก",
							icon: "person_add",
							path: "/app/admin",
							collapse: [],
						},);
					}
				this.links.push({
					key: "cr_chat",
					name: "แชทพนักงานขับรถ",
					icon: "forum",
					path: "/app/chat",
					collapse: [],
				},)
				this.links.push({
					key: "leave_management",
					name: "การจัดการพนักงาน",
					icon: "contact_mail",
					collapse: [
						{
							key: "leave_admin",
							name: "จัดการคำขอลา",
							path: "/app/leaveAdmin/all"
						}
					]
				},)
				this.loading = false
				}
			} catch (error) {
				console.error("Failed to fetch user profile:", error);
				this.loading = false
				// จัดการข้อผิดพลาดตามที่ต้องการ เช่น การแสดงข้อความข้อผิดพลาด
			}
		},
		filter_menus(array) {
			try {
			let results = [];
			if (this.user_profile && this.user_profile.permission) {
				results = array.filter((menu) => {
					if (this.user_profile.permission[menu.key] >= 1) {
						if ((menu.key === "booking" || menu.key === "quotation") && this.businessTypeId == 3)  {
							return false;
						}
						if ((menu.key === "customer_projects" || menu.key === "worksheet_management" || menu.key === "worksheet" || menu.key === "manage_car") && this.businessTypeId != 3)  {
							return false;
						}
						if (menu.key === "billing" && (this.businessTypeId == 3)) {
                            menu.name = "การเงินและการวางบิล";
                        }
						// if (menu.key === "quotation") {
						// 	menu.name = this.businessTypeId != 2 ? "ใบเสนอราคา" : "การจัดการใบงาน";
						// }
						if (menu.collapse) {
							menu.collapse = menu.collapse.filter(
								(subMenu) => {
									if (subMenu.key === "wait_bill" && this.businessTypeId == 3) {
										subMenu.name = "รายการรอวางบิล";
									}
									if (subMenu.key === "overdue_bill" && this.businessTypeId == 3) {
										subMenu.name = "จัดการวางบิลและเบิกจ่าย";
									}
									if (subMenu.key === "monthly_report" && this.businessTypeId == 3) {
										subMenu.name = "สรุปบัญชี";
									}
									return this.user_profile.permission[subMenu.key] >= 1
								}
							);
						}
					return true;
				}
				return false;
				});
			}
			return results;
		}
		catch (error) {
			console.error("Failed to filter menus:", error);
			// จัดการข้อผิดพลาดตามที่ต้องการ เช่น การแสดงข้อความข้อผิดพลาด
			}
		},
		filteredCollapse(collapse, key, name,i) {
    	if (this.businessTypeId == 2 || this.businessTypeId == 3) {
			return collapse
            .filter((item) => {
				if (this.businessTypeId != 3 && item.key === "check_bill") {
                    return false;
                }
                // if (item.key === "quotation_sub") {
				// 	item.name = "ใบงาน";
				// }
                return item.name !== "หลักค้ำประกันสัญญางาน" && item.name !== "เบี้ยเลี้ยง" && item.name !== "ไกด์";
            });
			} else if (this.businessTypeId != 2) {
			return collapse.filter((item) => item.name !== "ประวัติลูกค้าภายนอก" 
			// && item.name !== "ตรวจสอบค่าใช้จ่ายใบงาน"
		);
			}
		return collapse;
		},
	},
	watch: {
		openKeys(_val, oldVal) {
			this.preOpenKeys = oldVal;
		},
	},
	mounted() {
		this.connectToSocket();
		this.get_user_profile();
		this.$emit("register_toggleCollapsed", this.toggleCollapsed);
	},
	beforeUnmount() {
		this.disconnectFromSocket();
	},
};
</script>

<style>
.ant-menu-item-divider {
	border-color: #03145A !important;
}

.ant-menu-dark {
	background-color: #23316E !important;
}

.ant-menu-sub {
	box-shadow: inset 0 2px 8px #00000073 !important;
	background-color: #03145A !important;
}

.ant-menu-item-selected {
	background-color: #23316E !important;
}
.badge {
  position: absolute;
  top: -5px;
  left: 28px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px; 
  font-weight: bold;
  line-height: 1; 
  text-align: center;
  display: inline-block; 
}

</style>