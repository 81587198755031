<template>
  <div style="height: 100vh; padding: 15px">
    <div class="app-content">
      <a-card title="จัดการใบงาน">
        <a-row>
          <a-col :span="6">
            <a-input-search
              v-model:value="search_input"
              placeholder="ค้นหาใบงาน"
              enter-button="Search"
            />
          </a-col>
          <a-col style="margin-left: 1rem">
            <a-dropdown v-model:visible="booking_filter_visible" :trigger="['click']">
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1">
                    <span>1.สถานะใบงาน</span>
                    <a-select
                      style="width: 280px"
                      v-model:value="status"
                      placeholder="สถานะใบงาน"
                      @change="handleStatusChange"
                    >
                    <a-select-option :value="1">ใบงานว่าง</a-select-option>
                    <a-select-option :value="2">รอรายละเอียด</a-select-option>
                    <a-select-option :value="3">มอบหมายงานแล้ว</a-select-option>
                    </a-select>
                  </a-menu-item>
                  <a-menu-item key="2">
                    2.ต้นทาง
                    <a-input
                      v-model:value="search_origin"
                      style="width: 280px"
                      :placeholder="'ต้นทาง'"
                    />
                    <!-- <vue-google-autocomplete
                      id="origin"
                      v-model:value="origin"
                      placeholder="เลือกต้นทาง"
                      types="establishment"
                      country="th"
                    >
                    </vue-google-autocomplete> -->
                  </a-menu-item>
                  <a-menu-item key="3">
                    3.ปลายทาง
                    <a-input
                      style="width: 280px"
                      v-model:value="search_destination"
                      placeholder="ปลายทาง"
                    />
                  </a-menu-item>
                  <a-menu-item key="4">
                    4.ลูกค้า/บริษัท
                    <a-select
                      style="width: 280px"
                      v-model:value="search_client_company"
                      placeholder="ลูกค้า/บริษัท"
                      :options="
                          client_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                    >
                    </a-select>
                  </a-menu-item>
                  <a-menu-item key="5">
                    5.บริษัทรถ
                    <a-select
                      style="width: 280px"
                      v-model:value="search_car_company"
                      placeholder="บริษัทรถ"
                      :options="
                        car_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                    >
                    </a-select>
                  </a-menu-item>
                  <a-menu-item key="6">
                    6.ผู้ดูแลใบงานฝ่ายปฏิบัติการ
                    <a-select
                      style="width: 280px"
                      v-model:value="search_operation"
                      placeholder="ผู้ดูแลใบงานฝ่ายปฏิบัติการ"
                      :options="
                        operation_list.map(({ id, fullname }) => ({
                          value: id,
                          label: `${fullname}`,
                        }))
                      "
                    >
                    </a-select>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button style="width: 225px">
                <div
                  v-if="!car_filter_title"
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <span style="color: grey">ข้อมูลใบงาน</span>
                  <span
                    class="material-symbols-outlined"
                    style="font-size: 16px; color: grey"
                    >directions_car</span
                  >
                </div>
                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                {{ car_filter_title }}
                </div>
              </a-button>
            </a-dropdown>
          </a-col>
          <a-range-picker
            v-model:value="datetime"
            style="width: 300px; margin-left: 1rem"
            format="YYYY/MM/DD"
          />
          <a-button @click="clear_search" type="dashed" style="margin-left: 1rem"
            >CLEAR</a-button
          >
          <!-- <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="show_need_cars" />
            <span style="margin-left: 5px">จัดรถไม่ครบ</span>
          </a-col>
          <a-select
            v-model:value="search_booking_status"
            label-in-value
            style="width: 135px; margin-left: 1rem"
            placeholder="สถานะการจัดรถ"
            @change="handleTableChange"
          >
            <a-select-option :value="0">ครบ</a-select-option>
            <a-select-option :value="1">ไม่ครบ</a-select-option>
            <a-select-option :value="2">เกิน</a-select-option>
          </a-select> -->
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="require_rollcall" />
            <span style="margin-left: 5px">อนุญาติเฉพาะรถผ่าน RollCall</span>
          </a-col>
        </a-row>

        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane v-for="tab in tabsCustomer" :key="tab.status">
            <template #tab>
              <a-badge :count="count_by_status(tab.status)">
                <div class="google-icon">
                  <span class="material-symbols-outlined">{{ tab.icon }}</span>
                  <span>{{ tab.title }}</span>
                </div>
              </a-badge>
            </template>
          </a-tab-pane>
        </a-tabs>

        <a-table
          rowKey="id"
          :columns="[
            {
              title: 'QTC',
              dataIndex: 'booking_no',
              key: 'booking_no',
              width: 160,
              sorter: {
                compare: (a, b) => b.booking_no - a.booking_no,
                multiple: 1,
              },
              align: 'center',
            },
            {
              title: 'สถานะใบงาน',
              dataIndex: 'status',
              key: 'status',
              width: 150,
              align: 'center',
              sorter: {
                compare: (a, b) => {
                  const currentDate = new Date();
                  const diffA = Math.abs(new Date(a.time_start) - currentDate);
                  const diffB = Math.abs(new Date(b.time_start) - currentDate);

                  if (diffA === diffB) {
                    const statusPriority = {
                      'ใบงานว่าง': 1,
                      'รอรายละเอียด': 2,
                      'มอบหมายงานแล้ว': 3
                    };

                    const statusA = statusText(a) ? statusText(a).txt : '';
                    const statusB = statusText(b) ? statusText(b).txt : '';

                    return statusPriority[statusA] - statusPriority[statusB];
                  }

                  return diffA - diffB;
                },
                multiple: 1,
              },
            },
            {
              title: 'วันที่ - เวลารับสินค้า',
              dataIndex: 'time_start',
              key: 'time_start',
              width: 160,
              align: 'center',
              sorter: {
                compare: (a, b) => new Date(b.time_start) - new Date(a.time_start),
                multiple: 2,
              },
            },
            {
              title: 'วันที่ - เวลาส่งสินค้า',
              dataIndex: 'time_end',
              key: 'time_end',
              width: 160,
              align: 'center',
              sorter: {
                compare: (a, b) => new Date(b.time_end) - new Date(a.time_end),
                multiple: 3,
              },
            },
            {
              title: 'จำนวนวัน',
              dataIndex: 'countDays',
              key: 'countDays',
              width: 100,
              align: 'center',
              sorter: {
                compare: (a, b) => b.countDays - a.countDays,
              },
            },
            {
              title: 'สถานะรถ',
              dataIndex: 'car_status',
              key: 'car_status',
              width: 150,
              align: 'center',
            },
            {
              title: 'ต้นทาง',
              dataIndex: 'origin',
              key: 'origin',
              width: 160,
              align: 'center',
            },
            {
              title: 'ปลายทาง',
              dataIndex: 'destination',
              key: 'destination',
              width: 160,
              align: 'center',
            },
            {
              title: 'สถานะงาน',
              dataIndex: 'active_job',
              key: 'active_job',
              width: 120,
              align: 'center',
            },
            {
              title: 'บริษัทรถ',
              dataIndex: 'car_company',
              key: 'car_company',
              width: 230,
              align: 'center',
            },
            {
              title: 'ผู้ดูแลใบงานฝ่ายปฏิบัติการ',
              dataIndex: 'operation',
              key: 'operation',
              width: 230,
              align: 'center',
            },
            {
              title: 'ชื่อ-นามสกุล ผู้ขับ',
              dataIndex: 'driver_id',
              key: 'driver_id',
              width: 200,
              align: 'center',
            },
            {
              title: 'ทะเบียนรถ',
              dataIndex: 'plate_no',
              key: 'plate_no',
              width: 150,
              align: 'center',
            },
            {
              title: 'ลูกค้า/บริษัท',
              dataIndex: 'client',
              key: 'client',
              width: 230,
              align: 'center',
            },
            {
              title: 'โปรเจกต์ลูกค้า',
              dataIndex: 'project_name',
              key: 'project_name',
              width: 200,
              align: 'center',
              ellipsis: true,
            },
            {
              title: 'แก้ไขล่าสุด',
              dataIndex: 'updated_at',
              key: 'updated_at',
              width: 160,
              align: 'center',
            },
            {
              title: 'ตัวเลือก',
              dataIndex: 'id',
              key: 'id',
              width: 160,
              fixed: 'right',
              align: 'center',
            },
          ]"
          :data-source="filtered_list(sheets_data)" 
          bordered 
          size="small" 
          :scroll="{ x: 'calc(100px + 50%)', y: '100%' }" 
          :locale="{ emptyText: '' }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <span>
                <a-tag v-if="statusText(record)" :color="statusText(record).color">
                  {{ statusText(record).txt }}
                </a-tag>
              </span>
            </template>
            <template v-if="column.key == 'time_start'">
              <a-tag color="green">{{ render_datetime(record.time_start,record.time_product) }}</a-tag>
            </template>
            <template v-if="column.key == 'time_end'">
              <a-tag color="green">{{ render_datetime(record.time_end,record.time_product_finish) }}</a-tag>
            </template>
            <template v-if="column.key === 'countDays'">
              <a-tag color="green">{{ countDays(record.time_start, record.time_end) }}</a-tag>
            </template>
            <template v-if="column.key === 'car_status'">
              <span >
                <a-tag v-if="statusCar(record)" :color="statusCar(record).color">
                  {{ statusCar(record).txt }}
                </a-tag>
              </span>
            </template>
            <template v-if="column.key === 'operation'">
              <div>{{ record.operation && record.operation.fullname ? record.operation.fullname : '' }}</div>
            </template>
            <template v-if="column.key === 'driver_id'">
              <span v-if="record.booking_cars[0]?.driver_id && !record.booking_cars[0].temporary_driver">
                {{ getDriverName(record.booking_cars[0]?.driver_id) ?? '' }}
              </span>
              <span v-else-if="record.booking_cars[0].temporary_driver">
                {{ record.booking_cars[0].temporary_driver }}
              </span>
            </template>
            <template v-if="column.key === 'plate_no'">
              <div>{{ record.booking_cars[0]?.car?.plate_no ?? '' }}</div>
            </template>
            <template v-if="column.key === 'client'">
              <div>{{ record.client.fullname }}</div>
            </template>
            <template v-if="column.key === 'car_company'">
              <div>{{ record.car_company.fullname }}</div>
            </template>
            <template v-if="column.key === 'project_name'">
              <div>{{ record.project ? record.project.project_name : '' }}</div>
            </template>
            <template v-if="column.key === 'destination'">
              <div>{{ render_des(record) || '-' }}</div>
            </template>
            <template v-if="column.key === 'active_job'">
              <a-button
                type="danger"
                @click="activate_job(record)"
                :disabled="record.active_job == 1"
                >{{ record.active_job ? "แจ้งงานแล้ว" : "แจ้งงาน" }}
                </a-button>
            </template>
            <template v-if="column.key === 'id' && record[column.key]">
              <div>
                <a-tooltip>
                  <template #title>อัพเดทข้อมูล</template>
                  <a @click="show_update_progress(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >update</span
                    ></a
                  >
                </a-tooltip>
                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>แก้ไข</template>
                  <a @click="go_to_edit_quotation(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >drive_file_rename_outline</span
                    ></a
                  >
                </a-tooltip>
                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>ยกเลิก</template>
                  <a @click="remove_booking(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >delete</span
                    ></a
                  >
                </a-tooltip>
              </div>
            </template>
          </template>
        </a-table>
      </a-card>
    </div>

    <a-modal
      v-model:visible="update_progress_modal"
      @ok="update_progress"
    >
    <template #title>
      Booking ID : {{ selected_booking?.booking_no }}
    </template>
      <a-card v-if="activeKey == 2 || activeKey == null">
        <a-form :model="selected_booking" layout="vertical">
          <a-form-item label="สถานะ">
            <a-select v-model:value="selected_booking.status" placeholder="เลือกสถานะ">
              <a-select-option
                v-for="{ status, title } in filteredBookingStatus"
                :key="status"
                :value="status"
              >
                {{ title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
      <a-card v-if="activeKey != 2 && activeKey != null">
        <a-form :model="selected_booking" layout="vertical">
          <a-form-item label="สถานะ">
            <a-select v-model:value="this.selected_booking.booking_cars[0].status" placeholder="เลือกสถานะ">
              <a-select-option
                v-for="{ status, title } in filteredBookingStatus"
                :key="status"
                :value="status"
              >
                {{ title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
    </a-modal>

    <a-modal v-model:visible="showModalActiveJob" @ok="showModalActiveJob=false" >
      <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
          <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
          <p>กรุณาระบุชื่อคนขับ ก่อนกดแจ้งงาน</p>
      </div>
      <template #footer>
        <a-button type="primary" @click="showModalActiveJob=false">
          ตกลง
        </a-button>
      </template> 
    </a-modal> 

    <JobOrder
      v-if="job_order"
      :booking_detail="export_data"
      :user_name="user_name"
      :selected_driver_id="selected_driver_id"
      @close="job_order = false"
    />
    <BookingUpdatedPopup
      :isVisible="BookingUpdatedVisible"
      :booking_updated="bookingUpdatedData"
      @close="closeBookingUpdatePopup"/>
  </div>

</template>

<script>
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import BookingService from "../../api/BookingService";
import CompanyService from "../../api/CompanyService";
import { exportXLSXFile } from "@/components/helpers.js";
import ReportService from "../../api/ReportService";
import Swal from "sweetalert2";
import JobOrder from "@/components/modals/job_order.vue";
import Utility from "../../utility";
import SystemService from "../../api/SystemService";
import OperationService from "../../api/OperationService";
import { message } from "ant-design-vue";
import th from "dayjs/locale/th";
import BookingUpdatedPopup from "@/components/modals/booking_update_popup.vue";
import SheetService from '../../api/SheetService';
import UserService from "../../api/UserService";
import CarCompanyService from '../../api/CarCompanyService';
import { co, s } from "@fullcalendar/core/internal-common";
import socketService from "../../api/SocketService";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { JobOrder ,BookingUpdatedPopup, VueGoogleAutocomplete},
  props: {
    user_name: String,
  },
  name: "ManageWorksheet",
  data() {
    return {
      original_sheets_data: [],
      car_company_list: [],
      operation_list:[],
      client_company_list: [],
      search_operation: null,
      search_client_company: null,
      search_car_company: null,
      search_destination: null,
      search_origin: null,
      status: null,
      booking_filter_visible: false,
      selected_booking_status: null,
      socketService,
      showModalActiveJob: false,
      companyId: null,
      ranges: {
        'Today': [dayjs(), dayjs()]  // Use dayjs() to get today's date
      },
      filteredTabs: [],
      customOrder: [0, 1, 2, 11, 8, 9, 10, 3, 5, 6, 14, 15],
      model_form: {
          key: null // Initialize with your appropriate key
      },
      //BookingUpdatedPopup
      booking_business: false,
      business: false,
      bookingStatusData: [], 
      bookingStatusCarData: [], 
      BookingUpdatedVisible: false,
      bookingUpdatedData: null,
      bookingUpdated: {
        booking_no: '', 
        total_cars_expense: null, 
      },
      tabsCustomer: [
        { icon: 'menu', title: 'ทั้งหมด', status: null, activate_job: 1, roles: ['adminTM', 'preDelivery','planner','rollCall','others','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'จัดรถแล้ว', status: 2, roles: ['others','planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'แจ้งงาน', status: 11, roles: ['others','rollCall','planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'รับงาน', status: 8, roles: ['rollCall','others',,'planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'ยกเลิกการเดินทาง', status: 6, roles: ['others','rollCall','planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'ปฏิเสธงาน', status: 14, roles: ['planner', 'rollCall', 'others','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'ตรวจสอบ', status: 15, roles: ['adminTM','others','Accounting','hr','info','transport','repair'] },
      ],
      bookingStatus: [
        { title: 'ทั้งหมด', status: null },
        { title: 'จัดรถแล้ว', status: 2, },
        { title: 'รับงาน', status: 8 },
        { title: 'รถออกจากลานจอด', status: 9 },
        { title: 'รถถึงต้นทาง', status: 16 },
        { title: 'กำลังขึ้นของ', status: 10 },
        { title: 'อยู่ระหว่างขนส่ง', status: 17 },
        { title: 'รถถึงปลายทาง', status: 19 },
        { title: 'กำลังลงของ', status: 18 },
        { title: 'จบงาน', status: 5, },
        { title: 'ยกเลิกการเดินทาง', status: 6 },
        { title: 'ปฏิเสธงาน', status: 14 },
        { title: 'ตรวจสอบ', status: 15 },

        { title: 'แนะนำการจัดรถ', status: 0 },
        { title: 'รอจัดรถ', status: 1 },
        { title: 'ถึงจุดรับรถแล้ว', status: 3 },
        { title: 'กำลังเดินทาง', status: 7 },
        { title: 'แจ้งงาน', status: 11 },
        { title: 'รับของ', status: 12 },
        { title: 'รับของเสร็จสิ้น', status: 13 },
        { title: 'ตรวจสอบแล้ว', status: 20 },
        { title: 'รอตรวจสอบ', status: 21 },
      ],
      showWarningTollWays: false,
      showWarningGasRefills: false,
      showWarningExpenses: false,

      editTollWay: null,
      newTollWay: false,
      newGas: false,
      newExpense: false,

      selected_booking: {
        booking_cars: [
          {
            plate_no: null,
          },
        ],
      },

      editable: Utility.get_permission_editable_with_key("booking"),
      booking_list: [],
      pagination: {
        total: 0,
        onpage: 1,
        perPage: 20,
      },
      ///filter
      search_input: null,
      datetime: null,
      search_booking_status: null,
      search_plate_no: null,

      activeKey: null,
      show_need_cars: false,
      search_booking_status: null,
      require_rollcall: false,
      selected_driver_id: null,
      tag_option: {
        bookingType: {
          1: { txt: "เที่ยวเดียว (ขาเดียว)", color: "pink" },
          2: { txt: "ไป-กลับ", color: "red" },
          3: { txt: "รายวัน (เต็มวัน)", color: "orange" },
          4: { txt: "รายวัน (ครึ่งวัน)", color: "green" },
          5: { txt: "เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)", color: "cyan" },
          6: { txt: "เฉพาะวัน (ระบุวัน)", color: "blue" },
          7: { txt: "ใช้รถทุกวัน", color: "purple" },
          8: { txt: "รายเดือน", color: "green" },
        },
      },
      gas: {
        bill_photo_show: [],
        indexGas:null,
        orderPos:null,
      },
      bill_photo_show_source:"",
      toll_way_photo:[],
      billing_color: {
        1: "green",
        2: "red",
        3: "purple",
        4: "yellow",
      },
      selected_booking: null,
      export_data: null,
      show_monthly_report: false,
      loading: false,
      show_timeline: false,
      job_order: false,

      /* update_progress_modal */
      update_progress_modal: false,

      /* update_booking_car_status_modal */
      update_booking_car_status_modal: false,

      /* total_cars_expense_modal */
      total_cars_expense_modal: false,

      pay_method_list: [],
      expense_log: {
        toll_ways: null,
        gas_refills: null,
        extras: null,
      },
      expense_logs: [
        {
          id: 1,
          name: "อนุมัติ",
        },
        {
          id: 2,
          name: "ไม่อนุมัติ",
        },
      ],
      show_gas_refill_drawer: false,
      create_gas_refill: {
        bill_photo:[]
      },
      gas_station_list: [],
      booking_status_list:[],
      driver_name: [],
      countTabStatus: null,

      show_create_toll_way: false,
      create_toll_way: {},

      show_create_expense: false,
      create_expense: {},
      expense_list: [],
      car_list: [],

      total_car_expenses_collapse: null,
      show_export_excel: false,
      export_excel: {
        time_start: null,
        time_end: null,
        plate_no: null,
      },
      selectedTypeReport : null,
      selectedTypeReportClient: null,
      bookingCarExpenseModal: false,
      bookingCarExpenseData:[],
      userBusinessId:null,
      tabsCheck:[],
      tabsStatusOn:[],
      company_list:[],
      sheets_data:[],
    };
  },
  computed: {
    car_filter_title() {
      this.car_filter_list = [];
      if (this.status) {
        const statusLabel = this.status === 1 ? 'ใบงานว่าง' : this.status === 2 ? 'รอรายละเอียด' : 'มอบหมายงานแล้ว';
        this.car_filter_list.push({
          key: "status",
          value: this.status,
          label: `สถานะใบงาน: ${statusLabel}`,
        });
      }
      if (this.search_origin) {
        this.car_filter_list.push({
          key: "search_origin",
          value: this.search_origin,
          label: `ต้นทาง: ${this.search_origin}`,
        });
      }
      if (this.search_destination) {
        this.car_filter_list.push({
          key: "search_destination",
          value: this.search_destination,
          label: `ปลายทาง: ${this.search_destination}`,
        });
      }
      if (this.search_client_company) {
        const clientCompany = this.client_company_list.find(
          (company) => company.id == this.search_client_company
        )?.fullname;
        this.car_filter_list.push({
          key: "search_client_company",
          value: this.search_client_company,
          label: `ลูกค้า/บริษัท: ${clientCompany}`,
        });
      }
      if (this.search_car_company) {
        const carCompany = this.car_company_list.find(
          (company) => company.id == this.search_car_company
        )?.fullname;
        this.car_filter_list.push({
          key: "search_car_company",
          value: this.search_car_company,
          label: `บริษัทรถ: ${carCompany}`,
        });
      }
      if (this.search_operation) {
        const operation = this.operation_list.find(
          (op) => op.id == this.search_operation
        )?.fullname;
        this.car_filter_list.push({
          key: "search_operation",
          value: this.search_operation,
          label: `ผู้ดูแลใบงานฝ่ายปฏิบัติการ: ${operation}`,
        });
      }

      return this.car_filter_list.length
        ? this.car_filter_list.map(({ label }) => label).join(", ")
        : null;
    },
    filteredBookingStatus() {
      const allowedStatuses = [null, 2, 8, 9, 16, 10, 17, 19, 18, 5, 6, 14, 15];
      const filteredStatuses = this.bookingStatus.filter(status => allowedStatuses.includes(status.status));
      if (this.selected_booking && !allowedStatuses.includes(this.selected_booking.status)) {
        this.selected_booking.status = null;
      }
      return filteredStatuses;
    },
    sortedBookingStatusList() { 
      const status = this.booking_status_list;
      this.tmDepartment = JSON.parse(localStorage.getItem('user_profile')).department;
      let statusFilter;

      if (this.userBusinessId == 2) {
        statusFilter = this.tabsProduct.filter(tab => {
          const excludeStatuses = [null, 2,0, 1, 3, 7, 12, 13]; 
          return !excludeStatuses.includes(tab.status) || 
            (this.selected_booking && tab.status === this.selected_booking.status);
        });
        const customOrder = [11,8,  9, 16, 10, 17, 19, 18, 5, 6, 14, 15];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      } else {
        statusFilter = this.tabsCustomer;
        statusFilter = this.tabsCustomer.filter(tab => 
            ![4, 7, 12, 13].includes(tab.status)
          );

          const customOrder = [null, 0,1,2,11,8,9,10,3,5,6,14, 15];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      }

      let statusList = statusFilter.map(tab => {
        const statusItem = status.find(s => s.status == tab.status) || { status: null, name: 'ทั้งหมด' };
        if (statusItem) {
          tab.name = statusItem.name;
        }
        return tab;
      });

      if (this.tmDepartment != null) {
        statusList = statusList.filter(tab => tab.roles.includes(this.tmDepartment));
      } else {
        statusList = statusList.filter(tab => tab.roles.includes('others'));
      }
      return statusList;
    },
    sortedBookingStatusLista() { 
      const status = this.booking_status_list;
      this.tmDepartment = JSON.parse(localStorage.getItem('user_profile')).department;
      let statusFilter;

        statusFilter = this.tabsCustomer;
        statusFilter = this.tabsCustomer.filter(tab => 
            ![4, 7, 12, 13].includes(tab.status)
          );

          const customOrder = [null, 0,1,2,11,8,9,10,3,5,6,14, 15];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));

      let statusList = statusFilter.map(tab => {
        const statusItem = status.find(s => s.status == tab.status) || { status: null, name: 'ทั้งหมด' };
        if (statusItem) {
          tab.name = statusItem.name;
        }
        return tab;
      });

      if (this.tmDepartment != null) {
        statusList = statusList.filter(tab => tab.roles.includes(this.tmDepartment));
      } else {
        statusList = statusList.filter(tab => tab.roles.includes('others'));
      }
      return statusList;
    },
    licensePlateRules() {
      if (this.selectedTypeReport === null||this.selectedTypeReport == false) {
        return [{ required: true, message: 'โปรดเลือกทะเบียนรถ' }]; // Apply rule if 'SelectedCar'
      }
      return []; // No rules otherwise
    },
    CompanyClientRules() {
      if (this.selectedTypeReportClient === null||this.selectedTypeReportClient == false) {
        return [{ required: true, message: 'โปรดเลือกลูกค้า/บริษัท' }]; // Apply rule if 'SelectedCar'
      }
      return []; // No rules otherwise
    },
  },
  methods: {
    render_des(record){
      if (record.data_text_destination && record.data_text_destination !== "null") {
        const response = JSON.parse(record.data_text_destination);
        return response.join(',')
      }
    },
    async update_progress() {
      if (this.activeKey != 2 && this.activeKey != null) {
        await SystemService.update_all("BookingCar", { data: { status: this.selected_booking.booking_cars[0].status } }, this.selected_booking.booking_cars[0].id).then(async (res) => {
          if (res) {
            await SystemService.update_all("Booking", { data: { status: this.selected_booking.booking_cars[0].status } }, this.selected_booking.id);

            this.update_progress_modal = false;
            this.init_data();
            Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
          }
        });
      } else {
        await SystemService.update_all("Booking", { data: { status: this.selected_booking.status } }, this.selected_booking.id).then(async (res) => {
          if (res) {
            const bookingCarId = this.selected_booking.booking_cars[0].id;
            await SystemService.update_all("BookingCar", { data: { status: this.selected_booking.status } }, bookingCarId);

            this.update_progress_modal = false;
            this.init_data();
            Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
          }
        });
      }
    },
    statusCar(record){
      if (record.booking_cars[0].status == 15) {
        if (record.booking_cars[0].status == 15) {
          return { txt: "รอตรวจสอบ", color: "orange" };
        }
        if (record.booking_cars[0].status == 20) {
          return { txt: "ตรวจสอบแล้ว", color: "orange" };
        }
      }
      if (record.booking_cars[0].status == null) {
        return { txt: "ทั้งหมด" };
      }
      if (record.booking_cars[0].status == 0) {
        return { txt: "แนะนำการจัดรถ", color: "blue" };
      }
      if (record.booking_cars[0].status == 1) {
        return { txt: "ทั้งหมด"};
      }
      if (record.booking_cars[0].status == 2) {
        return { txt: "จัดรถแล้ว", color: "green" };
      }
      if (record.booking_cars[0].status == 3) {
        return { txt: "ถึงจุดรับรถแล้ว", color: "green" };
      }
      if (record.booking_cars[0].status == 5) {
        return { txt: "จบงาน", color: "green" };
      }
      if (record.booking_cars[0].status == 6) {
        return { txt: "ยกเลิกการเดินทาง", color: "red" };
      }
      if (record.booking_cars[0].status == 7) {
        return { txt: "กำลังเดินทาง", color: "blue" };
      }
      if (record.booking_cars[0].status == 8) {
        return { txt: "รับงาน", color: "green" };
      }
      if (record.booking_cars[0].status == 9) {
        return { txt: "รถออกจากลานจอด", color: "green" };
      }
      if (record.booking_cars[0].status == 10) {
        return { txt: "กำลังขึ้นของ", color: "orange" };
      }
      if (record.booking_cars[0].status == 11) {
        return { txt: "แจ้งงาน", color: "orange" };
      }
      if (record.booking_cars[0].status == 12) {
        return { txt: "รับของ", color: "blue" };
      }
      if (record.booking_cars[0].status == 13) {
        return { txt: "รับของเสร็จสิ้น", color: "orange" };
      }
      if (record.booking_cars[0].status == 14) {
        return { txt: "ปฏิเสธงาน", color: "red" };
      }
      if (record.booking_cars[0].status == 15) {
        return { txt: "ตรวจสอบ", color: "orange" };
      }
      if (record.booking_cars[0].status == 16) {
        return { txt: "รถถึงต้นทาง", color: "purple" };
      }
      if (record.booking_cars[0].status == 17) {
        return { txt: "อยู่ระหว่างขนส่ง", color: "pink" };
      }
      if (record.booking_cars[0].status == 18) {
        return { txt: "กำลังลงของ", color: "yellow" };
      }
      if (record.booking_cars[0].status == 19) {
        return { txt: "รถถึงปลายทาง", color: "blue" };
      }
      if (record.booking_cars[0].status == 20) {
        return { txt: "ตรวจสอบแล้ว", color: "green" };
      }
    },
    statusText(record) {
      if (record.company_3rd != 'บริษัทรถร่วม' && record.booking_cars[0].driver_id == null && record.booking_cars[0].car_id == null) {
          return { txt: "ใบงานว่าง", color: "yellow" };
      }
      if (record.company_3rd == 'บริษัทรถร่วม' && record.booking_cars[0].driver_id == null && record.booking_cars[0].car_id == null) {
        return { txt: "รอรายละเอียด", color: "orange" };
      } 
      if (record.booking_cars[0].driver_id != null && record.booking_cars[0].car_id != null) {
        return { txt: "มอบหมายงานแล้ว", color: "green" };
      }
      if (record.booking_cars[0].driver_id == null || record.booking_cars[0].car_id == null) {
        return { txt: "รอรายละเอียด", color: "orange" };
      } 
      // else {
      //   return { txt: "ใบงานว่าง", color: "yellow"};
      // }
    },
    handleStatusChange(value) {
      this.status = value;
      // this.init_data();
    },
    getDriverName(driver_id) {
      if (!driver_id) return '';
      const driver = this.driver_name.find(driver => driver.id === driver_id);
      return driver ? `${driver.firstname} ${driver.lastname}` : '';
    },
    render_date(datetime) {
      return dayjs(datetime).format('YYYY/MM/DD HH:mm');
    },
    countDays(time_start, time_end) {
      const start = dayjs(time_start);
      const end = dayjs(time_end);
      return end.diff(start, 'day');
    },
    filtered_list(array) {
      const currentDate = dayjs();
      return array.filter(({ booking_no, status, time_start, time_end, origin, data_text_destination, client_id, operation_id, sheet_status, car_company_id, booking_cars, company_3rd, time_product }) => {
        // console.log('111',time_start)
        // console.log('222',time_product)
        const searchInputLower = this.search_input ? this.search_input.toLowerCase() : "";
        const searchInput = !this.search_input || (booking_no && booking_no.toLowerCase().includes(searchInputLower));
        const searchDate = !this.datetime || 
          (time_start && dayjs(time_start).startOf('day').isBetween(dayjs(this.datetime[0]).startOf('day'), dayjs(this.datetime[1]).endOf('day'), null, '[]')) &&
          (time_end && dayjs(time_end).startOf('day').isBetween(dayjs(this.datetime[0]).startOf('day'), dayjs(this.datetime[1]).endOf('day'), null, '[]'));
        const effectiveStatus = [5, 8, 9, 10, 16, 17, 18, 19].includes(booking_cars[0].status) ? 8 : booking_cars[0].status;
        const searchStatus = this.activeKey === null || effectiveStatus === this.activeKey || ([11, 8, 6, 14, 15].includes(this.activeKey) && booking_cars[0].status === this.activeKey) || (this.activeKey === 15 && [15, 20].includes(booking_cars[0].status));
        const filterStatus = this.status === null || 
          (this.status === 1 && company_3rd != 'บริษัทรถร่วม' && booking_cars[0].driver_id == null && booking_cars[0].car_id == null) ||
          (this.status === 2 && (
            (company_3rd == 'บริษัทรถร่วม' && booking_cars[0].driver_id == null && booking_cars[0].car_id == null) || 
            (booking_cars[0].driver_id != null && booking_cars[0].car_id == null) || 
            (booking_cars[0].driver_id == null && booking_cars[0].car_id != null)
          )) ||
          (this.status === 3 && booking_cars[0].driver_id != null && booking_cars[0].car_id != null);
        const searchOrigin = !this.search_origin || (origin && origin.toLowerCase().includes(this.search_origin.toLowerCase()));
        const searchDestination = !this.search_destination || (data_text_destination && data_text_destination.toLowerCase().includes(this.search_destination.toLowerCase()));
        const searchClientCompany = !this.search_client_company || (client_id && client_id === this.search_client_company);
        const searchClient = !this.search_car_company || (car_company_id && car_company_id === this.search_car_company);
        const searchOperation = !this.search_operation || (operation_id && operation_id === this.search_operation);

        return searchInput && searchDate && searchStatus && filterStatus && searchOrigin && searchDestination && searchClientCompany && searchClient && searchOperation;
        }).sort((a, b) => {
          const diffA = Math.abs(this.formatDatetime(a.time_start, a.time_product).diff(this.formatDate(currentDate)));
          const diffB = Math.abs(this.formatDatetime(b.time_start, b.time_product).diff(this.formatDate(currentDate)));
          console.log('vvv',this.formatDate(currentDate))
          console.log('ccc',diffA)
          console.log('hhh', this.formatDatetime(a.time_start, a.time_product));
          
          if (diffA === diffB) {
            const statusPriority = {
              'ใบงานว่าง': 1,
              'รอรายละเอียด': 2,
              'มอบหมายงานแล้ว': 3
            };
            const statusA = this.statusText(a).txt;
            const statusB = this.statusText(b).txt;
            
            return statusPriority[statusA] - statusPriority[statusB];
          }
          return (diffA - diffB)
        });
    },
    async go_to_edit_quotation(id) {
      this.$router.push(`/app/sheet/${id}?manage_worksheet=true`);
    },
    connectSocket(){
        this.socketService.connect();
        this.socketService.joinRoom('booking');
        this.socketService.onBroadcast((message) => {
            if (message) {
              this.init_bookings_update(this.activeKey);
              this.fetchBookingStatus();
            }
        },'booking');
    },
    async getCompanyId() {
      let data = await UserService.get_user_profile();
      return data.company_id;
    },
    get_company_id() {
      if(this.userBusinessId == 2){
        return true
      }else{
        return this.userBusinessId
      }
      return null
    },
    handleIconClick(action, payload, event) {
        event.stopPropagation();
        if (action === 'preview') {
            this.showPreview(event , payload);
        } else if (action === 'delete') {
            this.fileList = [];
        }
    },
    openBookingUpdatePopup(record) {
      this.BookingUpdatedVisible = true;
      this.bookingUpdatedData = record.booking_updated;
    },
    closeBookingUpdatePopup() {
      this.BookingUpdatedVisible = false;
    },
    handlePlateNoChange(value) {
      this.search_plate_no = value;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.onpage = pagination.current;
      this.pagination.perPage = pagination.pageSize;

      this.init_data();
    },
    render_date(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm");
    },
    render_datetime(date,time) {
      const datetime = `${date.slice(0,10)} ${time.slice(11,16)}`
      return dayjs(datetime).format("DD/MM/YYYY HH:mm");
    },
    formatDatetime(date, time) {
      const datetime = `${date.slice(0, 10)} ${time.slice(11, 16)}`;
      return dayjs(datetime);
    },
    formatDate(date) {
      return dayjs(date);
    },
    async remove_booking(id) {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "คุณต้องการลบงานนี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SystemService.update_all("Booking", { data: { progress: 0, progress_1: 0 } }, id);
          this.init_data();
          Swal.fire("ลบเรียบร้อย", "ลบงานเรียบร้อย", "success");
        }
      });
    },
    async activate_job(record) {  
      const { id } = record;
      const bookingCarId = record.booking_cars[0].id;
      const hasNoDriverId = record.booking_cars.some(car => !car.driver_id);
      if (hasNoDriverId) {
          this.showModalActiveJob = true;
          return;
      }
      await SystemService.update_all("Booking", { data: { active_job: 1, status: 11 } }, id).then(async (res) => {
        await SystemService.update_all("BookingCar", { data: { active_car: 1, status: 11 } }, bookingCarId);
        this.init_data();
        this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
      })
    },
    show_update_progress(id) {
      this.update_progress_modal = true;
      this.selected_booking = this.sheets_data.find((booking) => booking.id === id);
      // console.log('Booking Status:', this.bookingStatus);
      // console.log('Selected Booking Status:', this.selected_booking.status);

      const matchingStatus = this.bookingStatus.find(status => status.status === this.selected_booking.status);
      // console.log('Matching Status:', matchingStatus);
    },
    count_by_status(status) {
      if (!this.sheets_data) return 0;
      if (status === null) {
        return this.sheets_data.length;
      }
      if (status === 8) {
        return this.sheets_data.filter(item => item.booking_cars.some(car => [5, 8, 9, 10, 16, 17, 18, 19].includes(car.status))).length;
      }
      if (status === 15) {
        return this.sheets_data.filter(item => item.booking_cars.some(car => [15, 20].includes(car.status))).length;
      }
      if ([11, 6, 14].includes(status)) {
        return this.sheets_data.filter(item => item.booking_cars.some(car => car.status === status)).length;
      }
      return this.sheets_data.filter(item => item.status === status).length;
    },
    clear_search() {
      this.activeKey = null;
      this.search_input = null;
      this.datetime = null;
      this.search_booking_status = null;
      this.status = null;
      this.search_origin = null;
      this.search_destination = null;
      this.search_client_company = null;
      this.search_car_company = null;
      this.search_operation = null;
      this.init_data();
    },
    select_row(selectedRowKeys) {
      this.selected_booking = selectedRowKeys;
    },
    select_filter(key, status) {
      console.log({ key, status });
    },
    render_time(time) {
      return dayjs(time).format("DD/MM/YYYY HH:mm");
    },
    async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2)
            {
                this.business = true
            }
        },
    async fetchBookingStatus() {
          try {
              const company = await UserService.get_user_profile();
              const bookingStatus = await SystemService.bookingStatus(company.company_id); 
              this.bookingStatusData = bookingStatus; // เก็บใน bookingData
          } catch (error) {
              console.error('Error fetching booking status:', error.message);
          }
      },
      // async init_data(status = null) {
      //   this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      //   let datas = await SheetService.get_work_sheets();
        
      //   if (status !== null) {
      //     datas = datas.filter(item => item.status === status);
      //   }

      //   this.sheets_data = datas;
      //   this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
      // },
      async init_data() {
        this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
        let datas = await SheetService.get_sheets_progress_one();
        this.sheets_data = datas;
        this.original_sheets_data = datas;
        this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        const [client_company_list] = await Promise.all([SystemService.get_all("Client")]);
        this.client_company_list = client_company_list
        this.operation_list = await OperationService.getOperationByCompany()
        // console.log('788',this.operation_list)
      }
  },
  watch: {
    activeKey(newVal) {
      this.init_data();
    },
    require_rollcall(newVal) {
      if (newVal != null) {
        const { company_id } = JSON.parse(localStorage.getItem("user_profile"));
        SystemService.update_all(
          "Company",
          { data: { require_rollcall: newVal } },
          company_id
        ).then((res) => {
          const original = JSON.parse(localStorage.getItem("companyData"));
          original.require_rollcall = newVal;
          localStorage.setItem("companyData", JSON.stringify(original));
        });
      }
    },
  },
  unmounted() {
      // this.leaveRoom('booking');
  },
  async mounted() {
    this.init_data();
    this.connectSocket();
    this.companyId = await this.getCompanyId();
    // this.filteredTabs = await this.getFilteredTabs();
    let data = await UserService.get_user_profile();
    let userCompanyId = data.company_id;
    let bid = await CompanyService.getBusinessId(userCompanyId);
    this.userBusinessId = bid.data
    // this.filterTabs();
    this.filteredTabs;
    this.pay_method_list = await SystemService.get_all("PayMethod");
    this.car_list = await SystemService.get_all("Car");
    this.company_list = await SystemService.get_all("Client");
    this.booking_status_list = await SystemService.get_all("BookingStatusText");
    this.driver_name = await SystemService.get_all("Driver");
    this.car_company_list = await SystemService.get_all("CarCompany");
    this.get_company();
    await this.fetchBookingStatus();
  },
};
</script>
<style>
  .ant-table-thead .center-header {
    text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
  }
</style>