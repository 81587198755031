<template>
  <div class="no-scroll">
    <div class="app-container">

      <a-layout>
        <a-layout-sider v-if="show_panels" :trigger="null" collapsible v-model:collapsed="collapsed">
          <SideNav
            @register_toggleCollapsed="register_toggleCollapsed"
          />
        </a-layout-sider>
        <a-layout>
          <div class="main-container no-scroll" style="width: 100%">
            <div class="no-scroll" style="display: flex;flex-direction: column;overflow-y: scroll;">
              <nav v-if="show_panels" class="app-nav dark-background no-scroll">
                <div style="color: white;font-size: 16px;margin: 10px">
                    <MenuUnfoldOutlined @click="collapsed = !collapsed" v-if="collapsed" />
                    <MenuFoldOutlined @click="collapsed = !collapsed" v-else />
                </div>
                <div class="nav-container">
                  <div ref="containerNotification" class="containerNotification" style="display: flex; justify-content: flex-end; align-items: center; position: relative;">
                      <a-dropdown 
                        :trigger="['click']" 
                        placement="bottomRight" 
                        v-model:open="isActive"
                        :overlayStyle="{ width: '350px', textAlign: 'right' }"
                      >
                        <div 
                          ref="notificationContainer"
                          :class="['notification', { active: isActive }]"
                          :style="notificationStyle"
                           @click="toggleNotification"
                        >
                        <Badge :count="badgeCountNoti" overflowCount="10" class="custom-badge">
                          <img :src="notificationImage()" alt="notification" width="15">
                        </Badge>
                        </div>
                        <template #overlay>
                          <a-menu
                          :style="{
                            width: '370px',
                            maxHeight: switchAll ? '350px' : '550px', /* Change max-height when switchAll is false */
                            overflowY: switchAll ? 'scroll' : 'hidden',
                            borderRadius: '20px'
                          }"
                          >
                          <!-- Support Color -->
                          <div v-if="isVisible" :style="{ backgroundColor: bgColor }">
                            <!-- Support color -->
                          </div>
                          <!-- <button @click="toggleVisibility">แสดง/ซ่อน</button> -->
                           <!-- Notification View -->
                            <div style="display: flex; flex-direction: column; padding: 8px;">
                              <div style="display: flex; gap: 8px; align-items: center; width: 100%; justify-content: center;">
                                <span style="font-size: 18px; text-align: left; font-weight: bold; width: 100%;">การแจ้งเตือน</span>
                              </div>
                              <div style="display: flex; gap: 8px; margin-top: 5px; margin-left: 8px;">
                                <div class="buttonChoose2" :style="buttonStyle2"  @click="toggleUnReadNotification">
                                  <span style="font-size: 15px">ที่ยังไม่ได้อ่าน</span></div>
                                <div class="buttonChoose" :style="buttonStyle1"  @click="toggleAllNotification">
                                  <span style="font-size: 15px"> ทั้งหมด </span></div>
                                <div>
                                  <a-tooltip>
                                    <template #title>ยกเลิก</template>
                                    <a style="display: flex; justify-content: center; align-items: center; transform: translateY(2px);"
                                    @click="checkbox_notification()"
                                      ><span :style="{ fontSize: '24px', color: this.showCheckbox ? '#1D39C4' : 'black' }" class="material-symbols-outlined"
                                        >delete</span
                                      ></a
                                    >
                                  </a-tooltip>
                                </div>
                              </div>
                            </div>
                              <div v-if="loadingNotification" style="padding: 10px; text-align: center; width: 100%; ">
                                <a-spin  tip="กำลังโหลดข้อมูล..."  />
                              </div>
                              <a-menu-item
                                v-if="!loadingNotification" 
                                v-for="(item, index) in (switchAll ? filterType(combinedData) : filterType(combinedData).slice(0, 4))" 
                                @click.stop
                                :key="index" 
                                class="listNoti"
                                :style="{ backgroundColor: checkId.includes(index)? '#F5F5F5' : item.is_read == null || item.is_read == 2 ? '#E9F6FE' : ''}"
                              >
                                <div v-if="item?.modelname == 'ClaimExpense'" @click.stop>
                                  <div style="display: flex; flex-direction: row;">
                                    <div style="flex: 0; display: flex; justify-content: center; align-items: center; margin-left: 5px">
                                      <input v-if="this.showCheckbox" type="checkbox" v-model="checkId" :value="index" />  <!-- Bind to item.selected -->
                                    </div>
                                    <div @click="handleClaim(item.id)">
                                      <div style="display: flex; justify-content: space-between; width: 310px;">
                                        <span style="font-size: 13px; font-weight: bold; margin-left: 8px;">แจ้งขออนุมัติเบิกเงินฉุกเฉิน</span>
                                        <span style="font-size: 12px; color: gray;">{{ dateTimeFormat(item.created_at) }}</span>
                                      </div>
                                      <div style="display: flex; flex-direction: row; width: 310px; align-items: center;">
                                        <div style="flex: 1; display: flex; flex-direction: column; font-size: 12px; line-height: 1.5; text-align: left;">
                                          <span style="display: flex; margin-left: 8px;">มีคำขออนุมัติเบิกเงินฉุกเฉิน</span>
                                          <span style="margin-left: 8px;">จาก พนักงานขับรถ <span style="color: #2D52B2; font-weight: bold;">{{ item?.driver.firstname }} {{ item?.driver.lastname }}</span></span>
                                          <span style="display: flex; margin-left: 8px;">จำนวนขอเบิก :{{ item.amount }} เมื่อวันที่ {{ new Date(item.created_at).toLocaleDateString('en-GB') }} </span>
                                        </div>
                                        <div style="flex: 0; display: flex; justify-content: right; align-items: right;">
                                          <button class="detailCheck"> <a @click.stop="handleClaim(item.id)" style="color: black;">ดูรายละเอียด</a></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="item?.isType" @click.stop>
                                  <div style="display: flex; flex-direction: row;" >
                                    <div style="flex: 0; display: flex; justify-content: center; align-items: center;margin-left: 5px">
                                      <input v-if="this.showCheckbox" type="checkbox" v-model="checkId" :value="index" />  <!-- Bind to item.selected -->
                                    </div>
                                    <div @click="handleCar(item.id,item.isType)">
                                      <div style="display: flex; justify-content: space-between; width: 310px;">
                                        <span v-if="item?.isType != 'maintenance'" style="font-size: 13px; font-weight: bold; margin-left: 8px;">แจ้งเตือนวันหมดอายุ {{ item?.textDescription }} </span>
                                        <span v-else style="font-size: 13px; font-weight: bold; margin-left: 8px;">แจ้งเตือนวันตรวจสภาพรถประจำปี </span>
                                        <span style="font-size: 12px; color: gray;">{{ dateTimeFormat(item.updated_date?item.updated_date:item.created_at) }}</span>
                                      </div>
                                      <div style="display: flex; flex-direction: row; width: 310px; align-items: center;">
                                        <div style="width: 240px; display: flex; flex-direction: column; font-size: 12px; line-height: 1.5; text-align: left;overflow: hidden; white-space: normal;word-wrap: break-word;">
                                          <span v-if="item?.isType != 'maintenance'" style="margin-left: 8px;">กรมธรรม์ประกันภัย {{item.textDescription}} [{{ item?.noNumber }}] </span>
                                          <span v-if="item?.isType != 'maintenance'" style="margin-left: 8px;">รถทะเบียน:{{ item.plate_no }}  เบอร์รถ:{{ item.carNo }}</span>
                                          <span v-else style="margin-left: 8px;">ตรวจสภาพรถประจำปีรถ ทะเบียน:{{ item.plate_no }}</span>
                                          <span v-if="item?.isType != 'maintenance'" style="margin-left: 8px;">จะหมดอายุในวันที่ {{ new Date(item.expireDate).toLocaleDateString('en-GB') }}  </span>
                                          <span v-else style="margin-left: 8px;">เบอร์รถ:{{ item.carNo }} จะครบกำหนดในวันที่ {{ new Date(item.expireDate).toLocaleDateString('en-GB') }}</span>
                                        </div>
                                        <div style="width: 70px; display: flex; justify-content: flex-end; align-items: flex-end;">
                                          <button class="detailCheck"><a @click.stop="handleCar(item.id,item.isType)" style="color: black;">ดูรายละเอียด</a></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="item?.modelname == 'LeaveAdmin'" @click.stop>
                                  <div style="display: flex; flex-direction: row;">
                                    <div style="flex: 0; display: flex; justify-content: center; align-items: center;margin-left: 5px">
                                      <input v-if="this.showCheckbox" type="checkbox" v-model="checkId" :value="index" />  <!-- Bind to item.selected -->
                                    </div>
                                    <div @click="handleLeaveAdmin(item.id)">
                                      <div style="display: flex; justify-content: space-between; width: 310px;">
                                        <span style="font-size: 13px; font-weight: bold; margin-left: 8px; overflow: hidden; white-space: normal;word-wrap: break-word;">ผลคำขอใช้โควตาการ{{ leaveTypeList.find((type)=>type.id == item.leave_type_id).name }} {{ formatToThaiMonth(item?.updated_date) }}</span>
                                        <span style="font-size: 12px; color: gray;">{{ dateTimeFormat(item?.updated_date) }}</span>
                                      </div>
                                      <div style="display: flex; flex-direction: row; width: 310px; align-items: center;">
                                        <div style="flex: 1; display: flex; flex-direction: column; font-size: 12px; line-height: 1.5; text-align: left;">
                                          <span style="margin-left: 8px;">มีคำขอลางาน ประเภท: {{ leaveTypeList.find((type)=>type.id == item.leave_type_id).name }} </span>
                                          <span style="margin-left: 8px;">วันที่ {{ new Date(item.time_start).toLocaleDateString('en-GB') }} - {{ new Date(item.time_end).toLocaleDateString('en-GB') }}</span>
                                          <span style="margin-left: 8px;">{{ item.approve_status == 1? 'ได้รับการอนุมัติแล้ว' : 'ไม่ได้รับการอนุมัติ' }}</span>
                                        </div>
                                        <div style="flex: 0; display: flex; justify-content: flex-end; align-items: flex-end;">
                                          <button class="detailCheck"><a @click.stop="handleLeaveAdmin(item.id)" style="color: black;">ดูรายละเอียด</a></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else @click.stop>
                                  <div style="display: flex; flex-direction: row;">
                                    <div style="flex: 0; display: flex; justify-content: center; align-items: center;margin-left: 5px">
                                      <input v-if="this.showCheckbox" type="checkbox" v-model="checkId" :value="index" />  <!-- Bind to item.selected -->
                                    </div>
                                    <div @click="item?.modelname != 'LeaveUser' ? handleLeave(item.id): handleLeaveUser(item.id)">
                                      <div v-if="item?.modelname != 'LeaveUser'" style="display: flex; justify-content: space-between; width: 310px;">
                                        <span style="font-size: 13px; font-weight: bold; margin-left: 8px;">แจ้งขอใช้โควตาการ{{ item?.work_status.name }}</span>
                                        <span style="font-size: 12px; color: gray;">{{ dateTimeFormat(item.created_at) }}</span>
                                      </div>
                                      <div v-else style="display: flex; justify-content: space-between; width: 310px;">
                                        <span style="font-size: 13px; font-weight: bold; margin-left: 8px;">แจ้งขอใช้โควตาการ{{ leaveTypeList.find((type)=>type.id == item.leave_type_id).name }}</span>
                                        <span style="font-size: 12px; color: gray;">{{ dateTimeFormat(item.created_at) }}</span>
                                      </div>
                                      <div style="display: flex; flex-direction: row; width: 310px; align-items: center;">
                                        <div style="flex: 1; display: flex; flex-direction: column; font-size: 12px; line-height: 1.5; text-align: left;">
                                          <span v-if="item?.modelname != 'LeaveUser'" style="margin-left: 8px;">มีคำขอลางาน ประเภท: {{ item?.work_status.name }} </span>
                                          <span v-else style="margin-left: 8px;">มีคำขอลางาน ประเภท: {{ leaveTypeList.find((type)=>type.id == item.leave_type_id).name }} </span>
                                          <span v-if="item?.modelname != 'LeaveUser'" style="margin-left: 8px;">จาก พนักงานขับรถ <span style="color: #2D52B2; font-weight: bold;">{{ item?.driver.firstname }} {{ item?.driver.lastname }}</span></span>
                                          <span v-else style="margin-left: 8px; overflow: hidden; white-space: normal;word-wrap: break-word;">
                                            จาก พนักงาน <span style="color: #2D52B2; font-weight: bold;">{{ item?.user_name }}</span>
                                          </span>
                                          <span style="margin-left: 8px;">วันที่ {{ new Date(item.time_start).toLocaleDateString('en-GB') }} - {{ new Date(item.time_end).toLocaleDateString('en-GB') }}</span>
                                        </div>
                                        <div style="flex: 0; display: flex; justify-content: flex-end; align-items: flex-end;">
                                          <button class="detailCheck" v-if="item?.modelname != 'LeaveUser'"><a @click.stop="handleLeave(item.id)" style="color: black;">ดูรายละเอียด</a></button>
                                          <button class="detailCheck" v-else><a @click.stop="handleLeaveUser(item.id)" style="color: black;">ดูรายละเอียด</a></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a-menu-item>
                              <div class="seeAll" 
                                v-if="switchAll==false"
                                style="text-align: center; 
                                       font-size: 14px; 
                                       padding: 5px;
                                       cursor: pointer; 
                                       background-color: #F9F9F9;"
                                @click="switchAll = !switchAll"
                              >         
                                ดูทั้งหมด
                              </div>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </div>
                  <div class="profile">
                    <a-dropdown :trigger="['click']">
                      <a class="ant-dropdown-link" @click.prevent>
                        <img style="height: 24px;width: 24px;border-radius: 300%;background-color: white;" :src="company_data.logo? company_data.logo:default_vars.avatar" />
                        <span style="color: white;font-size: 14px;margin-left: 8px">{{company_data[role]?.username}}</span>
                      </a>
                      
                      <template #overlay>
                      <a-menu>
                        <a-menu-item key="0" class="menu-item-hover" >
                          <span class="material-symbols-outlined" style="font-size: 22px;margin-right: 4px">Settings</span>
                          <a :href="car_company_list?.[0]?.id ? `/app/CarCompany/${car_company_list[0].id}` : '#'">Settings</a>
                        </a-menu-item>
                        <a-menu-item key="0" class="menu-item-hover" >
                          <span class="material-symbols-outlined" style="font-size: 22px;margin-right: 4px">outgoing_mail</span>
                          <a @click="leaveUser()">การแจ้งลา</a>
                        </a-menu-item>
                        
                        <!-- <a-menu-item key="1">
                          <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Lock</span>
                          <a @click="toChangePassword()">เปลี่ยนรหัสผ่าน</a>
                        </a-menu-item> -->
                        <a-menu-divider />

                        <a-menu-item key="2"  class="menu-item-hover">
                          <span class="material-symbols-outlined" style="font-size: 20px;margin-right: 4px">power_rounded</span>
                          <a @click="logout()" >ออกจากระบบ</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                    </a-dropdown>
                  </div>
                </div>
              </nav>
              <router-view 
                class="no-scroll"
                style="background-color: #f5f5f5;overflow-y: scroll;"
                :user_name="company_data[role]?.username"
                :permission="user_profile?.permission"
                :model_data="model_data"
                @register_value="register_company"
                @apply_model_data="apply_model_data"
                @request_fullscreen="apply_fullscreen"
              />
            </div>

            <!-- <div v-if="show_panels" class="no-srcoll" style="display: flex;width: 100%;position: relative">
              <footer>© 2022 VECABO MANAGEMENT</footer>
            </div> -->
          </div>
        </a-layout>
      </a-layout>

    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SystemService from './api/SystemService';
import SideNav from './components/side_nav.vue'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons-vue';
import VecaboService from './api/VecaboService';
import AdminService from './api/AdminService';
import ClaimExpenseService from './api/ClaimExpenseService';
import LeaveService from './api/LeaveService';
import { Badge, notification } from 'ant-design-vue';
import NotHaveNoti from "@/assets/icons/dashboard/not-have-noti.png";
import HaveNoti from "@/assets/icons/dashboard/have-noti.png";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import CarService from './api/CarService';
import Leave_admin from './views/app_view/leave_admin.vue';
import LeaveUserService from './api/LeaveUserService';
import LeaveAdminService from './api/LeaveAdminService';
import UserService from './api/UserService';

export default {
  components: { SideNav, MenuUnfoldOutlined, MenuFoldOutlined,Badge },
  name: 'App',
  computed: {
    show_panels() {
      return this.is_logged_in && !this.fullscreen
    },
    notificationStyle() {
      return {
        backgroundColor: this.isActive ? '#5D6DB5' : '', 
        borderRadius: '7px',
        padding: '5px 5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        display: 'flex',
        alignItems: 'center',
        width:'25px',
      };
    },
    buttonStyle1() {
      if (!this.activeField) {
        const hasUnread = this.filterType(this.combinedData).some(item => item.is_read === null || item.is_read === 2);
        this.activeField = hasUnread ? "All" : "";
      }
      const blue = this.activeField === "All"? "rgba(29, 57, 196, 0.3)":'';
      const borderBlue = this.activeField === "All" ? "#1D39C4" : "#80808099";
      return {
          borderRadius: "25px",
          border: `solid 1px ${borderBlue}`,
          paddingTop: "1px",
          paddingBottom: "1px",
          paddingLeft: "10px",
          paddingRight: "10px",
          cursor: "pointer",
          fontSize: "13px",
          backgroundColor:blue,
          color:"black",
        };
    },
    buttonStyle2() {
      const blue = this.activeField === "Unread" ? "rgba(29, 57, 196, 0.3)" : "";
      const borderBlue = this.activeField === "Unread" ? "#1D39C4" : "#80808099";
      return {
        borderRadius: "25px",
        border: `solid 1px ${borderBlue}`,
        paddingTop: "1px",
        paddingBottom: "1px",
        paddingLeft: "10px",
        paddingRight: "10px",
        cursor: "pointer",
        fontSize: "13px",
        backgroundColor:blue,
        color:"black",
      };
    },
  },
  data () {
    return {
      is_logged_in: localStorage.getItem('token'),
      company_data: localStorage.getItem('companyData')? JSON.parse(localStorage.getItem('companyData')):{},
      user_profile: localStorage.getItem('user_profile')? JSON.parse(localStorage.getItem('user_profile')):{},
      role: localStorage.getItem('role'),
      default_vars: {
        avatar: require('@/assets/images/fleetex.png')
      },
      toggleCollapsed: null,
      collapsed: false,
      fullscreen: false,
      showCheckbox: false,
      activeField:"",
      bgColor: "blue",
      isVisible: true,
      /* Tray */
      model_data: {},
      isActive:false,
      company_id : null,
      leavesData : [],
      leavesUserData : [],
      claimData : [],
      leaveTypeList: [],
      getCarNoti : [],
      leaveApproveList: [],
      combinedData : [],
      checkId : [],
      typeNotification : "All",
      loadingNotification : false,
      switchAll : false,
      badgeCountNoti : 0,
    }
  },
  methods: {
    async init_company_default() {
            this.car_company_list = await SystemService.get_all('CarCompany')
        },
    register_toggleCollapsed(e) {
      this.toggleCollapsed = e
    },
    formatToThaiMonth(date) {
      const formatDate = new Date(date);
      const thaiMonths = [
        "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
        "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
      ];

      const monthIndex = formatDate.getMonth(); // Get the month (0-11)
      return 'เดือน ' + thaiMonths[monthIndex];
    },
    async toggleNotification() {
      this.switchAll = false;
      if(this.isActive == false){
        await this.initNotification();
        //await ClaimExpenseService.isReadClaimExpense();
        //await LeaveService.isReadLeaves();
        //await CarService.isReadNotificationCar();
      }
      this.isActive = !this.isActive;
    },
    handleClick(item, index,a) {
    console.log('Item:', item);
    console.log('Index:', index);
    console.log('a:', a);
    // คุณสามารถทำสิ่งอื่นๆ ที่ต้องการกับ item หรือ index ที่นี่
  },
  async checkbox_notification(){
    // this.isVisible = !this.isVisible;
      if(this.checkId.length) {
        this.remove_notification()
      } else {
        this.showCheckbox = !this.showCheckbox
      }
    },
    async remove_notification(){
      const data = this.filterType(this.combinedData);
      var arrayDelNoti = []
      this.checkId.forEach((index) => {
        let days = 99;  

        if (data[index].isType) {
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          const expireDate = new Date(data[index].expireDate);
          expireDate.setHours(0, 0, 0, 0);
          const timeDifference = expireDate - currentDate; 
          days = timeDifference / (1000 * 60 * 60 * 24);
        }

        arrayDelNoti.push({
          "id": data[index].id,
          "isType": data[index].isType
            ? data[index].isType
            : data[index].modelname == 'ClaimExpense'
            ? "claimExpenses"
            : data[index].modelname == 'LeaveUser'
            ? "leaveUser"
            : data[index].modelname == 'LeaveAdmin'
            ? "leaveAdmin"
            : "leave", 
          "typeUser": data[index].isType
            ? (days <= 30 ? "Admin2" : "Admin") 
            : "Admin",
        });
      })
      await ClaimExpenseService.unActiveNotification(arrayDelNoti);
      this.checkId = [];
      await this.initNotification();
    },
    async handleClaim(id){
      await ClaimExpenseService.isReadClaimExpenseId(id);
      await this.initNotification();
      this.$router.push('/app/claim').then(() => {
        window.location.reload();
      });
    },
    async handleLeave(id){
      await LeaveService.isReadLeaveId(id);
      await this.initNotification();
      this.$router.push('/app/leave').then(() => {
        window.location.reload();
      });
    },
    async handleLeaveUser(id){
      await LeaveUserService.isReadLeaveUserId(id);
      await this.initNotification();
      this.$router.push('/app/leaveAdmin/all').then(() => {
        window.location.reload();
      });
    },
    async handleLeaveAdmin(id){
      await LeaveAdminService.isReadLeaveAdminId(id);
      await this.initNotification();
      this.$router.push('/app/leaveUser/all').then(() => {
        window.location.reload();
      });
    },
    async handleCar(id,expireType){
      await CarService.isReadNotificationCarId(id,expireType);
      await this.initNotification();
      this.$router.push('/app/Car/'+id).then(() => {
        window.location.reload();
      });
    },
    dateTimeFormat(data) {
      const parsedDate = dayjs(data);
      dayjs.locale('th');
      const currentTime = dayjs();

      const hourDiff = currentTime.diff(parsedDate, 'hour');
      const minuteDiff = currentTime.diff(parsedDate, 'minute');

      if (hourDiff === 0 && minuteDiff < 60) {
        return minuteDiff + " นาทีที่ผ่านมา";
      } else if (hourDiff > 0 && hourDiff < 25) {
        return hourDiff + " ชั่วโมงที่ผ่านมา";
      } else {
        return parsedDate.format('D MMMM YYYY');
      }
    },
    toggleAllNotification(){
        this.typeNotification = "All";
        this.activeField = "All"
        this.isVisible = !this.isVisible;
        console.log("Type Notification is now:", this.typeNotification);

    },
    toggleUnReadNotification(){
        this.typeNotification = "Unread";
        this.activeField = "Unread"
        this.isVisible = !this.isVisible;
        console.log("Type Notification is now:", this.typeNotification);

    },
    filterType(data){
      if(this.typeNotification == "Unread"){
        return data.filter((data)=>(data.is_read == null||data.is_read == 2)&&data.is_read != 0&&data.is_read!=9);
      }else{
        return data.filter((data)=> data.is_read != 0&&data.is_read!=9)
      }
    }, 
    register_company(e) {
      const { 
        company_name,
        company_address,
        company_billing_address,
        company_phone_no,
        company_tax_id,
        company_logo,
        company_bank,
        company_bank_acc_name,
        company_bank_acc_no,
        company_bank_branch,
        company_email,
        admin_username,
        admin_password,
        admin_email,
        admin_firstname,
        admin_lastname,
        admin_phone_no,
        business_type_id,
        tax_id,
      } = e
      const company = {
        fullname: company_name,
        address: company_address,
        billing_address: company_billing_address,
        phone_no: company_phone_no,
        tax_id: company_tax_id,
        logo: company_logo,
        bank_name: company_bank,
        bank_acc_no: company_bank_acc_no,
        bank_branch: company_bank_branch,
        business_type_id: business_type_id,
        company_type_id: tax_id,
        email: admin_email,
      }
      const admin = {
        username: admin_username,
        password: admin_password,
        email: admin_email,
        firstname: admin_firstname,
        lastname: admin_lastname,
        phone_no: admin_phone_no,
      }
      const fileLogo = this.base64ToFile(company_logo);
      let carCompany = {
        fullname: company_name,
        address: company_address,
        billing_address: company_billing_address,
        phone_no: company_phone_no,
        tax_id: company_tax_id,
        logo: company_logo ?? null,
        logo_file_name: company_logo ? fileLogo.name : null,
        bank_account_name: company_bank_acc_name,
        bank_id : company_bank,
        bank_account_no: company_bank_acc_no,
        bank_branch: company_bank_branch,
        company_id: this.company_id,
        email: admin_email,
      }
      Swal.fire({
        title: 'กำลังดำเนินการ...',
        text: 'กรุณารอสักครู่',
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading()
        }
      });

      AdminService.createCompanyAdmin({data: [company]})
        .then(res => {
          if (res) {
            admin.company_id = res.data.id
            AdminService.createAdmin({data: [admin]})
              .then(res => {
                if (res) {
                  carCompany.company_id = res.data.company_id
                  const permission = {
                    role: 'admin',
                    role_id: res.data.id,
                    is_active: true,
                    dashboard: 2,
                    calendar: 2,
                    schedule: 2,
                    quotation: 2,
                    booking: 2,
                    billing: 2,
                    manage_booking: 2,
                    manage_tour: 2,
                    company: 2,
                    manage_cars: 2,
                    manage_car_parts: 2,
                    manage_sales: 2,
                    manage_repair: 2,
                    gas_station: 2,
                    manage_users: 2,
                    manage_accident: 2,
                    roll_call: 2,
                    cr_chat: 2,
                    //subfeture
                    user_or_company: 2,
                    coordinator: 2,
                    customer_group_type: 2,
                    external_customer: 2,
                    guide: 2,
                    quotation_sub: 2,
                    manage_notes: 2,
                    wait_bill: 2,
                    overdue_bill: 2,
                    monthly_report: 2,
                    guarantee_work: 2,
                    manage_car_company: 2,
                    manage_car_type: 2,
                    car_seat_type: 2,
                    vehicle_employee: 2,
                    allowance: 2,
                    all_car: 2,
                    vehicle_parking_spot: 2,
                    additional_car: 2,
                    additional_qt: 2,
                    purpose_of_travel: 2,
                    expiry_date_report: 2,
                    parts_type: 2,
                    unit_parts: 2,
                    manage_sale_vendor: 2,
                    tradesman: 2,
                    manage_spare_parts: 2,
                    disbursement_parts: 2,
                    history_parts: 2,
                    repair_type: 2,
                    accident_report: 2,
                    car_tracking: 2,
                    check_car: 2,
                    leave_management: 2,
                    leave_admin: 2,
                    customer_projects: 2,
                    worksheet_management: 2,
                    worksheet: 2,
                    manage_car: 2,
                    manage_worksheet: 2,
                  }

                  AdminService.createPermissionAdmin({data: [permission]})
                    .then(async res2 => {
                      if (res2) {

                        // API to add car company
                        await SystemService.create_all("CarCompany", { data: [carCompany] })
                          .then(res => {
                              if (res) {
                                  console.log('CarCompany Crreated', res)
                              }
                          })

                        // i need if user click ok give reload page
                        Swal.fire({
                          title: 'สร้างบริษัท และผู้ดูแลเรียบร้อยแล้ว',
                          text: 'ระบบได้ส่งข้อมูลบัญญชีไปยังอีเมลแล้ว กรุณาตรวจสอบ',
                          icon: 'success',
                          confirmButtonText: 'OK'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.$router.push(`/app/admin`)
                          }
                        })
                        
                      }
                    })
                }
              })
          }
        })
    },
    base64ToFile(base64) {
      const [header, base64Data] = base64.split(',');
      const mimeType = header.match(/data:([^;]+);base64/)[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      const extension = mimeType.split('/')[1]; // e.g., "png" for "image/png"
      const fileName = `logo.${extension}`;
      const file = new File([blob], fileName, { type: mimeType });
      return file;
    },
    logout () {
      localStorage.clear()
      window.location.href = '/'
    },
    leaveUser () {
      this.$router.push('/app/leaveUser/all');
    },
    apply_fullscreen(e) {
      this.fullscreen = e
    },
    apply_model_data(e) {
      this.model_data = e
    },
    toChangePassword() {
      this. user_profile = localStorage.getItem('user_profile')? JSON.parse(localStorage.getItem('user_profile')):{}
      this.$router.push(`/app/manageUsers/${this.role}/`+this.user_profile?.username)
    },
    notificationImage() {
      return NotHaveNoti;
    },
    async handleClickOutside(event) {
      const notificationContainer = this.$refs.notificationContainer;
      const dropdownOverlay = document.querySelector('.ant-dropdown'); // or a specific class for your overlay
      if (
        notificationContainer &&
        !notificationContainer.contains(event.target) &&
        dropdownOverlay &&
        !dropdownOverlay.contains(event.target)
      ) {
        this.isActive = false;
      }
    },
    async initNotification(){
      this.loadingNotification = true;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      this.leaveTypeList = await SystemService.get_core('getLeaveType')
      const data = await UserService.get_user_profile();
      if(data.permission.role == "admin" || data.permission.role == "administrative"){
        let claimNotFilter = await ClaimExpenseService.getClaimExpense();
        this.claimData = claimNotFilter.filter((item) =>  item.is_active_noti_admin != 0 && item.approve == 0);
        const leaveDataNotFilter = await LeaveService.getLeaveDriver('WAITING');
        this.leavesData = leaveDataNotFilter.filter((item)=> item.is_active_noti_admin != 0);
        var LeaveUser = await LeaveUserService.getLeaveUserNotiByCompany();
          LeaveUser = LeaveUser
                      .map((item) => {
                        return {
                          ...item, 
                          user_name: item.accountings_fullname
                                    ? item.accountings_fullname
                                    : item.administratives_fullname
                                    ? item.administratives_fullname
                                    : item.operations_fullname
                                    ? item.operations_fullname
                                    : item.admins_firstname + " " + item.admins_lastname,                               
                        };
                      });
        this.leavesUserData = LeaveUser.filter((item)=> item.is_active_noti_admin != 0);
        this.leavesUserData = this.leavesUserData
                        .map((item) => {
                        return {
                            ...item, 
                            created_at: item.updated_at,
                          };
                        });
        this.getCarNoti = await CarService.getNotificationCar();

        this.combinedData = [...this.claimData, ...this.leavesData,...this.getCarNoti,...this.leavesUserData];
      }else if (data.permission.role == "operation" || data.permission.role == "accounting"){
        this.getCarNoti = await CarService.getNotificationCar();
        const leaveApproveNotFilter = await SystemService.get_core('getLeaveAdminProgressTwo')
        this.leaveApproveList = leaveApproveNotFilter
                                .filter((item)=>item.approve_status != null && item.is_active_noti_admin != 0 && item.user_name == data.username)
                                .map((item) => {
                                return {
                                  ...item, 
                                  updated_date: item.approved_date?item.approved_date:item.created_at                                
                                };
                                });
        this.combinedData = [...this.getCarNoti,...this.leaveApproveList];
      }

      if(this.combinedData != []){
        this.combinedData.sort((a, b) => {
          // const isReadPriority = (value) => value === null || value === 2;

          // const aPriority = isReadPriority(a.is_read) ? 0 : 1;
          // const bPriority = isReadPriority(b.is_read) ? 0 : 1;
          // if (aPriority !== bPriority) {
          //   return aPriority - bPriority;
          // }
          if(a.updated_date || b.updated_date){
            const dateA = new Date(a.updated_date? a.updated_date:a.created_at);
            const dateB = new Date(b.updated_date? b.updated_date:b.created_at);
            return dateB - dateA;
          }else{
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA;
          }
        });

        this.combinedData = this.combinedData.filter((item)=>{
          const createdDate = new Date(item.created_at);
          createdDate.setHours(0, 0, 0, 0);
          const timeDiff = (currentDate - createdDate) / (1000 * 60 * 60 * 24);
          return timeDiff < 30;
        })
        this.badgeCountNoti = this.combinedData.filter((cData)=> cData.is_read == null || cData.is_read == 2).length;
      }
      this.loadingNotification = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  async mounted(){
    this.initNotification();
    document.addEventListener('click', this.handleClickOutside);
    await this.init_company_default();

  }


}
</script>

<style lang="scss">
//'Noto Sans Thai', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

$blue: #1890FF;
$dark: #23316E;
$green: #46b04b;
$orange: #ee582f;
$yellow: #fcab10;

* {
  font-family: 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-badge .ant-badge-count {
  font-size: 8px;
  height: 12px;
  line-height: 12px;
  min-width: 12px;
  padding: 0 4px;
}
.ant-upload-list-item-actions {
  display: flex;
  align-items: center;
}

.ant-upload-list-item-actions .download-icon {
  font-size: 16px;
  color: #1890ff; 
  margin-left: 8px;
  cursor: pointer;
}

.ant-upload-list-picture-container {
  margin-right: 10px;
}
.ant-upload-list {
  display: flex;
  overflow-x: scroll;
}
span.ant-upload-list-item-name {
  display: none !important;
}
.ant-layout-sider-children {
  background-color: $dark;
  // overflow-y: scroll;
}
.ant-input-number {
  width: 100% !important;
}
.ant-divider-inner-text {
  color: #1d39c4;
}
.ant-layout-sider-children::-webkit-scrollbar {
  opacity: 1;
}
.ant-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.material-symbols-outlined {
  font-size: 14px;
  margin: auto 3px
}
// html::-webkit-scrollbar, .no-scroll::-webkit-scrollbar {
//   display: none;
// }
.detailCheck{
  font-size: 12px;
  background-color: white;
  border: 0.5px solid grey;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.detailCheck:hover{
  background-color: #e6e6e6;
}

footer {
  position: absolute;
  bottom: 0;
  padding:11px 30px 12px;
  background-color: #f0f2f5;
  width: 100%;
  border-top:1px solid #0000000d;
  font-size: 11px;
}

html {
  padding: 0;
  width: 100vw;

  body {
    margin: 0;
  }
}

.google-icon {
  display: flex;
  align-items: center;
  span {
    padding-left: 4px;
  }
}
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapse-button {
  background-color: $dark;
  border: none;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
  overflow: hidden;
  justify-content: space-between;
}

.menu-class {
  span, .menu-list {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 8px;
    font-size: 14px;
    color: #ffffffa6 !important;
  }
  a {
    text-decoration: none;
  }
}
.app-container {
  height: 100vh;
  display: flex;
}
.app-content {
  background-color: white;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 0.375rem;
  padding: 2rem;
  overflow-y: scroll;
}
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panel-container {
  width: 240px;
  text-align: center;

  @media (-width: 240px) {
    opacity: 1;
  }
}

.primary-button {
  cursor: pointer;
  background-color: $blue;
  color: white;
  margin: 0px 8px 12px 0px;
  padding: 7.2px 42px;
  width: 100%;
  border: none;
  border-radius: 6px;
}
.bg-blue {
  background-color: $blue !important;
}
.bg-green {
  background-color: $green !important;
}
.bg-orange {
  background-color: $orange !important;
}
.bg-yellow {
  background-color: $yellow !important;
}

.ant-upload {
  margin: 0 !important;
  height: fit-content !important;
  width: fit-content !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}
.dark-background {
  background-color: $dark;
}
.app-nav {
  height: 60px; /* Fixed height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden; /* Prevent content overflow */
  min-height: 60px; /* Ensures it doesn’t shrink below 60px */
  max-height: 60px; /* Ensures it doesn’t expand above 60px */
  box-sizing: border-box; /* Ensures padding and borders don’t affect height */
}

.profile {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 14px;
}
.nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center; 
  width: 100%;
  color: white;
}
.btn-disabled,
.btn-disabled[disabled] {
  opacity: .9;
  cursor: default !important;
  pointer-events: none;
}
.buttonChoose:hover{
  background-color: #f0fcfc;
  border-color: #3494fc;
}
.buttonChoose2:hover{
  background-color: #f0fcfc;
  border-color: #3494fc;
}
.listNoti{
  padding: 2px;
  border-top: solid #80808099 1px;
}


.menu-item-hover {
  display: block; /* ให้ครอบคลุมพื้นที่ทั้งหมด */
  padding: 8px; /* เพิ่มพื้นที่รอบข้อความ */
  background-color: transparent; /* สีพื้นหลังเริ่มต้น */
  transition: background-color 0.3s; /* เพิ่มการเปลี่ยนสีที่ลื่นไหล */
}

.menu-item-hover:hover {
  background-color: #d3d3d3 !important; /* บังคับให้เป็นสีแดง */
  cursor: pointer;
}
.seeAll:hover{
  background-color: #d3d3d3;
}


</style>
