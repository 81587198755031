<template>
  <div style="height: 100vh; padding: 15px">
    <div class="app-content">
      <a-card title="ตรวจสอบค่าใช้จ่ายใบงาน">
        <a-row style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
          <a-col>
            <a-button @click="show_monthly_report = true" type="primary" size="large">
              <template #icon><span class="material-symbols-outlined">Add</span></template>
              ออกรายงานการจองจัดรถ
            </a-button>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-input-search v-model:value="search_input" placeholder="ค้นหาใบงาน"
              enter-button="ค้นหา" />
          </a-col>
          <a-col style="margin-left: 1rem">
            <a-dropdown v-model:visible="booking_filter_visible" :trigger="['click']">
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1">
                    <span>1.สถานะใบงาน</span>
                    <a-select
                      style="width: 280px"
                      v-model:value="sheet_status"
                      placeholder="สถานะใบงาน"
                      @change="handleStatusChange"
                  >
                      <a-select-option :value="0">ใบงานว่าง</a-select-option>
                      <a-select-option :value="2">รอรายละเอียด</a-select-option>
                      <a-select-option :value="3">มอบหมายงานแล้ว</a-select-option>
                  </a-select>

                  </a-menu-item>
                  <a-menu-item key="2">
                    2.ต้นทาง
                    <a-input
                      v-model:value="search_origin"
                      style="width: 280px"
                      :placeholder="'เลือกต้นทาง'"
                    />

                  </a-menu-item>
                  <a-menu-item key="3">
                    3.ปลายทาง
                    <a-input
                      style="width: 280px"
                      v-model:value="search_destination"
                      placeholder="ปลายทาง"
                    />
                  </a-menu-item>
                  <a-menu-item key="4">
                    4.ลูกค้า/บริษัท
                    <a-select
                      style="width: 280px"
                      v-model:value="search_client_company"
                      placeholder="ลูกค้า/บริษัท"
                      :options="
                          client_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                    >
                    </a-select>
                  </a-menu-item>
                  <a-menu-item key="5">
                    5.บริษัทรถ
                    <a-select
                      style="width: 280px"
                      v-model:value="search_car_company"
                      placeholder="บริษัทรถ"
                      :options="
                          car_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                    >
                    </a-select>
                  </a-menu-item>
                  <a-menu-item key="6">
                    6.ผู้ดูแลใบงานฝ่ายปฏิบัติการ
                    <a-select
                      style="width: 280px"
                      v-model:value="search_operation"
                      placeholder="ผู้ดูแลใบงานฝ่ายปฏิบัติการ"
                      :options="
                        operation_list.map(({ id, fullname }) => ({
                          value: id,
                          label: `${fullname}`,
                        }))
                      "
                    >
                    </a-select>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button style="width: 225px">
                <div
                  v-if="!car_filter_title"
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <span style="color: grey">ข้อมูลใบงาน</span>
                  <span
                    class="material-symbols-outlined"
                    style="font-size: 16px; color: grey"
                    >directions_car</span
                  >
                </div>
                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                {{ car_filter_title }}
                </div>
              </a-button>
            </a-dropdown>
          </a-col>
          <a-range-picker v-model:value="datetime" @change="handleTableChange" style="margin-left: 1rem" />
          <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="show_need_cars" />
            <span style="margin-left: 5px">จัดรถไม่ครบ</span>
          </a-col>
          <a-select v-model:value="search_booking_status" label-in-value style="width: 135px; margin-left: 1rem"
            placeholder="สถานะการจัดรถ" @change="handleTableChange">
            <a-select-option :value="0">ครบ</a-select-option>
            <a-select-option :value="1">ไม่ครบ</a-select-option>
            <a-select-option :value="2">เกิน</a-select-option>
          </a-select>
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="require_rollcall" />
            <span style="margin-left: 5px">อนุญาติเฉพาะรถผ่าน RollCall</span>
          </a-col>
        </a-row>


        <a-modal v-model:visible="show_monthly_report" title="ออกรายงานการจองจัดรถ" :footer="null">
          <a-form :model="monthly_report_date" layout="vertical" ref="formMonthly" @finish="submit_monthly_report()">
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item name="report_company" :rules="CompanyClientRules">
                  <template #label>
                    <div style="display: flex; align-items: center;">
                      <span style="margin-right: 8px;">ลูกค้า/บริษัท</span>
                      <label class="inline-flex items-center" style="margin-bottom: 1px;">
                        <input type="checkbox" v-model="selectedTypeReportClient" value="All"
                          class="form-checkbox text-blue-600" @change="handleCheckboxChangeClient"
                          style="width: 12px;height: 12px;" />
                        <span style="margin-left: 5px; font-size: 13px;">ทั้งหมด</span>
                      </label>
                    </div>
                  </template>
                  <a-select v-model:value="monthly_report_date.report_company" placeholder="เลือกลูกค้า/บริษัท"
                    style="width: 300px" show-search :disabled="selectedTypeReportClient == true">
                    <a-select-option v-for="{ fullname } in company_list" :value="fullname">{{ fullname
                      }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item name="report_company_car" :rules="car_company">
                  <template #label>
                    <div style="display: flex; align-items: center;">
                      <span style="margin-right: 8px;">บริษัทรถ</span>
                      <label class="inline-flex items-center" style="margin-bottom: 1px;">
                        <input type="checkbox" v-model="selectedTypeReportCarCompany" value="All"
                          class="form-checkbox text-blue-600" @change="handleCheckboxChangeCarCompany"
                          style="width: 12px;height: 12px;" />
                        <span style="margin-left: 5px; font-size: 13px;">ทั้งหมด</span>
                      </label>
                    </div>
                  </template>
                  <a-select v-model:value="monthly_report_date.report_company_car" placeholder="เลือกบริษัทรถ"
                    style="width: 300px" show-search :disabled="selectedTypeReportCarCompany == true">
                    <a-select-option v-for="{ fullname } in car_company_list" :value="fullname">{{ fullname }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
              <a-col :span="20">
                <a-form-item label="วันที่รับ-ส่งงาน" name="datetime"
                  :rules="[{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }]">
                  <a-range-picker v-model:value="monthly_report_date.datetime" format="DD/MM/YYYY" style="width: 300px"
                    :placeholder="['Start Date', 'End Date']" :minuteStep="1" :ranges="ranges" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row justify="center">
                <a-col :span="20">
                  <a-form-item label="สถานะรถ" name="car_status">
                    <a-input v-model:value="monthly_report_date.car_status_text" disabled style="width: 300px;" />
                  </a-form-item>
                </a-col>
              </a-row>
            <a-row justify="end">
              <a-button type="primary" html-type="submit">บันทึก</a-button>
            </a-row>
          </a-form>
        </a-modal>

        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane
            v-for="tab in filteredTabs.filter(t => ![null, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19,20].includes(t.status))"
            :key="tab.status">
            <template #tab>
              <a-badge :count="count_by_status(tab.status)">
                <div class="google-icon">
                  <span class="material-symbols-outlined">{{ tab.icon }}</span>
                  <span>{{ tab.title }}</span>
                </div>
              </a-badge>
            </template>
          </a-tab-pane>
        </a-tabs>

        <a-table rowKey="id" :columns="booking_columns"
          :data-source="filtered_table(booking_list)" bordered size="small"
          :scroll="{ x: 'calc(100px + 50%)', y: '500px' }" :row-selection="{
            type: 'radio',
            onSelect: select_row,
          }" :pagination="{
            total: pagination.total,
            current: pagination.onpage,
            pageSize: pagination.perPage,
          }" @change="handleTableChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'booking_no' && record[column.key]">
              {{ record.booking_no }}
            </template>



            <template v-if="column.key === 'active_car'">
              <div style="padding: 15px; display: flex; justify-content: center; align-items: center;">
                <a-tag color="green">
                  {{ record.booking_cars[0].active_car ? "แจ้งงานแล้ว" : "แจ้งงาน" }} เมื่อ {{
                    render_time(record.booking_cars[0].active_car_timestamp) }}
                </a-tag>
              </div>
            </template>
            <template v-if="column.key == 'time_start'">
              <a-tag color="green">{{ render_datetime(record.time_start,record.time_product) }}</a-tag>
            </template>
            <template v-if="column.key == 'time_end'">
              <a-tag color="green">{{ render_datetime(record.time_end,record.time_product_finish) }}</a-tag>
            </template>
            <template v-if="column.key === 'car_company'">
              <div>{{ record.car_company.fullname }}</div>
            </template>
            <template v-if="column.key === 'project_name'">
              <div>{{ record.project ? record.project.project_name : '' }}</div>
            </template>

            <template v-if="column.key === 'status' && record[column.key] != null">
              <div style="padding: 15px 10px;">
                <span><a-tag :color="tag_option.status[record.status]?.color">
                    {{ tag_option.status[record.status]?.txt }}
                  </a-tag>
                </span>
              </div>
            </template>
            <template v-if="column.key === 'sheet_status'">
              <span>
                <a-tag v-if="worksheet_status(record)" :color="worksheet_status(record).color">
                  {{ worksheet_status(record).txt }}
                </a-tag>
              </span>
            </template>
            <template v-if="column.key === 'client'">
              <div>{{ record.client.fullname }}</div>
            </template>
            <template v-if="column.key === 'operation'">
              <div>{{ record.operation && record.operation.fullname ? record.operation.fullname : '' }}</div>
            </template>

            <template v-if="column.key == 'profit'">
              {{
                (
                  Number(record.selling_per_trip) -
                  Number(sum_car_expense(record,record.booking_cars))
                ).toLocaleString()
              }}
            </template>
            <template v-if="column.key == 'driverCost'">
              {{ Number(show_driver_cost(record.booking_cars)).toLocaleString() }}
            </template>
            <template v-if="column.key === 'active_job'">
              <a-button
                type="danger"
                @click="activate_job(record)"
                :disabled="record.active_job == 1 || record.booking_cars.length == 0"
                >{{ record.active_job ? "แจ้งงานแล้ว" : "แจ้งงาน" }}
                </a-button>
            </template>
            <template v-if="column.key === 'finished'">
              <div style="display: flex; justify-content: center; align-items: center;">
              <a-button
                type="primary"
                @click="on_booking_finished(record.id , record.booking_cars[0]?.id)"
                :disabled="record.finished == 1"
                >{{ record.finished ? "อนุมัติแล้ว" : "อนุมัติ" }}</a-button
              >
              </div>
            </template>
            <template v-else-if="column.key === 'selling_per_trip' && record[column.key]">
              <b>{{ Number(record.selling_per_trip).toLocaleString() }}</b>
            </template>

            <template v-if="column.key === 'countDays'">
              <a-tag color="green">{{ countDays(record.time_start, record.time_end) }}</a-tag>
            </template>

            <template v-if="column.key === 'destination'">
              <div>{{ render_des(record) || '-' }}</div>
            </template>


            <template v-if="column.key === 'billing_status' && record[column.key]">
              <a-tooltip placement="topLeft">
                <template #title>
                  <a-tag
                    v-for="({ billing_type, billing_type_id }, i) in record.billings"
                    :key="i"
                    :color="billing_color[billing_type_id]"
                  >
                    {{ billing_type }}
                  </a-tag>
                </template>
                <a>{{ record.billings?.length }}</a>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'total_cars_expense'">
              <div style="display: flex; align-items: center; justify-content: center">
                <span style="font-size: 16px"
                  >{{ sum_car_expense(record,record.booking_cars).toLocaleString() }}
                </span>
                <a
                  @click="show_total_cars_expense(record,record.id)"
                  :key="record.id"
                  :style="`height: 16px; color: ${
                    allIsApproved(record.booking_cars)
                      ? 'greenyellow'
                      : 'red'
                  }`"
                >
                  <span
                    style="font-size: 16px; line-height: 0"
                    class="material-symbols-outlined"
                    >pie_chart</span
                  >
                </a>
              </div>
            </template>


            <template v-if="column.key === 'id'">
              <div style="padding: 15px;">
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>อัพเดทข้อมูล</template>
                      <a @click="show_update_progress(record.id)"><span
                          style="font-size: 14px" class="material-symbols-outlined">update</span></a>
                    </a-tooltip>

                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="go_to_edit_quotation( record.id)"><span style="font-size: 14px"
                          class="material-symbols-outlined">drive_file_rename_outline</span></a>
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_booking_car(record.booking_cars[0]?.id)"><span style="font-size: 18px"
                          class="material-symbols-outlined">delete</span></a>
                    </a-tooltip>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </a-table>
      </a-card>
    </div>





      <a-modal v-model:visible="update_progress_modal" @ok="update_progress">
        <template #title>
      Booking ID : {{ selected_booking?.booking_no }}
    </template>
        <a-card>
          <a-form :model="selected_booking" layout="vertical">
            <a-form-item label="สถานะ">
              <a-select v-model:value="temp_status" placeholder="เลือกสถานะ">
                <a-select-option
                  v-for="{ status, title } in sortedBookingStatusList"
                  :key="status"
                  :value="status"
                >
                  {{ title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-card>
      </a-modal>


    <a-modal v-model:visible="total_cars_expense_modal" width="80%" @ok="save_car_expenses"
      @cancel="handleCloseCarExpenses" :footer="null">
      <template #title>
        <span v-html="modalTitle"></span>
      </template>
        <div v-if="bookingCarExpenseModal">
        <p style="color: black; margin-left: 10px;">ทะเบียนรถ : {{ search_plate_no }} </p>
      </div>
      <div v-if="total_car_expenses">

        <a-collapse v-model:activeKey="total_car_expenses_collapse" expand-icon-position="right"
          style="background-color: white; font-size: 18px; font-weight: 500">
          <a-collapse-panel key="1" v-if="selected_booking.company_3rd !== 'บริษัทรถร่วม'">
            <template #header>
              <div>
                1. ค่าทางด่วน -
                {{ total_car_expenses.toll_ways?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.toll_ways?.length }}
                <a-tag v-if="showWarningTollWays" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totaTollWays }}บาท
            </template>

            <a-button @click="init_toll_way()" type="primary" style="margin-bottom: 10px">เพิ่มค่าทางด่วน</a-button>
            <a-table :dataSource="filteredTollWays" :pagination="false" :columns="[
              {
                title: 'ทะเบียนรถ',
                dataIndex: 'plate_no',
                key: 'plate_no',
              },
              {
                title: 'ผู้ชำระ',
                dataIndex: 'driver_paid',
                key: 'driver_paid',
                align: 'center',
                width: 100,
              },
              {
                title: 'จำนวนเงิน',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'จำนวนเงินที่จ่าย',
                dataIndex: 'paid_amount',
                key: 'paid_amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'วัน-เวลา',
                dataIndex: 'created_at',
                key: 'created_at',
                align: 'center',
                width: 180,
              },
              {
                title: 'หมายเหตุกรณีไม่อนุมัติ',
                dataIndex: 'approve_remark',
                key: 'approve_remark',
              },
              {
                title: 'สถานะ',
                dataIndex: 'granted',
                key: 'granted',
                align: 'center',
                width: 150,
              },
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 100,
              },
            ]">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'driver_paid'">
                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                </template>
                <template v-if="column.key === 'created_at'">
                  {{ render_date(record.created_at) }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select v-model:value="record.granted" @change="
                    update_granted(
                      record,
                      'toll_ways',
                      $event,
                      total_car_expenses.toll_ways
                    )
                    " style="width: 120px" placeholder="เลือกสถานะ">
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'toll_ways')"><span style="font-size: 14px"
                          class="material-symbols-outlined">drive_file_rename_outline</span></a>
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'toll_ways')"><span style="font-size: 18px"
                          class="material-symbols-outlined">delete</span></a>
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="2" v-if="selected_booking.company_3rd !== 'บริษัทรถร่วม'">
            <template #header>
              <div>
                2. ค่าน้ำมัน -
                {{ total_car_expenses.gas_refills?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.gas_refills?.length }}
                <a-tag v-if="showWarningGasRefills" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totalGasRefills }}บาท
            </template>

            <a-button @click="show_gas_refill_drawer = true; bill_photo_show_source = ''" type="primary"
              style="margin-bottom: 10px">เพิ่มค่าน้ำมัน</a-button>
            <a-table :dataSource="filteredGasRefills" :pagination="false" :columns="[
              {
                title: 'ทะเบียนรถ',
                dataIndex: 'plate_no',
                key: 'plate_no',
              },
              {
                title: 'การชำระเงิน',
                dataIndex: 'pay_method_id',
                key: 'pay_method_id',
                align: 'center',
                width: 120,
              },
              {
                title: 'จำนวนเงิน',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'จำนวนเงินที่จ่าย',
                dataIndex: 'total_amount',
                key: 'total_amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'วัน-เวลา',
                dataIndex: 'created_at',
                key: 'created_at',
                align: 'center',
                width: 180,
              },
              {
                title: 'หมายเหตุกรณีไม่อนุมัติ',
                dataIndex: 'approve_remark',
                key: 'approve_remark',
              },
              {
                title: 'สถานะ',
                dataIndex: 'granted',
                key: 'granted',
                align: 'center',
                width: 150,
              },
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 100,
              },
            ]">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'pay_method_id'">
                  {{ pay_method_list.find((e) => record.pay_method_id == e.id)?.name }}
                </template>
                <template v-if="column.key === 'created_at'">
                  {{ render_date(record.created_at) }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select v-model:value="record.granted" @change="
                    update_granted(
                      record,
                      'gas_refills',
                      $event,
                      total_car_expenses.gas_refills
                    )
                    " style="width: 120px" placeholder="เลือกสถานะ">
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'gas_refills')"><span style="font-size: 14px"
                          class="material-symbols-outlined">drive_file_rename_outline</span></a>
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'gas_refills')"><span style="font-size: 18px"
                          class="material-symbols-outlined">delete</span></a>
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="3"  v-if="selected_booking.company_3rd !== 'บริษัทรถร่วม'">
            <template #header>
              <div>
                3. ค่าใช้จ่ายอื่นๆ -
                {{ total_car_expenses.expenses?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.expenses?.length }}
                <a-tag v-if="showWarningExpenses" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totalExpense }}บาท
            </template>
            <a-button @click="show_create_expense = true" type="primary"
              style="margin-bottom: 10px">เพิ่มค่าใช้จ่ายอื่นๆ</a-button>
            <a-table :dataSource="filteredExpense" :pagination="false" :columns="[
              {
                title: 'ทะเบียนรถ',
                dataIndex: 'plate_no',
                key: 'plate_no',
              },
              {
                title: 'ผู้ชำระ',
                dataIndex: 'driver_paid',
                key: 'driver_paid',
                align: 'center',
                width: 100,
              },
              {
                title: 'จำนวนเงิน',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'จำนวนเงินที่จ่าย',
                dataIndex: 'total_payment',
                key: 'total_payment',
                align: 'center',
                width: 150,
              },
              {
                title: 'รายการ',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
              },
              {
                title: 'หมายเหตุกรณีไม่อนุมัติ',
                dataIndex: 'approve_remark',
                key: 'approve_remark',
              },
              {
                title: 'สถานะ',
                dataIndex: 'granted',
                key: 'granted',
                align: 'center',
                width: 150,
              },
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 100,
              },
            ]">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'driver_paid'">
                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select v-model:value="record.granted" @change="
                    update_granted(
                      record,
                      'expenses',
                      $event,
                      total_car_expenses.expenses
                    )
                    " style="width: 120px" placeholder="เลือกสถานะ">
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'expenses')"><span style="font-size: 14px"
                          class="material-symbols-outlined">drive_file_rename_outline</span></a>
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'expenses')"><span style="font-size: 18px"
                          class="material-symbols-outlined">delete</span></a>
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="4" :header="`4. ราคารถ`"  v-if="selected_booking.company_3rd !== 'บริษัทรถร่วม'">
            <template #extra>
              {{ carPayment }} บาท</template>
            <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
              ทะเบียนรถ: {{ car?.plate_no }} :
              {{ (Number(carPayment)).toLocaleString() }} บาท<br />
            </span>
          </a-collapse-panel>

          <a-collapse-panel key="5" :header="`5. ค่าจ้างคนขับ`"  v-if="selected_booking.company_3rd !== 'บริษัทรถร่วม'">
            <template #extra>
              {{ driverSalary }} บาท</template>
            <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
              ทะเบียนรถ: {{ car?.plate_no }} :
              {{ (Number(driverSalary)).toLocaleString() }} บาท<br />
            </span>
          </a-collapse-panel>

          <a-collapse-panel key="6" :header="`1. ราคารถ`"  v-if="selected_booking.company_3rd == 'บริษัทรถร่วม'">
            <template #extra>
              {{ selected_booking.price_car }} บาท</template>
            <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
              ทะเบียนรถ: {{ car?.plate_no }} :
              {{ (Number(selected_booking.price_car)).toLocaleString() }} บาท<br />
            </span>
          </a-collapse-panel>

          <a-collapse-panel key="7" :header="`2. ค่าแรงงาน`"  v-if="selected_booking.company_3rd == 'บริษัทรถร่วม'">
            <template #extra>
              {{ selected_booking.lift_things  }} บาท</template>
            <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
              ทะเบียนรถ: {{ car?.plate_no }} :
              {{ (Number(selected_booking.lift_things )).toLocaleString() }} บาท<br />
            </span>
          </a-collapse-panel>

          <a-collapse-panel key="3"  v-if="selected_booking.company_3rd == 'บริษัทรถร่วม'">
            <template #header>
              <div>
                3. ค่าใช้จ่ายอื่นๆ -
                {{ total_car_expenses.expenses?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.expenses?.length }}
                <a-tag v-if="showWarningExpenses" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totalExpense }}บาท
            </template>
            <a-button @click="show_create_expense = true" type="primary"
              style="margin-bottom: 10px">เพิ่มค่าใช้จ่ายอื่นๆ</a-button>
            <a-table :dataSource="filteredExpense" :pagination="false" :columns="[
              {
                title: 'ทะเบียนรถ',
                dataIndex: 'plate_no',
                key: 'plate_no',
              },
              {
                title: 'ผู้ชำระ',
                dataIndex: 'driver_paid',
                key: 'driver_paid',
                align: 'center',
                width: 100,
              },
              {
                title: 'จำนวนเงิน',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                width: 150,
              },
              {
                title: 'จำนวนเงินที่จ่าย',
                dataIndex: 'total_payment',
                key: 'total_payment',
                align: 'center',
                width: 150,
              },
              {
                title: 'รายการ',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
              },
              {
                title: 'หมายเหตุกรณีไม่อนุมัติ',
                dataIndex: 'approve_remark',
                key: 'approve_remark',
              },
              {
                title: 'สถานะ',
                dataIndex: 'granted',
                key: 'granted',
                align: 'center',
                width: 150,
              },
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 100,
              },
            ]">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'driver_paid'">
                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select v-model:value="record.granted" @change="
                    update_granted(
                      record,
                      'expenses',
                      $event,
                      total_car_expenses.expenses
                    )
                    " style="width: 120px" placeholder="เลือกสถานะ">
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'expenses')"><span style="font-size: 14px"
                          class="material-symbols-outlined">drive_file_rename_outline</span></a>
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'expenses')"><span style="font-size: 18px"
                          class="material-symbols-outlined">delete</span></a>
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>


        </a-collapse>

        <a-card>
          <div style="display: flex; justify-content: space-between" >
            <h4>รวมต้นทุนค่าเช่ารถ</h4>
            <a-space>
              <!-- <a-button type="primary" :disabled="selected_booking.paid_expense" @click="on_expense_paided">{{ selected_booking.paid_expense?'จ่ายแล้ว':'จ่าย'}}</a-button> -->
              <h4 style="margin: auto">
                {{ sum_expense(selected_booking.booking_cars).toLocaleString() }} บาท
              </h4>
            </a-space>
          </div>
        </a-card>

        <a-drawer v-model:visible="show_gas_refill_drawer" placement="right" title="บันทึกค่าน้ำมัน"
          @close="create_gas_refill = {}">
          <a-form name="create_gas_refill" autocomplete="off" layout="vertical" :model="create_gas_refill"
            @finish="submit_create_gas_refill">
            <a-row>
              <a-col :span="12">
                <a-form-item label="ทะเบียนรถ" name="booking_car_id" v-if="!bookingCarExpenseModal"
                  :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]">
                  <a-select v-model:value="create_gas_refill.booking_car_id" placeholder="เลือกรถ">
                    <a-select-option v-for="{ id, car: { plate_no } } in selected_booking.booking_cars" :key="id"
                      :value="id">
                      {{ plate_no }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-switch v-model:checked="create_gas_refill.use_gas_station" checkedChildren="ปั้มที่ร่วมกับบริษัท"
              unCheckedChildren="ปั้มนอก" />
            <a-form-item v-if="create_gas_refill.use_gas_station" label="เลือกปั้มน้ำมัน" name="gas_station_id"
              :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]">
              <a-select v-model:value="create_gas_refill.gas_station_id" placeholder="เลือกปั้มน้ำมัน">
                <a-select-option v-for="{ id, name } in gas_station_list" :key="id" :value="id">
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-else label="ระบุชื่อปั้ม" name="station_name"
              :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]">
              <a-textarea v-model:value="create_gas_refill.station_name" />
            </a-form-item>

            <a-form-item label="ชนิดน้ำมัน" name="gas_type" :rules="[
              { required: true, message: 'เลือกชนิดน้ำมัน' },
            ]">
              <a-select v-model:value="create_gas_refill.gas_type" placeholder="เลือกชนิดน้ำมัน">
                <a-select-option :value="'Diesel'">Diesel</a-select-option>
                <a-select-option :value="'Diesel B7'">Diesel B7</a-select-option>
                <a-select-option :value="'Vepower Diesel'">Vepower Diesel</a-select-option>
                <a-select-option :value="'EV'">EV</a-select-option>
              </a-select>
            </a-form-item>

            <a-row>
              <a-col :span="12">
                <a-form-item label="จำนวนลิตร" name="refill_amount"
                  :rules="[{ required: true, message: 'กรุณากรอกจำนวนลิตร' }]">
                  <a-input-number v-model:value="create_gas_refill.refill_amount" :min="0" :max="1000" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="จำนวนเงิน" name="amount" :rules="[{ required: true, message: 'กรุณากรอกยอดจ่าย' }]">
                  <a-input-number v-model:value="create_gas_refill.amount" :min="0" :max="100000" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item label="ประเภทการจ่ายเงิน" name="pay_method_id"
              :rules="[{ required: true, message: 'เลือกประเภทการจ่ายเงิน' }]">
              <a-select v-model:value="create_gas_refill.pay_method_id" placeholder="เลือกประเภทการจ่ายเงิน">
                <a-select-option v-for="{ id, name } in pay_method_list" :key="id" :value="id">
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="วันเวลา" name="datetime" :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]">
              <a-date-picker v-model:value="create_gas_refill.datetime" :show-time="{ format: 'HH:mm' }"
                format="DD/MM/YYYY HH:mm" placeholder="เลือกเวลา" :minuteStep="1" />
            </a-form-item>
            <a-form-item name="bill_photo" label="รูปหลักฐานการโอน">
              <a-upload v-model:file-list="gas.bill_photo_show" list-type="picture-card" class="avatar-uploader"
                accept="image/*" :show-upload-list="false"
                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                @change="upload_files($event, 'bill_photo_show', 'gas_refills')" method="GET"
                @remove="remove_uploaded_file">
                <div v-if="bill_photo_show_source != ''" style=" position: relative;
                      width: 100%;
                      height: 100%;">
                  <img :src="bill_photo_show_source" class="uploaded-image" alt="uploaded image" style=" width: 100%;
                          object-fit: cover;
                          transition: filter 0.3s ease;
                          cursor: pointer;" />
                </div>
                <div v-else style="display: flex; flex-direction: column; align-items: center;">
                  <p class="ant-upload-drag-icon">
                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                  </p>
                  คลิกเพื่ออัพโหลดรูปภาพ
                </div>
              </a-upload>
            </a-form-item>

            <a-row justify="end">
              <a-button html-type="submit" type="primary" size="large" style="margin: 4px; text-align: right">
                <span class="material-symbols-outlined" style="font-size: 16px; margin-right: 4px">Edit</span>
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer v-model:visible="show_create_toll_way" placement="right" title="เพิ่มรายการค่าทางด่วน">
          <a-form :model="create_toll_way" layout="vertical" @finish="submit_create_toll_way">
            <a-row>
              <a-col :span="12">
                <a-form-item label="ทะเบียนรถ" name="booking_car_id" v-if="!bookingCarExpenseModal"
                  :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]">
                  <a-select v-model:value="create_toll_way.booking_car_id" placeholder="เลือกรถ">
                    <a-select-option v-for="{ id, car: { plate_no } } in selected_booking.booking_cars" :key="id"
                      :value="id">
                      {{ plate_no }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item label="ผู้ชำระ" name="driver_paid" :rules="[{ required: true, message: 'กรุณากรอกผู้ชำระ' }]">
              <a-radio-group v-model:value="create_toll_way.driver_paid">
                <a-radio-button value="1">คนขับ</a-radio-button>
                <a-radio-button value="0">บริษัท</a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="สถานะงาน" name="booking_status" :rules="[{ required: true, message: 'เลือกสถานะงาน' }]">
              <a-select v-model:value="create_toll_way.booking_status" placeholder="เลือกสถานะงาน">
                <a-select-option :value="8">ก่อนเริ่มงาน</a-select-option>
                <a-select-option :value="7">ระหว่างงาน</a-select-option>
                <a-select-option :value="11">หลังจบงาน</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="จำนวนเงิน" name="amount" :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]">
              <a-input-number v-model:value="create_toll_way.amount" :min="0" :max="1000000" />
            </a-form-item>

            <a-form-item label="วันเวลา" name="datetime" :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]">
              <a-date-picker v-model:value="create_toll_way.datetime" :show-time="{ format: 'HH:mm' }"
                format="DD/MM/YYYY HH:mm" placeholder="เลือกเวลา" :minuteStep="1" />
            </a-form-item>


            <a-form-item label="ใบรูปหลักฐานการโอน" name="photo_1">
              <div class="upload-section">
                <a-upload v-model:file-list="toll_way_photo" list-type="picture-card" class="avatar-uploader"
                  accept="image/*" :show-upload-list="false"
                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                  @change="upload_files($event, 'photo_1_show', 'toll_ways', create_toll_way.index)" method="GET">
                  <img v-if="create_toll_way.photo_1" :src="create_toll_way.photo_1"
                    :style="`width: 100%;object-fit: cover`" alt="avatar" />
                  <div v-else>
                    <p class="ant-upload-drag-icon">
                      <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                    </p>
                    คลิกเพื่ออัปโหลดรูปภาพ
                  </div>
                </a-upload>
              </div>
            </a-form-item>

            <a-row justify="end">
              <a-button html-type="submit" type="primary" size="large" style="margin: 4px; text-align: right">
                <span class="material-symbols-outlined" style="font-size: 16px; margin-right: 4px">Edit</span>
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer v-model:visible="show_create_expense" placement="right" title="เพิ่มค่าใช้จ่ายอื่นๆ">
          <a-form :model="create_expense" layout="vertical" @finish="submit_create_expense">
            <a-row justify="space-between">
              <a-col :span="11">
                <a-form-item label="ทะเบียนรถ" name="booking_car_id" v-if="!bookingCarExpenseModal"
                  :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]">
                  <a-select v-model:value="create_expense.booking_car_id" placeholder="เลือกรถ">
                    <a-select-option v-for="{ id, car: { plate_no } } in selected_booking.booking_cars" :key="id"
                      :value="id">
                      {{ plate_no }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="11">
                <a-form-item label="ผู้ชำระ" name="driver_paid"
                  :rules="[{ required: true, message: 'กรุณาเลือกผู้ชำระ' }]">
                  <a-radio-group v-model:value="create_expense.driver_paid">
                    <a-radio-button value="1">คนขับ</a-radio-button>
                    <a-radio-button value="0">บริษัท</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="11">
                <a-form-item label="ประเภทการจ่ายเงิน" name="pay_method_id" :rules="[
                  {
                    required: true,
                    message: 'เลือกประเภทการจ่ายเงิน',
                  },
                ]">
                  <a-select v-model:value="create_expense.pay_method_id" placeholder="เลือกประเภทการจ่ายเงิน">
                    <a-select-option v-for="{ id, name } in pay_method_list" :key="id" :value="id">
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-form-item label="รายการ" name="expense_id" :rules="[{ required: true, message: 'กรุณากรอกรายการ' }]">
                  <a-select v-model:value="create_expense.expense_id">
                    <a-select-option v-for="expense in expense_list" :key="expense.id" :value="expense.id">
                      {{ expense.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row justify="space-between">
              <a-col :span="24">
                <a-form-item label="จำนวนเงิน" name="amount"
                  :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]">
                  <a-input-number v-model:value="create_expense.amount" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="รูปหลักฐานการโอน" name="photo">
                  <a-upload v-model:file-list="create_expense.photoList" list-type="picture-card"
                    class="avatar-uploader" accept="image/*" :show-upload-list="false"
                    action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                    @change="upload_files($event, 'photo', 'create_expense')" method="GET"
                    @remove="remove_uploaded_file">
                    <div v-if="create_expense.photo != '' && create_expense.photo != null" class="upload-container-gas"
                      :style="`width: 100%;object-fit: cover`">
                      <img :src="create_expense.photo" class="uploaded-image" alt="uploaded image"
                        :style="`width: 100%;object-fit: cover`" />
                    </div>
                    <div v-else style="display: flex; flex-direction: column; align-items: center;">
                      <p class="ant-upload-drag-icon">
                        <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                      </p>
                      คลิกเพื่ออัพโหลดรูปภาพ
                    </div>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row justify="end">
              <a-button html-type="submit" type="primary" size="large" style="margin: 4px; text-align: right">
                <span class="material-symbols-outlined" style="font-size: 16px; margin-right: 4px">Edit</span>
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>
      </div>
    </a-modal>


  </div>
  

  <a-modal v-model:visible="showModalActiveJob" @ok="showModalActiveJob = false">
    <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
      <span class="material-symbols-outlined"
        style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
      <p>กรุณาระบุชื่อคนขับ ก่อนกดแจ้งงาน</p>
    </div>
    <template #footer>
      <a-button type="primary" @click="showModalActiveJob = false">
        ตกลง
      </a-button>
    </template>
  </a-modal>

</template>

<script>
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import BookingService from "../../api/BookingService";
import CompanyService from "../../api/CompanyService";
import { exportXLSXFile } from "@/components/helpers.js";
import ReportService from "../../api/ReportService";
import Swal from "sweetalert2";
import JobOrder from "@/components/modals/job_order.vue";
import Utility from "../../utility";
import SystemService from "../../api/SystemService";
import { message } from "ant-design-vue";
import th, { name } from "dayjs/locale/th";
import BookingUpdatedPopup from "@/components/modals/booking_update_popup.vue";
import * as xlsx from "xlsx-js-style";
import UserService from "../../api/UserService";
import { co, s } from "@fullcalendar/core/internal-common";
import socketService from "../../api/SocketService";
import OperationService from "../../api/OperationService";
import CarCompanyService from '../../api/CarCompanyService';


export default {
  components: { JobOrder, BookingUpdatedPopup },
  props: {
    user_name: String,
  },
  name: "checkBill",
  data() {
    return {
      socketService,
      showModalActiveJob: false,
      companyId: null,
      ranges: {
        'Today': [dayjs(), dayjs()]  // Use dayjs() to get today's date
      },
      filteredTabs: [],
      customOrder: [16,20],
      model_form: {
        key: null // Initialize with your appropriate key
      },
      //BookingUpdatedPopup
      booking_business: false,
      business: false,
      bookingStatusData: [],
      bookingStatusCarData: [],
      BookingUpdatedVisible: false,
      bookingUpdatedData: null,
      bookingUpdated: {
        booking_no: '',
        total_cars_expense: null,
      },
      //TM department
      tmDepartment: null,
      tabsProduct: [
        { icon: 'menu', title: 'ทั้งหมด', status: null, activate_job: 1, roles: ['adminTM', 'preDelivery', 'planner', 'rollCall', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'แนะนำการจัดรถ', status: 0, roles: ['adminTM', 'preDelivery', 'planner', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รอจัดรถ', status: 1, roles: ['adminTM', 'planner', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'จัดรถแล้ว', status: 2, roles: ['adminTM', 'planner', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'ถึงจุดรับรถแล้ว', status: 3, roles: ['adminTM', 'planner', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รถถึงปลายทาง', status: 19, roles: ['rollCall', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'กำลังลงของ', status: 18, roles: ['adminTM', 'planner', 'rollCall', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'ยกเลิกการเดินทาง', status: 6, roles: ['adminTM', 'planner', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'กำลังเดินทาง', status: 7, roles: ['others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รับงาน', status: 8, roles: ['adminTM', 'planner', 'rollCall', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รถออกจากลานจอด', status: 9, roles: ['adminTM', 'planner', 'rollCall', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'กำลังขึ้นของ', status: 10, roles: ['adminTM', 'planner', 'rollCall', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'แจ้งงาน', status: 11, roles: ['adminTM', 'planner', 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รับของ', status: 12, roles: [, 'others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รับของเสร็จสิ้น', status: 13, roles: ['others', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'ปฏิเสธงาน', status: 14, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รอตรวจสอบ', status: 15, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        // { icon: 'list', title: 'รอตรวจสอบ', status: 21, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'ตรวจสอบแล้ว', status: 20, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'รถถึงต้นทาง', status: 16, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'อยู่ระหว่างขนส่ง', status: 17, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
        { icon: 'list', title: 'จบงาน', status: 5, roles: ['others', 'planner', 'Accounting', 'hr', 'info', 'transport', 'repair'] },
      ],


      showWarningTollWays: false,
      showWarningGasRefills: false,
      showWarningExpenses: false,

      editTollWay: null,
      newTollWay: false,
      newGas: false,
      newExpense: false,

      selected_booking: {
        booking_cars: [
          {
            plate_no: null,
          },
        ],
      },

      editable: Utility.get_permission_editable_with_key("booking"),
      booking_list: [],
      pagination: {
        total: 0,
        onpage: 1,
        perPage: 20,
      },
      ///filter
      search_input: null,
      datetime: null,
      search_booking_status: null,
      search_plate_no: null,

      activeKey: 15,
      show_need_cars: false,
      search_booking_status: null,
      require_rollcall: false,
      selected_driver_id: null,
      tag_option: {
        bookingType: {
          1: { txt: "เที่ยวเดียว (ขาเดียว)", color: "pink" },
          2: { txt: "ไป-กลับ", color: "red" },
          3: { txt: "รายวัน (เต็มวัน)", color: "orange" },
          4: { txt: "รายวัน (ครึ่งวัน)", color: "green" },
          5: { txt: "เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)", color: "cyan" },
          6: { txt: "เฉพาะวัน (ระบุวัน)", color: "blue" },
          7: { txt: "ใช้รถทุกวัน", color: "purple" },
          8: { txt: "รายเดือน", color: "green" },
        },

        status: {

          15: { txt: "รอตรวจสอบ", color: "warning", next: 15 },
          20: { txt: "ตรวจสอบแล้ว", color: "success", next: 20 },
        },

      },
      gas: {
        bill_photo_show: [],
        indexGas: null,
        orderPos: null,
      },
      bill_photo_show_source: "",
      toll_way_photo: [],
      billing_color: {
        1: "green",
        2: "red",
        3: "purple",
        4: "yellow",
      },
      selected_booking: null,
      temp_status: null,
      export_data: null,
      show_monthly_report: false,
      loading: false,
      show_timeline: false,
      job_order: false,

      /* update_progress_modal */
      update_progress_modal: false,

      /* update_booking_car_status_modal */
      update_booking_car_status_modal: false,

      /* total_cars_expense_modal */
      total_cars_expense_modal: false,

      pay_method_list: [],
      expense_log: {
        toll_ways: null,
        gas_refills: null,
        extras: null,
      },
      expense_logs: [
        {
          id: 1,
          name: "อนุมัติ",
        },
        {
          id: 2,
          name: "ไม่อนุมัติ",
        },
      ],
      show_gas_refill_drawer: false,
      create_gas_refill: {
        bill_photo: []
      },
      gas_station_list: [],
      booking_status_list: [],
      countTabStatus: null,
      booking_filter_visible: false,
      show_create_toll_way: false,
      create_toll_way: {},
      search_origin: null,
      search_destination: null,
      search_client_company: null,
      search_car_company: null,
      search_operation: null,

      show_create_expense: false,
      create_expense: {},
      expense_list: [],
      car_list: [],

      total_car_expenses_collapse: null,
      show_export_excel: false,
      export_excel: {
        time_start: null,
        time_end: null,
        plate_no: null,
      },
      userCompanyId: null,
      monthly_report_date: {
        report_time_start: null,
        report_time_end: null,
        report_company: null,
        report_company_car: null,
        datetime: null,
        car_status: 20,  // ใช้ 20 เป็นค่าที่จะส่งไปยังระบบ
        car_status_text: "ตรวจสอบแล้ว",  // ใช้ข้อความแสดงผล
      },
      selectedTypeReportClient: null,
      selectedTypeReportCarCompany: null,
      bookingCarExpenseModal: false,
      bookingCarExpenseData: [],
      userBusinessId: null,
      tabsCheck: [],
      tabsStatusOn: [],
      company_list: [],
      client_company_list: [],
      operation_list:[],
      car_company_list: [],


      
    };
  },
  computed: {
    modalTitle() {
      const bookingNo = this.selected_booking?.booking_no || '';
      `Booking Car : ${this.selected_booking?.booking_cars[0]?.booking_car_no || ''}`;
      return `<div style="margin-bottom: 5px;">ต้นทุนค่าใช้จ่าย ใบจองจัดรถ ID : ${bookingNo}</div>`;
    },

    sortedBookingStatusList() { 
        const status = this.booking_status_list;
        this.tmDepartment = JSON.parse(localStorage.getItem('user_profile')).department;
        let statusFilter;
  
          statusFilter = this.tabsProduct.filter(tab => {
            const excludeStatuses = [0, 1, 3, 7, 12, 13, 11, ]; 
            return !excludeStatuses.includes(tab.status) || 
              (this.selected_booking && tab.status === this.selected_booking.status);
          });
          const customOrder = [null, 2,8,9,16,10,17,19,18,5,6,14,15,20];
          statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
        
  
        let statusList = statusFilter.map(tab => {
          const statusItem = status.find(s => s.status == tab.status) || { status: null, name: 'ทั้งหมด' };
          if (statusItem) {
            tab.name = statusItem.name;
          }
          return tab;
        });
  
      
        return statusList;
      },


    CompanyClientRules() {
          return this.selectedTypeReportClient ? [] : [{ required: true, message: 'โปรดเลือกลูกค้า/บริษัท' }];
        },

        car_company() {
          return this.selectedTypeReportCarCompany ? [] : [{ required: true, message: 'โปรดเลือกบริษัทรถ' }];
        },

        handleCheckboxChangeClient() {
      this.$refs.formMonthly.clearValidate(['report_company']);
      if (!this.selectedTypeReportClient) {
        this.$nextTick(() => {
          this.$refs.formMonthly.validateFields(['report_company']);
        });
      }
    },

    handleCheckboxChangeCarCompany() {
      this.$refs.formMonthly.clearValidate(['report_company_car']);
      if (!this.selectedTypeReportCarCompany) {
        this.$nextTick(() => {
          this.$refs.formMonthly.validateFields(['report_company_car']);
        });
      }
    },


    booking_columns() {
      const booking_columns = [
        {
          title: 'QTC',
          dataIndex: 'booking_no',
          key: 'booking_no',
          width: 160,
          sorter: {
            compare: (a, b) => b.booking_no - a.booking_no,
            multiple: 1,
          },
        },
        {
          title: 'สถานะใบงาน',
          dataIndex: 'sheet_status',
          key: 'sheet_status',
          align: 'center',
          width: 150,
        },
        {
          title: 'วันที่ - เวลารับสินค้า',
          dataIndex: 'time_start',
          key: 'time_start',
          width: 150,
          align: 'center',
          sorter: {
            compare: (a, b) => new Date(b.time_start) - new Date(a.time_start),
            multiple: 2,
          },
        },
        {
          title: 'วันที่ - เวลา ส่งสินค้า',
          dataIndex: 'time_end',
          key: 'time_end',
          width: 150,
          align: 'center',
          sorter: {
            compare: (a, b) => new Date(b.time_end) - new Date(a.time_end),
            multiple: 3,
          },
        },
        {
          title: 'จำนวนวัน',
          dataIndex: 'countDays',
          key: 'countDays',
          width: 100,
          align: 'center',
          sorter: {
            compare: (a, b) => b.countDays - a.countDays, // ใช้ total_days แทน countDays
          },
        },
        {
          title: 'สถานะรถ',
          dataIndex: 'status',
          key: 'status',
          width: 250,
          align: 'center',
        },
        {
          title: 'ต้นทาง',
          dataIndex: 'origin',
          key: 'origin',
          width: 300,
          align: 'center',
        },
        {
          title: 'ปลายทาง',
          dataIndex: 'destination',
          key: 'destination',
          width: 300,
          align: 'center',
        },
        {
          title: 'สถานะงาน',
          dataIndex: 'active_job',
          key: 'active_job',
          width: 120,
          align: 'center',
        },
        {
          title: 'บริษัทรถ',
          dataIndex: 'car_company',
          key: 'car_company',
          align: 'center',
          width: 180,
        },
        {
          title: 'ผู้ดูแลใบงานฝ่ายปฏิบัติการ',
          dataIndex: 'operation',
          key: 'operation',
          width: 120,
          align: 'center',
        },
        {
          title: 'ชื่อ-นามสกุล ผู้ขับ',
          dataIndex: 'driver_firstname',
          key: 'driver_firstname',
          align: 'center',
          width: 180,
        },
        {
          title: 'ทะเบียนรถ',
          dataIndex: 'car_plate_no',
          key: 'car_plate_no',
          align: 'center',
          width: 180,
        },

        {
          title: 'ลูกค้า/บริษัท',
          dataIndex: 'client',
          key: 'client',
          width: 400,
        },
        {
          title: 'โปรเจกต์ลูกค้า',
          dataIndex: 'project_name',
          key: 'project_name',
          width: 400,
        },
        {
          title: 'แก้ไขล่าสุด',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 200,
          align: 'center',
        },
        {
          title: 'รายได้',
          dataIndex: 'selling_per_trip',
          key: 'selling_per_trip',
          width: 130,
          align: 'center',
        },
        {
          title: 'รายจ่าย',
          dataIndex: 'total_cars_expense',
          key: 'total_cars_expense',
          width: 130,
          align: 'center',
        },
        {
          title: 'กำไรสุทธิ',
          dataIndex: 'profit',
          key: 'profit',
          width: 130,
          align: 'center',
        },

      ]

        booking_columns.splice(23, 0, {
          title: 'สถานะการตรวจสอบ',
          dataIndex: 'finished',
          key: 'finished',
          width: 125,
          align: 'center',
        });
      

      booking_columns.push({
        title: 'ตัวเลือก',
        dataIndex: 'id',
        key: 'id',
        width: 190,
        fixed: 'right',
        align: 'center',
      });
      return booking_columns
    },

    filteredTollWays() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.toll_ways) {
        return [];
      }
      return this.total_car_expenses.toll_ways.filter(item => item.plate_no === selectedPlateNo);
    },
    totaTollWays() {
      return this.filteredTollWays
        .filter(({ granted }) => granted == 1)
        .reduce((a, b) => a + (Number(b.paid_amount) || 0), 0)
        .toLocaleString();
    },

    filteredGasRefills() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.gas_refills) {
        return [];
      }
      return this.total_car_expenses.gas_refills.filter(item => item.plate_no === selectedPlateNo);
    },
    totalGasRefills() {
      return this.filteredGasRefills
        .filter(({ granted }) => granted == 1)
        .reduce((a, b) => a + (Number(b.total_amount) || 0), 0)
        .toLocaleString();
    },

    filteredExpense() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.expenses) {
        return [];
      }
      let result = this.total_car_expenses.expenses.filter(item => item.plate_no === selectedPlateNo)
      result.forEach((item) => {
        item.name = this.expense_list.find(expense => expense.id === item.expense_id).name
      })
      return result
    },
    totalExpense() {
      return this.filteredExpense
        .filter(({ granted }) => granted == 1)
        .reduce((a, b) => a + (Number(b.total_payment) || 0), 0)
        .toLocaleString();
    },

    filteredBookingCars() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo) {
        return [];
      }
      return this.selected_booking.booking_cars.filter(({ car }) => car.plate_no === selectedPlateNo);
    },
    totalRentalCost() {
      return this.filteredBookingCars
        .map(({ rental_price }) => {
          const rentalPriceNum = parseFloat(rental_price);
          if (!isNaN(rentalPriceNum)) {
            return rentalPriceNum;
          } else {
            return 0;
          }
        })
        .reduce((total, amount) => total + amount, 0)
        .toLocaleString(undefined, { maximumFractionDigits: 2 });
    },

    totalDriverCost() {
      console.log("this.filteredBookingCars",this.filteredBookingCars)
      return this.filteredBookingCars
        .map(({ driver_cost }) => {
          const driverCostNum = parseFloat(driver_cost);
          if (!isNaN(driverCostNum)) {
            return driverCostNum;
          } else {
            return 1;
          }
        })
        .reduce((total, amount) => total + amount, 0)
        .toLocaleString(undefined, { maximumFractionDigits: 2 });
    },

    carPayment() {

    if (this.selected_booking.cars && this.selected_booking.cars.length > 0) {
            const carPayment = parseFloat(this.selected_booking.cars[0].car_payment);
            return isNaN(carPayment) ? '0.00' : (carPayment / 26).toFixed(2);
          }
          return '0.00';
        },

    driverSalary() {
          
      if (this.selected_booking.drivers && this.selected_booking.drivers.length > 0) {
        const salary = parseFloat(this.selected_booking.drivers[0].salary);
        return isNaN(salary) ? '0.00' : (salary / 26).toFixed(2);
      }
      return '0.00';
    },


    total_car_expenses() {
      const cars = this.selected_booking.booking_cars;
      const tollways_list = [];
      const gas_refills_list = [];
      const expenses_list = [];

      cars.forEach(({ toll_ways, gas_refills, expenses, car }) => {
        toll_ways.forEach((tollway) => {
          tollway.plate_no = car.plate_no;
          tollways_list.push(tollway);
        });

        gas_refills.forEach((gas_refill) => {
          gas_refill.plate_no = car.plate_no;
          gas_refills_list.push(gas_refill);
        });

        expenses.forEach((expense) => {
          const {
            expense: { name },
            booking_car_id,
            amount,
            id,
            granted,
            total_payment,
            driver_paid,
            expense_id,
            approve_remark,
            edit_expenses_status,
            pay_method_id,
            photo,
          } = expense;
          expenses_list.push({
            id,
            booking_car_id,
            amount,
            plate_no: car.plate_no,
            granted,
            total_payment,
            approve_remark,
            driver_paid,
            expense_id,
            edit_expenses_status,
            pay_method_id,
            photo,
            photoList: [],
          });
        });
      });

      return {
        toll_ways: tollways_list,
        gas_refills: gas_refills_list,
        expenses: expenses_list,
      };
    },
  },
  methods: {
    connectSocket() {
      this.socketService.connect();
      this.socketService.joinRoom('booking');
      this.socketService.onBroadcast((message) => {
        if (message) {
          // this.init_bookings_update(this.activeKey);
          // this.fetchBookingStatus();
          this.fetchBookingCarStatus();

        }
      }, 'booking');
    },
    
    async getCompanyId() {
      let data = await UserService.get_user_profile();
      return data.company_id;
    },
    async getFilteredTabs() {
      const status = this.booking_status_list;
      const customOrder = [15];

      let tabsToFilter;

      tabsToFilter = this.tabsProduct;

      let filteredTabs = tabsToFilter.map(tab => {
        if (tab.status === 15) {
            return { ...tab, title: "ตรวจสอบ" };
        }
        return tab;
    });      

       
      console.log("filteredTabs1", filteredTabs);

      return filteredTabs;
    },
    get_total_car(index) {
      return `${index + 1} / ${this.selected_booking.booking_cars
        .map(({ quantity }) => (quantity ? quantity : 0))
        .reduce((a, b) => a + b, 0)}`;
    },
    bookingCarStatusLogs(booking_cars) {
      return booking_cars.logs?.filter(
        ({ model_name }) => model_name === 'BookingCarStatus'
      ) || [];
    },
    get_company_id() {
      if (this.userBusinessId == 3 || this.userBusinessId == 2) {
        return true
      }
    },
    async upload_files(e, key, target, id) {
      if (target && !id) {
        await this.getBase64(e.file.originFileObj, key, target);
      } else if (id) {
        await this.getBase64(e.file.originFileObj, key, target, id);
      } else {
        await this.getBase64(e.file.originFileObj, key);
      }

      // console.log(`After upload_files:`, this.service);

      return `https://mocki.io/v1/a6c8d0ec-4de1-4a61-870c-4e4751fb8fda`;
    },

    async getBase64(file, key, target, id) {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64String = reader.result;

          if (target) {
            target[key] = base64String;
          } else if (id) {
            await SystemService.update_all(id.model, { data: { [key]: base64String } }, id.id);
          } else {
            this.service[key] = base64String;
          }
        };
      } catch (err) {
        console.error("เกิดข้อผิดพลาดในการแปลงไฟล์เป็น Base64:", err);
      }
    },
    async getBase64(file, key) {
      if (key) {
        const vue = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          vue.model_form[key] = reader.result
          vue.model_form[`${key}_file_name`] = file.name
        };
      } else {
        return await new Promise((resolve, reject) => {
          if (file) {
            if (typeof file == 'string') {
              resolve(file)
            } else {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            }
          } else {
            reject('error')
          }
        });
      }
    },
    async getBase64Image(e, file) {
      e.stopPropagation();

      if (file.url) {
        const result = await this.getBase64ImageFromURL(file.url);

        // download
        let a = document.createElement('a');
        a.href = result;
        a.download = 'image.png';
        a.click();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
          const base64String = reader.result;
          let a = document.createElement('a');
          a.href = base64String;
          a.download = 'image.png';
          a.click();
        };
      }
    },
    async remove_uploaded_file({ file, index, uid }) {
      if (uid) {
        const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
        if (fileIndex !== -1) {
          this.gas.photo_2_show.splice(fileIndex, 1);
        }
      } else if (index !== undefined) {
        this.gas.bill_photo_show.splice(index, 1);
      } else {
        const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
        if (fileIndex !== -1) {
          this.gas.photo_2_show.splice(fileIndex, 1);
        }
      }
    },
    handleClickDeletePhoto(e, key, model_name, index = null, sub_index = null) {

      e.stopPropagation(); // ป้องกันการกระทำอื่นๆ ที่เกิดขึ้น

      if (model_name == 'gas_refills') {
        if (key == 'bill_photo_show') {
          this.gas.bill_photo_show = [];
        }
      }
    },
    upload_files(e, key, model_name = null, index = null, sub_index = null) {
      if (e.file.status != 'remove') {
        this.getBase64(e.file.originFileObj, key)
        if (model_name == 'gas_refills') {
          if (key == 'bill_photo_show') {
            //this.create_booking.booking_cars[index].gas_refills[sub_index].bill_photo = this.model_form.bill_photo_show
            this.bill_photo_show_source = this.model_form.bill_photo_show
          }
        } else if (model_name == 'toll_ways') {
          if (key == 'photo_1_show') {
            // this.assign_car_form.toll_ways[index].photo_1 = this.model_form.photo_1_show
            this.create_toll_way.photo_1 = this.model_form.photo_1_show
          }
        } else if (model_name == 'create_expense') {
          if (key == 'photo') {
            this.create_expense.photo = this.model_form.photo
          }
        }
        return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
      }
    },
    showPreview(e, fileUrl) {
      e.stopPropagation();
      this.previewImage = fileUrl;
      this.previewVisible = true;
    },
    getUploadedUrl(file) {
      // ตรวจสอบว่าการอัปโหลดสำเร็จและมี URL หรือไม่
      if (!Array.isArray(file)) {
        return file
      }
      if (file.response && file.response.url) {
        return file.response.url;
      }
      return file.url || URL.createObjectURL(file.originFileObj);
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
      // window.open(this.previewImage);
    },
    handleIconClick(action, payload, event) {
      event.stopPropagation();
      if (action === 'preview') {
        this.showPreview(event, payload);
      } else if (action === 'delete') {
        // this.model_form[payload] = '';
        this.fileList = [];
      }
    },
    firstStartStop(stops) {
      if (!stops || stops.length === 0) return [];
      const filteredStops = stops.filter(stop => stop.stop_type_id == 1);
      filteredStops.sort((a, b) => new Date(a.expected_timestamp) - new Date(b.expected_timestamp));
      return filteredStops.length > 0 ? [filteredStops[0]] : [];
    },
    endStartStop(stops) {
      if (!stops || stops.length === 0) return [];
      const filteredStops = stops.filter(stop => stop.stop_type_id == 6);
      filteredStops.sort((a, b) => new Date(a.expected_timestamp) - new Date(b.expected_timestamp));
      return filteredStops.length > 0 ? [filteredStops[0]] : [];
    },
    getCarNoByCarId(car_id) {
      const record = this.filtered_table(this.booking_list);
      let carNo = '';
      record.forEach((item) => {
        item.cars.forEach(car => {
          if (car.id === car_id) {
            carNo = car.car_no;
          }
        });
      });
      return carNo;
    },
    getPlateNoByCarId(car_id) {
      const record = this.filtered_table(this.booking_list);
      let plateNo = '';
      record.forEach((item) => {
        item.cars.forEach(car => {
          if (car.id === car_id) {
            plateNo = car.plate_no;
          }
        });
      });
      return plateNo;
    },
    getFirstNameByDrierId(driver_id) {
      const record = this.filtered_table(this.booking_list);
      let firstname = '';
      record.forEach((item) => {
        item.drivers.forEach(driver => {
          if (driver.id === driver_id) {
            firstname = driver.firstname;
          }
        });
      });
      return firstname;
    },
    getLastNameByDrierId(driver_id) {
      const record = this.filtered_table(this.booking_list);
      let lastname = '';
      record.forEach((item) => {
        item.drivers.forEach(driver => {
          if (driver.id === driver_id) {
            lastname = driver.lastname;
          }
        });
      });
      return lastname;
    },
    getfullnameBycompany(companyId) {
      const record = this.filtered_table(this.booking_list);
      let fullname = '';
      record.forEach((item) => {
        if (item.company && item.company.id === companyId) {
          fullname = item.company.fullname || '-'; // ถ้าไม่มี fullname ให้ใช้ '-'
        }
      });

      return fullname;
    },

    handledateTimeChange() {
      this.filtered_table(this.booking_list);
    },
    // bookingupdate method
    openBookingUpdatePopup(record) {
      this.BookingUpdatedVisible = true;
      this.bookingUpdatedData = record.booking_updated;
    },
    closeBookingUpdatePopup() {
      this.BookingUpdatedVisible = false;
    },
   
    handleCloseCarExpenses() {
      this.total_cars_expense_modal = false;
      this.showWarningExpenses = false;
      this.showWarningGasRefills = false;
      this.showWarningTollWays = false;
    },
   sum_expense() {
      const plateNo = this.search_plate_no;
      console.log("plateno", plateNo);

      if (!plateNo || !this.selected_booking) {
        return 0;
      }

      const bookingCars = this.filteredBookingCars.filter(({ car }) => car.plate_no === plateNo);

      // ค่าใช้จ่ายพื้นฐาน (expenses)
      let expensesSum = bookingCars
        .map(({ expenses }) => 
          Array.isArray(expenses)
            ? expenses.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_payment) : 0), 0)
            : 0
        )
        .reduce((a, b) => a + b, 0);

      // ถ้าเป็น "บริษัทรถร่วม" → ใช้ "ราคารถ + ค่าแรงงาน + expenses"
      if (this.selected_booking?.company_3rd === 'บริษัทรถร่วม') {
        const priceCar = Number(this.selected_booking?.price_car) || 0;
        const liftThings = Number(this.selected_booking?.lift_things) || 0;
        return priceCar + liftThings + expensesSum;
      }

      // ถ้าไม่ใช่ "บริษัทรถร่วม" → ใช้ "toll_ways + gas_refills + expenses + carPayment + driverSalary"
      let otherCosts = bookingCars
        .map(({ toll_ways, gas_refills }) => {
          const tollWaysSum = Array.isArray(toll_ways)
            ? toll_ways.reduce((a, b) => a + (b.granted === 1 ? Number(b.paid_amount) : 0), 0)
            : 0;
          const gasRefillsSum = Array.isArray(gas_refills)
            ? gas_refills.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_amount) : 0), 0)
            : 0;
          
          return tollWaysSum + gasRefillsSum;
        })
        .reduce((a, b) => a + b, 0);

      // เพิ่มค่า this.carPayment และ this.driverSalary เฉพาะกรณีที่ไม่ใช่ "บริษัทรถร่วม"
      const driverCost = Number(this.carPayment) || 0;
      const rentalPrice = Number(this.driverSalary) || 0;

      return otherCosts + expensesSum + driverCost + rentalPrice;
    },

    sum_car_expense(record, booking_cars) {
      if (!record || !booking_cars) {
        console.warn("sum_car_expense() received null record or booking_cars:", record, booking_cars);
        return "0.00";
      }
      let expensesSum = booking_cars
        .map(({ expenses }) => 
          Array.isArray(expenses)
            ? expenses.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_payment) : 0), 0)
            : 0
        )
        .reduce((a, b) => a + b, 0);
      if (record?.company_3rd === 'บริษัทรถร่วม') {
        const priceCar = Number(record?.price_car) || 0;
        const liftThings = Number(record?.lift_things) || 0;
        return (priceCar + liftThings + expensesSum).toFixed(2);
      }
      let otherCosts = booking_cars
        .map(({ toll_ways, gas_refills }) => {
          const tollWaysSum = Array.isArray(toll_ways)
            ? toll_ways.reduce((a, b) => a + (b.granted === 1 ? Number(b.paid_amount || 0) : 0), 0)
            : 0;
          const gasRefillsSum = Array.isArray(gas_refills)
            ? gas_refills.reduce((a, b) => a + (b.granted === 1 ? Number(b.total_amount || 0) : 0), 0)
            : 0;
          return tollWaysSum + gasRefillsSum;
        })
        .reduce((a, b) => a + b, 0);
      const driverCost = booking_cars
        .map(({ driver }) => driver ? Number(driver.salary) / 26 : 0)
        .reduce((a, b) => a + b, 0);
      const rentalPrice = booking_cars
        .map(({ car }) => car ? Number(car.car_payment) / 26 : 0)
        .reduce((a, b) => a + b, 0);
      return (otherCosts + expensesSum + driverCost + rentalPrice).toFixed(2);
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination.onpage = pagination.current;
      this.pagination.perPage = pagination.pageSize;

      this.init_bookings();
    },
    compare_time(time, array) {
      const lastupdate = array.map(({ created_at }) => created_at);
      //if time is greater than any of lastupdate and no any of lastupdate is greater than time
      return array
        ? lastupdate.some((lastupdate) => dayjs(time).isAfter(lastupdate))
        : false;
    },
    geBookingCarMax(car_types) {
      return car_types
        .map(({ quantity }) => (quantity ? quantity : 0))
        .reduce((a, b) => a + b, 0);
    },
    getCarStatusMax(car_status_job) {
      return car_status_job
        .map(({ quantity }) => (quantity ? quantity : 0))
        .reduce((a, b) => a + b, 0);
    },
    async refresh_total_cars_expense_modal() {
      message.loading({
        content: "กำลังโหลดข้อมูลใหม่...",
        key: "refresh_total_cars_expense_modal",
      });
      // this.total_cars_expense_modal = false
      await this.init_bookings();
      this.selected_booking = this.booking_list.find(
        (booking) => booking.id === this.selected_booking.id
      );
      if (this.bookingCarExpenseModal) {
        this.bookingCarExpenseData = this.selected_booking;
        this.bookingCarExpenseData.booking_cars = this.selected_booking.booking_cars.filter((book) => book.id == this.bookingCarExpenseData.booking_cars[0].id)
      }
      // this.total_cars_expense_modal = true
      message.success({
        content: "โหลดข้อมูลเรียบร้อยแล้ว",
        key: "refresh_total_cars_expense_modal",
      });
    },
    async update_granted({ id, approve_remark }, type, value, list) {
      try {
        const result = {
          approve_remark,
          granted: value,
        };
        if (value == 2) {
          await Swal.fire({
            title: "กรุณาระบุเหตุผลที่ไม่อนุมัติ",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              return value;
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(({ isConfirmed, value }) => {
            // console.log({ isConfirmed, value, type });
            if (value && isConfirmed) {
              if (result.approve_remark?.includes("ไม่อนุมัติ")) {
                result.approve_remark = result.approve_remark.split(" (ไม่อนุมัติ")[0];
                result.approve_remark = `${result.approve_remark} (ไม่อนุมัติ: ${value})`;
              } else {
                result.approve_remark = `${result.approve_remark ? result.approve_remark : ""
                  } (ไม่อนุมัติ: ${value})`;
              }

              list.forEach((item) => {
                if (item.id === id) {
                  item.approve_remark = result.approve_remark;
                }
              });
            } else {
              list.forEach((item) => {
                if (item.id === id) {
                  item.granted = null;
                }
              });
              throw "ยกเลิกการอัพเดท";
            }
          });
        }

        switch (type) {
          case "toll_ways":
            SystemService.update_all("TollWay", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "gas_refills":
            SystemService.update_all("GasRefill", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "expenses":
            SystemService.update_all("BookingCarExpense", { data: result }, id).then(
              () => {
                this.refresh_total_cars_expense_modal();
                message.success("อัพเดทข้อมูลสำเร็จ");
              }
            );
            break;
        }
        let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.bookingUpdated["total_cars_expense"] = currentTime
        BookingService.booking_update_timestamp(this.bookingUpdated)
      } catch (err) {
        console.log(err);
      }
    },
    submit_create_expense() {
      const e = { ...this.create_expense };
      const { id } = e;
      if (this.bookingCarExpenseModal) {
        e.booking_car_id = this.selected_booking.booking_cars[0]?.id
      }
      delete e.datetime;
      delete e.plate_no;
      delete e.photoList;
      delete e.name
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)

      if (id) {
        SystemService.update_all("BookingCarExpense", { data: e }, id).then(() => {
          SystemService.update_all("BookingCarExpense", { data: { edit_expenses_status: 1 } }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_create_expense = false;
            this.create_expense = {};
            this.refresh_total_cars_expense_modal();
            if (e.edit_expenses_status == 1) {
              this.newExpense = true;
            }
            this.showWarningExpenses = true;
          });
        });
      } else {
        SystemService.create_all("BookingCarExpense", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_create_expense = false;

          this.create_expense = {};
          this.refresh_total_cars_expense_modal();
        });
      }
      // this.total_cars_expense_modal = false;
      setTimeout(() => {
        if (this.bookingCarExpenseModal) {
          this.show_total_cars_expense(this.bookingCarExpenseData, this.bookingCarExpenseData.id)
        } else {
          this.total_cars_expense_modal = true;
        }
      }, 1000);
    },
    submit_create_toll_way() {
      const e = { ...this.create_toll_way };
      if (this.bookingCarExpenseModal) {
        e.booking_car_id = this.selected_booking.booking_cars[0]?.id
      }
      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      delete e.datetime;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)

      if (id) {
        SystemService.update_all("TollWay", { data: e }, id).then(() => {
          SystemService.update_all("TollWay", { data: { edit_toll_ways_status: 1 } }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_create_toll_way = false;
            this.create_toll_way = {};
            this.model_form.photo_1_show = null
            this.refresh_total_cars_expense_modal();
            if (e.edit_toll_ways_status == 1) {
              this.newTollWay = true;
            }
            this.showWarningTollWays = true;

          });
        });
      } else {
        SystemService.create_all("TollWay", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_create_toll_way = false;
          this.create_toll_way = {};
          this.model_form.photo_1_show = null
          this.refresh_total_cars_expense_modal();
        });
      }
      // this.total_cars_expense_modal = false;
      setTimeout(() => {
        if (this.bookingCarExpenseModal) {
          this.show_total_cars_expense(this.bookingCarExpenseData, this.bookingCarExpenseData.id)
        } else {
          this.total_cars_expense_modal = true;
        }
      }, 1000);
    },
    submit_create_gas_refill() {
      this.create_gas_refill.bill_photo = this.model_form.bill_photo_show
      const e = { ...this.create_gas_refill };
      if (this.bookingCarExpenseModal) {
        e.booking_car_id = this.selected_booking.booking_cars[0]?.id
      }
      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      delete e.datetime;
      delete e.use_gas_station;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      if (id) {
        SystemService.update_all("GasRefill", { data: e }, id).then(() => {
          SystemService.update_all("GasRefill", { data: { edit_gas_status: 1 } }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_gas_refill_drawer = false;
            this.create_gas_refill = {};
            this.refresh_total_cars_expense_modal();
            if (e.edit_gas_status == 1) {
              this.newGas = true;
            }
            this.showWarningGasRefills = true;
          });
        });
      } else {
        SystemService.create_all("GasRefill", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_gas_refill_drawer = false;
          this.create_gas_refill = {};
          this.refresh_total_cars_expense_modal();
        });
      }
      // this.total_cars_expense_modal = false;
      setTimeout(() => {
        if (this.bookingCarExpenseModal) {
          this.show_total_cars_expense(this.bookingCarExpenseData, this.bookingCarExpenseData.id)
        } else {
          this.total_cars_expense_modal = true;
        }
      }, 1000);
    },
    edit_expense_log(data, type) {
      switch (type) {
        case "toll_ways":
          this.show_create_toll_way = true;
          this.create_toll_way = data;
          this.create_toll_way.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
        case "gas_refills":
          for (const [key, value] of Object.entries(data)) {
            if (key == 'bill_photo' && value != null) {
              this.bill_photo_show_source = value
            } else if (key == 'bill_photo' && value == null) {
              this.bill_photo_show_source = ''
            }
          }
          this.show_gas_refill_drawer = true;
          this.create_gas_refill = data;
          this.create_gas_refill.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          this.create_gas_refill.use_gas_station = data.gas_station_id != null;
          break;
        case "expenses":
          this.show_create_expense = true;
          this.create_expense = data;
          this.create_expense.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
      }
    },
    remove_expense_log(model_id, type) {
      const old_active_key = this.total_car_expenses_collapse;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      switch (type) {
        case "toll_ways":
          SystemService.delete_all("TollWay", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.toll_ways.splice(
              this.total_car_expenses.toll_ways.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.booking_cars.forEach((car) => {
              car.toll_ways = car.toll_ways?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "gas_refills":
          SystemService.delete_all("GasRefill", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.gas_refills.splice(
              this.total_car_expenses.gas_refills.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.booking_cars.forEach((car) => {
              car.gas_refills = car.gas_refills?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "expenses":
          SystemService.delete_all("BookingCarExpense", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.expenses.splice(
              this.total_car_expenses.expenses.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.booking_cars.forEach((car) => {
              car.expenses = car.expenses?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
      }
      this.refresh_total_cars_expense_modal();
    },
    async on_expense_paided() {
      SystemService.update_all(
        "Booking",
        { data: { paid_expense: true } },
        this.selected_booking.id
      ).then((res) => {
        this.total_cars_expense_modal = false;
        this.init_bookings();
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
    },
    render_date(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm");
    },

    show_driver_cost(booking_cars) {
      return booking_cars
        .map(({ driver_cost }) => {
          const driverCost = Number(driver_cost) || 0;
          return driverCost
        })
        .reduce((a, b) => a + b, 0).toFixed(0);
    },
    allIsApproved(booking_cars) {
      if (booking_cars.every(({ toll_ways, gas_refills, expenses, driver_cost, rental_price }) => {
        return (
          (!toll_ways || toll_ways.length === 0) &&
          (!gas_refills || gas_refills.length === 0) &&
          (!expenses || expenses.length === 0) &&
          (driver_cost === "0.00") &&
          (rental_price == null)
        );
      })) {
        SystemService.update_all("Booking", { data: { all_approved: 1 } }, this.selected_booking?.id);
        return false;
      } else if (
        booking_cars.every(({ toll_ways, gas_refills, expenses }) =>
          (!toll_ways || toll_ways.length === 0) &&
          (!gas_refills || gas_refills.length === 0) &&
          (!expenses || expenses.length === 0)
        ) &&
        booking_cars.some(({ driver_cost, rental_price }) =>
          driver_cost !== "0.00" || rental_price != null
        )
      ) {
        SystemService.update_all("Booking", { data: { all_approved: 1 } }, this.selected_booking?.id);
        return true;
      } else if (booking_cars.some(({ toll_ways, gas_refills, expenses }) => {
        const tollwaysNotGranted = toll_ways?.some(({ granted }) => granted == null);
        const gasRefillsNotGranted = gas_refills?.some(({ granted }) => granted == null);
        const expensesNotGranted = expenses?.some(({ granted }) => granted == null);

        return tollwaysNotGranted || gasRefillsNotGranted || expensesNotGranted;
      })) {
        SystemService.update_all("Booking", { data: { all_approved: 2 } }, this.selected_booking?.id);
        return false;
      } else {
        SystemService.update_all("Booking", { data: { all_approved: 1 } }, this.selected_booking?.id);
        return true;
      }
    },

    show_billings(bills_array) {
      return bills_array.map(({ billing_type }) => billing_type).join(", ");
    },
    async duplicate_booking(id) {
      message.loading({ content: "กำลังโหลด...", key: "loading" });
      const data = await BookingService.duplicate_booking(id);
      await this.init_bookings();
      message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    async remove_booking_car(id) {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "คุณต้องการลบงานนี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SystemService.delete_all("BookingCar", id);
          await this.init_bookings(this.activeKey);
          Swal.fire("ลบเรียบร้อย", "ลบงานเรียบร้อย", "success");
        }
      });
    },
    save_car_expenses() { },
    show_total_cars_expense(bookingCarData, id ) {
      console.log('bookingCarData', bookingCarData)
      console.log('id', id)
      this.total_cars_expense_modal = true;
      this.selected_booking = this.booking_list.find((booking) => booking.id === id);
      this.bookingUpdated["booking_no"] = this.selected_booking.booking_no;
      if (bookingCarData != null) {
        this.bookingCarExpenseModal = true;
        this.selected_booking = bookingCarData;
        this.bookingCarExpenseData = bookingCarData;
        this.search_plate_no = this.selected_booking.booking_cars[0]?.car?.plate_no || null;
      } else {
        this.bookingCarExpenseModal = false;
      }
      this.selected_booking.booking_cars.forEach((car) => {
        car.toll_ways.map((tollway) => {
          if (tollway.edit_toll_ways_status == 1) {
            this.showWarningTollWays = true;
          }
        });
        car.gas_refills.map((gas) => {
          if (gas.edit_gas_status == 1) {
            this.showWarningGasRefills = true;
          }
        });
        car.expenses.map((expense) => {
          if (expense.edit_expenses_status == 1) {
            this.showWarningExpenses = true;
          }
        });
      });

      console.log("company_3rd" ,bookingCarData.company_3rd)
       
    },
    activate_job(record) {
      const { id, booking_cars } = record;
      const currentTimestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const hasNoDriverId = booking_cars.some(car => !car.driver_id);
      if (hasNoDriverId) {
        this.showModalActiveJob = true;
        return;
      }
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      SystemService.update_all("Booking", { data: { active_job: 1 } }, id).then((res) => {
        this.init_bookings();
        this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        booking_cars.map(({ id }) => {
          SystemService.update_all("BookingCar", { data: { active_car: true, status: 11, active_car_timestamp: currentTimestamp } }, id).then((res) => {
            this.init_bookings();
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
          });
        });
      })
    },

    on_booking_finished(id, bookingCarId) {
      Swal.fire({
        title: "คุณต้องการตรวจสอบรายการนี้ใช่หรือไม่",
        text: "คุณจะไม่สามารถแก้ไขรายการนี้ได้หากคุณตรวจสอบแล้ว!?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
          try {

            // console.log("Booking ID:", id);
            // console.log("Booking:", bookingCarId);
            await SystemService.update_all("Booking", { data: { finished: true  } }, id);
            await SystemService.update_all("BookingCar", { data: { status: 20 } }, bookingCarId);
              
            await this.init_bookings(this.activeKey);

            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "success" });
            Swal.fire("ตรวจสอบเรียบร้อย", "ตรวจสอบงานเรียบร้อย", "success");
          } catch (error) {
            this.$message.error({ content: "เกิดข้อผิดพลาดในการตรวจสอบ", key: "error" });
            console.error("Error updating booking:", error);
          }
        }
      });
    },
    on_booking_car_finished(id) {
      Swal.fire({
        title: "คุณต้องการอนุมัติรายการนี้ใช่หรือไม่",
        text: "คุณจะไม่สามารถแก้ไขรายการนี้ได้หากคุณอนุมัติแล้ว!?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
          try {
            await SystemService.update_all("BookingCar", { data: { approve_status: 1 } }, id);

            await this.init_bookings(this.activeKey);

            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "success" });
            Swal.fire("อนุมัติเรียบร้อย", "อนุมัติงานเรียบร้อย", "success");
          } catch (error) {
            this.$message.error({ content: "เกิดข้อผิดพลาดในการอนุมัติ", key: "error" });
            console.error("Error updating booking:", error);
          }
        }
      });
    },
        show_update_progress(id) {
        this.update_progress_modal = true;
        
        this.selected_booking = this.booking_list.find((booking) => booking.id === id);
          
        this.temp_status = this.selected_booking.status;
      },
    show_update_booking_car_status(id) {
      this.update_booking_car_status_modal = true;
      this.selected_booking = this.booking_list.find((booking) =>
        booking.booking_cars.some((car) => car.id === id)
      );
    },
    async update_progress() {
    if (!this.selected_booking) return;
    this.selected_booking.status = this.temp_status;

    const carIds = this.selected_booking.booking_cars.map(car => car.id);
    // console.log("Updating booking cars with IDs:", carIds);

    await SystemService.update_all("BookingCar", { data: { status: this.selected_booking.status } }, carIds)
      .then((res) => {
        this.update_progress_modal = false;
        this.init_bookings();
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
  },
    async update_booking_car_status() {
      const bookingCarsStatus = this.selected_booking.booking_cars[0].status;
      const bookingCarId = this.selected_booking.booking_cars[0].id;
      await SystemService.update_all("BookingCar", { data: { status: bookingCarsStatus } }, bookingCarId).then((res) => {
        this.update_booking_car_status_modal = false;
        this.init_bookings(this.activeKey);
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
    },
    add_to_billing() {
      SystemService.update_all(
        "Booking",
        { data: { billing_status: 1 } },
        this.selected_booking.id
      );
      Swal.fire("เพิ่มเรียบร้อย", "เพิ่มงานเรียบร้อย", "success");
    },
    show_time_line() {
      if (this.selected_booking) {
        this.show_timeline = true;
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกงาน",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async export_booking_gas_report() {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      const time_start = dayjs(this.export_excel.time_start).format("YYYY-MM-DD");
      const time_end = dayjs(this.export_excel.time_end).format("YYYY-MM-DD");
      const { headers, data } = await ReportService.booking_gas_report(time_start, time_end);
      exportXLSXFile(headers, data, `Booking-Gas-Report-${time_start}-${time_end}`);

      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    export_job_order() {
      if (this.selected_booking) {
        this.export_data = JSON.parse(JSON.stringify(this.selected_booking));
        if (this.selected_driver_id) {
          this.export_data.booking_cars = this.selected_booking.booking_cars?.filter(
            ({ driver_id }) => driver_id === this.selected_driver_id
          );
          setTimeout(() => {
            this.job_order = true;
          }, 100);
        } else {
          setTimeout(() => {
            this.job_order = true;
          }, 100);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกงาน",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async export_car_used() {
      const { headers, data } = await ReportService.car_used();
      exportXLSXFile(headers, data, `CarUsed-Report`);
    },
    async export_stops() {
      const { headers, data } = await ReportService.stops();
      exportXLSXFile(headers, data, `Trip-Report`);
    },
    async submit_monthly_report() {
      this.show_monthly_report = false;
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      const company_id = this.userCompanyId;
      const time_start = dayjs(this.monthly_report_date.datetime[0]).format("YYYY-MM-DD");
      const time_end = dayjs(this.monthly_report_date.datetime[1]).format("YYYY-MM-DD");
      const company_client = this.monthly_report_date.report_company;
      const company_car = this.monthly_report_date.report_company_car;
      const car_status = 20;
      if (this.selectedTypeReportClient == true) {
        this.selectedTypeReportClient = 'All'
      } else {
        this.selectedTypeReportClient = 'SelectedCompany'
      }
      if (this.selectedTypeReportCarCompany == true) {
        this.selectedTypeReportCarCompany = 'All'
      } else {
        this.selectedTypeReportCarCompany = 'SelectedCarCompany'
      }
      const typeReportClient = this.selectedTypeReportClient;
      const typeReportCarCompany = this.selectedTypeReportCarCompany;
      const { data } = await SystemService.CheckBill_report(company_id, company_client,company_car,car_status, time_start, time_end, typeReportClient,typeReportCarCompany);
      
      if (!data || data.length === 0) {
      this.$message.warning("ไม่มีข้อมูลที่สามารถสร้างรายงานได้");
      return;
    }
     
      const headersColumn = [
        { header: "Booking no.", key: "bookingNo" },
        { header: "วันที่รับสินค้า", key: "Pickup_Date" }, 
        { header: "เวลารับสินค้า", key: "pickup_time" },
        { header: "ระยะเวลาวิ่งส่งงาน", key: "Running_time" },
        { header: "วันที่ส่งสินค้า", key: "delivery_Date" }, // timeEnd
        { header: "เวลาส่งสินค้า", key: "delivery_time" },
        { header: "ลูกค้า", key: "companyName" },
        { header: "ชื่อโปรเจค", key: "project_name" },
        { header: "ประเภทโปรเจค", key: "project_type_id" },
        { header: "เลขที่จ๊อบสั่งงานของลูกค้า (ถ้ามี)", key: "Customer_order_no" },
        { header: "ประเภทของการขนส่ง", key: "bookingType" },
        { header: "ต้นทาง", key: "origin" },
        { header: "ปลายทาง", key: "destination" },
        { header: "ระยะทางต้นทาง-ปลายทาง", key: "trip_distance" },
        { header: "ชนิดรถ", key: "truck_type" },
        { header: "ทะเบียนรถ", key: "plate_no" },
        { header: "ทะเบียนหาง (ถ้ามี)", key: "dock_plate" },
        { header: "ชื่อรถซัพ", key: "supplier" },
        { header: "ชื่อคนขับ", key: "driverName" },
        { header: "เบอร์โทร", key: "contactPhoneNo" },
        { header: "สแตนบายจริง", key: "Actual_standby" },
        { header: "เริ่มออกจากต้นทาง", key: "Out_for_delivery" },
        { header: "ถึงปลายทาง", key: "delivered" },
        { header: "ชั่วโมงวิ่งจริง", key: "Actual_runtime" },
        { header: "ชั่วโมงรถเลท", key: "Late_record" },
        { header: "เวลาจบงานจริง", key: "Job_Completed_time" },
        { header: "เลขที่ใบงาน (ได้จากต้นทาง)", key: "D_no" },
        { header: "ราคาวางบิลลูกค้า", key: "selling_per_trip" },
        { header: "ราคาวางบิลอื่นๆ (ถ้ามี)", key: "additional_selling" },
        { header: "ชาร์จสินค้ากลับ", key: "return_cost" },
        { header: "ชาร์จเพิ่มเสียเวลา", key: "waiting_time_fee" },
        { header: "ชาร์จค่าค้างคืน", key: "overnight_charge" },
        { header: "ยกเลิกการใช้รถ", key: "cancellation_fee" },
        { header: "ราคาวางบิลรวม", key: "total_selling" },
        { header: "ราคาจ่ายรถ", key: "Cost_per_trip" },
        { header: "ชาร์จค่าน้ำมัน", key: "gasAmount" },
        { header: "ค่าทางด่วน", key: "tollWaysAmount" },
        { header: "ค่าแรงงาน", key: "Labor_Charge" },
        { header: "ค่าจ้างคนขับ", key: "Driver_charge" },
        { header: "ค่าใช้จ่ายอื่นๆ (ถ้ามี)", key: "expense" },
        { header: "ราคาจ่ายรวม", key: "Total_cost" },
        { header: "หมายเหตุ", key: "Remark" },
        { header: "จองโดยใคร", key: "contactName" },
        { header: "วันที่ เวลาจอง", key: "createdate" },

      ];

      const workBook = xlsx.utils.book_new();
      const workSheet = xlsx.utils.json_to_sheet(data, { header: headersColumn.map(h => h.key) });

      xlsx.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });

      let fillColor = { rgb: "FFF4CC" };
        headersColumn.forEach((header, colIndex) => {
            const cellAddress = xlsx.utils.encode_cell({ c: colIndex, r: 0 });
            if (!workSheet[cellAddress]) workSheet[cellAddress] = { v: header.header };

            workSheet[cellAddress].s = {
                font: { bold: true },  // ตัวหนา
                fill: { fgColor: fillColor },  // สีพื้นหลัง
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" }
                },
                alignment: { horizontal: "center", vertical: "middle" } // กึ่งกลาง
            };
        });

        const lastRow = data.length + 1  ;
        for (let row = 1; row <= lastRow; row++) {
            for (let col = 0; col < headersColumn.length; col++) {
                const cellAddress = xlsx.utils.encode_cell({ c: col, r: row });
                if (!workSheet[cellAddress]) {
                    workSheet[cellAddress] = { v: "" }; // กำหนดค่าให้เซลล์ว่าง
                }
                // กำหนดเส้นกรอบทุกเซลล์
                workSheet[cellAddress].s = {
                    border: {
                        top: { style: "thin" },
                        bottom: { style: "thin" },
                        left: { style: "thin" },
                        right: { style: "thin" }
                    },
                    alignment: { horizontal: "center", vertical: "middle" }
                };

                for (let row = 2; row <= lastRow; row++) {
                  const borderStyle = {
                      border: {
                          top: { style: "thin" },
                          bottom: { style: "thin" },
                          left: { style: "thin" },
                          right: { style: "thin" }
                      },
                      alignment: { horizontal: "center", vertical: "middle" }
                  };

                  workSheet[`X${row}`] = {
                      f: `TEXT(IF(W${row}<V${row},W${row}+1-V${row},W${row}-V${row}),"hh:mm")`,
                      s: borderStyle
                  };

                  workSheet[`Y${row}`] = {
                      f: `IF(X${row} - (D${row}/24) >= 0, TEXT(X${row} - (D${row}/24), "hh:mm"), "-" & TEXT(ABS(X${row} - (D${row}/24)), "hh:mm"))`,
                      s: borderStyle
                  };

                  workSheet[`AH${row}`] = {
                      f: `SUM(AB${row}, AC${row}, AD${row}, AE${row}, AF${row}, AG${row})`,
                      s: borderStyle
                  };

                  workSheet[`AO${row}`] = {
                      f: `SUM(AI${row}:AN${row})`,
                      s: borderStyle
                  };
              }

            }
        }


        workSheet['!cols'] = headersColumn.map(header => ({
            wch: Math.max(...data.map(row => (row[header.key] ? row[header.key].toString().length : 10)), header.header.length)
        }));

        workSheet['!cols'][14] = { wch: 20 }; // คอลัมน์ O เป็นคอลัมน์ที่ 15 (index เริ่มที่ 0)
        workSheet['!cols'][11] = { wch: 20 }; 
        workSheet['!cols'][19] = { wch: 20 }; 


      xlsx.utils.book_append_sheet(workBook, workSheet, "Report");
      xlsx.writeFile(workBook, "รายงานการจองจัดรถ.xlsx");
      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },


    count_by_status(status) {
    if (!this.bookingStatusCarData || !Array.isArray(this.bookingStatusCarData)) {
        console.warn("bookingStatusData ไม่ใช่อาร์เรย์หรือเป็น undefined/null");
        return 0;
    }
    if (status === 15) {
        const count15Item = this.bookingStatusCarData.find(item => item.status === 15);
        const count20Item = this.bookingStatusCarData.find(item => item.status === 20);
        const count15 = count15Item ? count15Item.count : 0;
        const count20 = count20Item ? count20Item.count : 0;
        return count15 + count20;
    }

    const found = this.bookingStatusData.find(item => item.status === status);
    return found ? found.count : 0;
},

    clear_search() {
      this.search_input = null;
      this.datetime = null;
      this.search_booking_status = null;
      this.status = null;
      this.sheet_status = null 
      this.search_origin = null;
      this.search_destination = null;
      this.search_client_company = null;
      this.search_car_company = null;
      this.search_operation = null;
      this.init_bookings();
    },
    select_row(selectedRowKeys) {
      this.selected_booking = selectedRowKeys;
    },
    select_filter(key, status) {
      console.log({ key, status });
    },
    filtered_table(array) {
  // console.log('ข้อมูลเริ่มต้น:', array); // Debug ข้อมูลก่อนกรอง

  if (!Array.isArray(array)) {
    // console.error(" ข้อมูลที่ส่งมาไม่ใช่อาร์เรย์:", array);
    return [];
  }

  const filteredArray = array
    ?.filter(({ booking_no, start_location_name, end_location_name, client_name }) => {
      if (!this.search_input) return true; // ถ้าไม่มี search_input ไม่ต้องกรอง

      const searchText = this.search_input.toLowerCase();
      return [start_location_name, end_location_name, booking_no, client_name]
        .some(field => field?.toLowerCase()?.includes(searchText)); // ใช้ toLowerCase() เพื่อไม่ให้แยกพิมพ์เล็ก-ใหญ่
    })
    ?.filter((item) => {
      if (!this.datetime || this.datetime.length < 2) return true; // ถ้าไม่มี datetime ไม่ต้องกรอง

      const search_time_start = dayjs(this.datetime[0]).startOf('day');
      const search_time_end = dayjs(this.datetime[1]).endOf('day');

      const item_time_start = dayjs(item.time_start);
      const item_time_end = dayjs(item.time_end);

      return (
        (item_time_start.isValid() && item_time_start.isSameOrAfter(search_time_start) && item_time_start.isSameOrBefore(search_time_end)) ||
        (item_time_end.isValid() && item_time_end.isSameOrAfter(search_time_start) && item_time_end.isSameOrBefore(search_time_end))
      );
    })
    ?.filter(({ status }) => {
      if (this.activeKey === null || this.activeKey === undefined) return true; // ถ้า activeKey เป็น null หรือ undefined ไม่ต้องกรอง

      if (this.activeKey == 15) {
        return [15, 20].includes(status); // รวม status 20 กับ 21
      }
      return status == this.activeKey;
    })
    ?.filter(({ sheet_status }) => {
            // console.log("🔍 ตรวจสอบค่า sheet_status:", sheet_status, " เทียบกับ ", this.sheet_status);
            if (this.sheet_status === null || this.sheet_status === undefined) {
              // console.log("✅ ไม่มีการเลือก sheet_status → ไม่กรอง");
              return true;
            }
            const normalizedSheetStatus = sheet_status === 1 ? 0 : sheet_status;
            const normalizedSelected = this.sheet_status === 1 ? 0 : this.sheet_status;
            const isMatch = normalizedSheetStatus == normalizedSelected;
            // console.log(`🔍 เปรียบเทียบ: ${normalizedSheetStatus} == ${normalizedSelected} → ${isMatch ? '✅ ผ่าน' : '❌ ไม่ผ่าน'}`);
            return isMatch;
        })


    ?.filter(({ booking_no, status, time_start, time_end, origin, destination, data_text_destination, client_id, operation_id,car_company_id }) => {
          const searchOrigin = !this.search_origin || (origin && origin.toLowerCase().includes(this.search_origin.toLowerCase()));
          // console.log("searchOrigin",searchOrigin)
          const destinationArray = data_text_destination ? JSON.parse(data_text_destination) : [];

          const searchDestination = !this.search_destination || destinationArray.some(dest => dest.toLowerCase().includes(this.search_destination.toLowerCase()));

          const searchClientCompany = !this.search_client_company || (client_id && client_id === this.search_client_company);
          // console.log("searchClientCompany",searchClientCompany)
          const searchClient = !this.search_car_company || (car_company_id && car_company_id === this.search_car_company);
          console.log("searchClient",searchClient)
          const searchOperation = !this.search_operation || (operation_id && operation_id === this.search_operation);
          // console.log("searchOperation",searchOperation)
          return   searchOrigin && searchDestination && searchClientCompany && searchClient && searchOperation;
      })

    
    .sort((a, b) => {
      const current_date = dayjs();
      const timeA = dayjs(a.time_start).isValid() ? dayjs(a.time_start).diff(current_date) : Number.MAX_SAFE_INTEGER;
      const timeB = dayjs(b.time_start).isValid() ? dayjs(b.time_start).diff(current_date) : Number.MAX_SAFE_INTEGER;
      
      return Math.abs(timeA) - Math.abs(timeB);
    });

  // console.log('ข้อมูลหลังกรอง:', filteredArray); // Debug ข้อมูลหลังกรอง

  return filteredArray;
},  
countDays(time_start, time_end) {
      const start = dayjs(time_start);
      const end = dayjs(time_end);
      return end.diff(start, 'day');
    },


    render_time(time) {
      return dayjs(time).format("DD/MM/YYYY HH:mm");
    },
    render_datetime(date,time) {
      const datetime = `${date.slice(0,10)} ${time.slice(11,16)}`
      return dayjs(datetime).format("DD/MM/YYYY HH:mm");
    },
    checkData(data) {
      // console.log("check", data)
    },
    async go_to_edit_quotation(id) {
      // console.log("id", id)
      this.$router.push(`/app/sheet/${id}?checkBill=true`);
    },
    init_toll_way() {
      this.show_create_toll_way = true;
      this.create_toll_way = {};
    },
    async init_bookings(activeKey) {
        this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
        let datas = null
        if(this.datetime){
          let time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD")
          let time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD")
            datas = await SystemService.get_CheckBill(
              this.pagination.onpage,
              this.pagination.perPage,
              {
              time_start: time_start,
              time_end: time_end,
              }
             
            );
          } else {
            datas = await SystemService.get_CheckBill(
              this.pagination.onpage,
              this.pagination.perPage,
         
            );
          }

          const [client_company_list] = await Promise.all([SystemService.get_all("Client")]);
          this.client_company_list = client_company_list
          this.car_company_list = await SystemService.get_all("CarCompany");
          this.operation_list = await OperationService.getOperationByCompany()

  
        const combinedData = {
          data: [...(datas ? datas.data : [])],
          total: (datas ? datas.total : 0),
          page: this.pagination.onpage,
          perPage: this.pagination.perPage,
        };
  
        // Calculate the actual total based on returned data
        const actualTotal = (this.pagination.onpage - 1) * this.pagination.perPage + combinedData.data.length;
  
        // Adjust the total count based on the actual data length
        if (combinedData.data.length < this.pagination.perPage) {
          combinedData.total = actualTotal;
        }
  
        this.pagination = {
          total: combinedData.total,
          onpage: combinedData.page,
          perPage: combinedData.perPage,
        };
        this.booking_list = combinedData.data;
        this.booking_list = this.booking_list?.sort(
          (a, b) => dayjs(a.time_start).unix() - dayjs(b.time_start).unix()
        );
        this.require_rollcall =
          JSON.parse(localStorage.getItem("companyData")).require_rollcall == 1;
        this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
      },
    // async init_bookings_update(activeKey = this.activeKey) {
    //   this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
    //   let datas = null;

    //   if (this.datetime) {
    //     let time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD");
    //     let time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD");
    //     datas = await SystemService.get_CheckBill()
    //   } else {
    //     datas = await SystemService.get_CheckBill()
    //   }

    //   this.booking_list = datas.data?.filter(({ status }) => status === activeKey);
    //   this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    // },

    render_des(record) {
        if (record.data_text_destination && record.data_text_destination !== "null") {
          try {
            const response = JSON.parse(record.data_text_destination);
            return response.length ? response.join(', ') : '-';
          } catch (e) {
            console.error("Error parsing data_text_destination:", e);
            return '-';
          }
        }
        return '-';
      },



    async get_company() {
      const company = await UserService.get_user_profile();
      const businessType = await CompanyService.getBusinessId(company.company_id)
      if (businessType.data === 3) {
        this.business = true
      }
    },
    worksheet_status(record) {
        // console.log("🔍 ตรวจสอบค่า sheet_status:", record.sheet_status);
          switch (record.sheet_status) {
            case 0:
            case 1:
              // console.log("✅ ตรงกับใบงานว่าง (0 หรือ 1)");
              return { txt: "ใบงานว่าง", color: "yellow" };
            case 2:
              // console.log("✅ ตรงกับรอรายละเอียด");
              return { txt: "รอรายละเอียด", color: "orange" };
            case 3:
              // console.log("✅ ตรงกับมอบหมายงานแล้ว");
              return { txt: "มอบหมายงานแล้ว", color: "green" };
            default:
              // console.log("❌ ไม่ทราบสถานะ:", record.sheet_status);
              return { txt: "ไม่ทราบสถานะ", color: "gray" };
          }
        },


    async fetchBookingCarStatus() {
          try {
              const company = await UserService.get_user_profile();
              const bookingCarStatus = await SystemService.CheckBillstatus(company.company_id);
              this.bookingStatusCarData = bookingCarStatus;
          } catch (error) {
              console.error('Error fetching booking car status:', error.message);
          }
      },
      handleStatusChange(value) {
        // console.log("🔄 ค่า sheet_status ที่ถูกเลือกก่อนแปลง:", value);
          this.sheet_status = Number(value);
          if (this.sheet_status === 1) {
            // console.log("🔁 แปลงค่า 1 เป็น 0 เพื่อให้มีค่าเท่ากัน");
            this.sheet_status = 0;
          }
          // console.log("✅ ค่า sheet_status หลังแปลง:", this.sheet_status);
        this.init_bookings(); // โหลดข้อมูลใหม่
      },



    leaveRoom(channelId) {
      this.socketService.leaveRoom(channelId);
      this.socketService.offBroadcast(channelId);
    },

  },
  watch: {
    activeKey(newVal) {
      this.init_bookings(newVal);
    },
    require_rollcall(newVal) {
      if (newVal != null) {
        const { company_id } = JSON.parse(localStorage.getItem("user_profile"));
        SystemService.update_all(
          "Company",
          { data: { require_rollcall: newVal } },
          company_id
        ).then((res) => {
          const original = JSON.parse(localStorage.getItem("companyData"));
          original.require_rollcall = newVal;
          localStorage.setItem("companyData", JSON.stringify(original));
        });
      }
    },
  },
  unmounted() {
    this.leaveRoom('booking');
  },
  async mounted() {
    this.connectSocket();
    this.companyId = await this.getCompanyId();
    this.filteredTabs = await this.getFilteredTabs();
    let data = await UserService.get_user_profile();
    this.userCompanyId = data.company_id;
    let userCompanyId  = data.company_id;
    let bid = await CompanyService.getBusinessId(userCompanyId);
    this.userBusinessId = bid.data
    this.filteredTabs;
    this.init_bookings(this.activeKey);
    this.pay_method_list = await SystemService.get_all("PayMethod");
    this.expense_list = await SystemService.get_all("Expense");
    this.gas_station_list = await SystemService.get_all("GasStation");
    this.car_list = await SystemService.get_all("Car");
    this.company_list = await SystemService.get_all("Client");
    this.booking_status_list = await SystemService.get_all("BookingStatusText");
    this.car_company_list = await CarCompanyService.get_car_companies();

    // this.countTabStatus = await SystemService.get_core("countTabStatus");
    this.get_company();
    // await this.fetchBookingStatus();
    await this.fetchBookingCarStatus();


  },
};
</script>
<style>
.ant-table-thead .center-header {
  text-align: center;
  /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
}
</style>