const { String } = require('core-js');
const FileSaver = require('file-saver');
const XLSX = require('sheetjs-style');

module.exports = {
    ArabicNumberToText(Number) {
        var Number = CheckNumber(Number);
        var NumberArray = new Array ("ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ");
        var DigitArray = new Array ("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน");
        var BahtText = "";
        if (isNaN(Number))
        {
            return "ข้อมูลนำเข้าไม่ถูกต้อง";
        } else
        {
            if ((Number - 0) > 9999999.9999)
            {
                return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
            } else
            {
                Number = Number.split (".");
                if (Number[1]) {
                    Number[1] = Number[1].padEnd(2, '0').substring(0, 2);
                } else {
                    Number[1] = "00";
                }
                var NumberLen = Number[0].length - 0;
                for(var i = 0; i < NumberLen; i++)
                {
                    var tmp = Number[0].substring(i, i + 1) - 0;
                    if (tmp != 0)
                    {
                        if ((i == (NumberLen - 1)) && (tmp == 1))
                        {
                            BahtText += "เอ็ด";
                        } else
                        if ((i == (NumberLen - 2)) && (tmp == 2))
                        {
                            BahtText += "ยี่";
                        } else
                        if ((i == (NumberLen - 2)) && (tmp == 1))
                        {
                            BahtText += "";
                        } else
                        {
                            BahtText += NumberArray[tmp];
                        }
                        BahtText += DigitArray[NumberLen - i - 1];
                    }
                }
                BahtText += "บาท";
                if ((Number[1] == "0") || (Number[1] == "00"))
                {
                    BahtText += "ถ้วน";
                } else
                {
                    DecimalLen = Number[1].length - 0;
                    for (var i = 0; i < DecimalLen; i++)
                    {
                        var tmp = Number[1].substring(i, i + 1) - 0;
                        if (tmp != 0)
                        {
                            if ((i == (DecimalLen - 1)) && (tmp == 1))
                            {
                                BahtText += "เอ็ด";
                            } else
                            if ((i == (DecimalLen - 2)) && (tmp == 2))
                            {
                                BahtText += "ยี่";
                            } else
                            if ((i == (DecimalLen - 2)) && (tmp == 1))
                            {
                                BahtText += "";
                            } else
                            {
                                BahtText += NumberArray[tmp];
                            }
                            BahtText += DigitArray[DecimalLen - i - 1];
                        }
                    }
                    BahtText += "สตางค์";
                }
                return BahtText;
            }
        }
    },
    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }
    
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
    
        var csv = convertToCSV(jsonObject);
    
        var exportedFilenmae = fileTitle + '.xlsx' || 'export.xlsx';
    
        var blob = new Blob([csv], { type: 'text/xlsx;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },
    exportXLSXFile(headers, json, fileName, fields) {
        const list = [...json].map(item => {
            if (fields) {
                const obj = {};
                fields.forEach(field => {
                    obj[field] = item[field];
                });
                return obj;
            } else {
                return item;
            }
        });

        if (headers) {
            list.unshift(headers);
        }

        const data = list;
        // console.log(list);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(data);
        const all_width = []
        for (var key in headers) {
            all_width.push({ wpx: 200 })
        }
        ws['!cols'] = all_width;
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data1, fileName + fileExtension);
    },
    xlxsBase64toJson(base64) {
        const cleanBase64 = base64.replace(/\s/g, '');
        const workbook = XLSX.read(cleanBase64, { type: 'base64' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(sheet);
        // json.shift();

        return json;
    },
}

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

function CheckNumber(Number){
	var decimal = false;
	Number = Number.toString();						
	Number = Number.replace (/ |,|บาท|฿/gi,'');  		
	for (var i = 0; i < Number.length; i++)
	{
		if(Number[i] =='.'){
			decimal = true;
		}
	}
	if(decimal == false){
		Number = Number+'.00';
	}
	return Number
}