
import httpClient from './httpClient';

const prefix = 'core'

export default {
  async get_bookings(onpage = 1, perpage = 20, progress_1 = null, searchBody = null) {
    var query = ''
    if (searchBody != null) {
      for (var key in searchBody) {
        if (searchBody[key]) {
          query = query != '' ? `${query}&${key}=${searchBody[key]}` : `${key}=${searchBody[key]}`
        }
      }
    }
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/bookings?onpage=${onpage}&perpage=${perpage}${progress_1 != null ? `&progress_1=${progress_1}` : ''}&${query}`);
    return data;
  },

  async get_bookings_and_booking_cars(onpage = 1, perpage = 20, progress_1 = null, searchBody = null) {
    var query = '';
    if (searchBody != null) {
      for (var key in searchBody) {
        if (searchBody[key]) {
          query = query != '' ? `${query}&${key}=${searchBody[key]}` : `${key}=${searchBody[key]}`;
        }
      }
    }
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/bookings_and_booking_cars?onpage=${onpage}&perpage=${perpage}${progress_1 != null ? `&progress_1=${progress_1}` : ''}&${query}`);
    return data;
  },

  async get_all_bookings(progress_1 = null, searchBody = null) {
    var query = ''
    if (searchBody != null) {
      for (var key in searchBody) {
        if (searchBody[key]) {
          query = query != '' ? `${query}&${key}=${searchBody[key]}` : `${key}=${searchBody[key]}`
        }
      }
    }
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/bookings?${progress_1 != null ? `progress_1=${progress_1}` : ''}&${query}`);
    return data;
  },
  async get_remarks() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/remarks`);

    return data;
  },
  async update_remark(id, body) {
    const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/remarks/${id}`, body);

    return data;
  },
  async update_remark_index(type, body) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/remarks_index/${type}`);
    
    return data;
  },
  async get_sheets (modelname, query) {
      const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getBookingLogAByCompany${query? '?'+query : ''}`)
  
      return data
    },
    async updateStatus(id) {
      const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateStatusBookingLogA/${id}`);
      return data;
    },
    async getSheetById(id) {
      const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getBookingLogAById/${id}`);
      return data;
    },
    async deleteSheet(id) {
      const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteBookingLogAById/${id}`);
      return data;
    },
  async create_multi_sheets(sheet , qulity) {
    const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createMultiBookingLogA?qulity=${qulity}`, sheet);

    return data;
  },
  async update_Sheet(id, sheet) {
    const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateBookingLogA/${id}`, sheet);

    return data;
  },
  async duplicate_sheet(id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/duplicate-BookingLogA/${id}`);

    return data;
},
  async delete_booking(id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/booking/${id}`);

    return data;
  },
  async get_work_sheets (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getBookingAndBookingCarLogA${query? '?'+query : ''}`)

    return data
  },
  async get_sheets_progress_one (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getBookingAndBookingCarLogAByProgress${query? '?'+query : ''}`)

    return data
  },
}